import React, { ReactNode } from 'react'

type Props = {
    loading: boolean,
    hide?: boolean,
    fixed?: boolean,
    children:ReactNode,
}

const LoadingIndicator = ({ children, loading, hide, fixed }:Props) => (
    <div className={"loading-indicator" + (fixed ? " fixed" : "") }>
        <div className={`loading-indicator-icon-container ${loading?'is-loading':''}`}>
            <div className="loading-indicator-spacer">
                <i className="fas fa-spinner fa-2x fa-pulse" />
            </div>
        </div>
        <div className={`loading-indicator-content ${loading?'is-loading':''}`}>
            { ((hide === true && !loading) || hide!==true) ? children :  null }
        </div>
    </div>
)

export default LoadingIndicator;
