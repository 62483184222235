import React from 'react'

type Props = {
    children:React.ReactNode   
}


export default function({children}:Props){
    return (
        <div className="paragraph-card">
            { children }
        </div>
    )
}
