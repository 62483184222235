import React from 'react'
import Step from '../../components/Step'
import Button from '../../elements/Button'
import ButtonContainer from '../../elements/ButtonContainer'
import Input from '../../elements/Input'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'
import googleAnalytics from '../../common/services/google-analytics'


const contentsSumInsured = fieldName('contentsSumInsured', 0)
    
export const rules = {
    [contentsSumInsured]: [
        enforce.required("Required"),
        //enforce.rangeInclusive(250000, 3000000)("Please specify a value between 250,000 and 3,000,000")
    ]
}

export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();

    async function next(){
        try{
            await validation.validate(rules)
            navigation.goToNext();
        }catch(e){}
    }

    function onPDSClicked(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.LINK_CLICK,  "PDS")
    }

    return (
        <Step>
            <h2>How much would you like to insure the common area contents for? <HelpIcon label={contentsSumInsured} message={<>Common Area Contents include shared portable/removable items such as pool equipment, fire extinguishers, furniture, artwork or garden equipment. Read the <a onClick={onPDSClicked} href="https://www.flexinsurance.com.au/residential-strata/#importantdocuments" target="_blank" rel="noopener noreferrer">PDS</a> for more information.</>}/></h2>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input autoFocus field={contentsSumInsured} type="dollar" maxLength={10}/>
                </div>
            </div>
            
            <ButtonContainer>
                <Button label="Continue" icon="fas fa-chevron-right" type="primary" callback={ next } />
            </ButtonContainer>
        </Step>
    )
};
