import React from 'react'
import Step from '../../components/Step'
import Button from '../../elements/Button'
import ButtonContainer from '../../elements/ButtonContainer'
import Input from '../../elements/Input'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'
import googleAnalytics from '../../common/services/google-analytics'


const buildingSumInsured = fieldName('buildingSumInsured', 0)
    
export const rules = {
    [buildingSumInsured]: [
        enforce.required("Required")
    ]
}

export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();

    async function next(){
        try{
            await validation.validate(rules)
            navigation.goToNext();
        }catch(e){}
    }

    function onPDSClicked(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.LINK_CLICK,  "PDS")
    }

    return (
        <Step>
            <h2>What amount would you like to insure your building for? <HelpIcon label={buildingSumInsured} message={<>This is the maximum amount we would pay out for damage to your building. There is a legislative requirement that you select a value that is adequate to cover the full replacement and reinstatement of your Strata. If you are unsure, you may wish to consider a valuations service, or an insurance calculator to determine this figure. Read the <a href="https://www.flexinsurance.com.au/residential-strata/#importantdocuments" onClick={onPDSClicked} target="_blank" rel="noopener noreferrer">PDS</a> for more information.</>}/></h2>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input autoFocus field={buildingSumInsured} type="dollar" maxLength={10}/>
                </div>
            </div>
            
            <ButtonContainer>
                <Button label="Continue" icon="fas fa-chevron-right" type="primary" callback={ next } />
            </ButtonContainer>
        </Step>
    )
};
