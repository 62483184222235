import Request from '../../common/services/request'
import CurrencyFormatter from 'currency-formatter'
import Common from '../../common/Common'


type ExcessResponse = {
    type: string,
    code: string,
    description: string,
    amount: number,
    standardAmount: number,
}


type ExcessResult = {
    type: string,
    value: number,
    description: string,
    default: number,
}


type ResiExcessesResult = {
    MachineryBreakdown:{
        MB: ExcessResult[]
    },
    Property:{
        BUILDING: ExcessResult[]
        WATER: ExcessResult[]
    }
}


export default async () => {
    const url = Common.config.services.excesses;
    
    let data:{
        excesses:{
            MachineryBreakdown:{
                MB: ExcessResponse[]
            },
            Property:{
                BUILDING: ExcessResponse[]
                WATER: ExcessResponse[]
            }

        }
    } = await Request.get(url);


    function mapOption(option:any){
        return {
            type: option.type,
            value: option.amount,
            description: CurrencyFormatter.format(option.amount,{
                thousand: ",",
                precision: 0,
            }),
            default: option.standardAmount,
        }
    }


    const result:ResiExcessesResult = {
        MachineryBreakdown:{
            MB: data.excesses.MachineryBreakdown.MB.map(mapOption)
        },
        Property:{
            BUILDING: data.excesses.Property.BUILDING.map(mapOption),
            WATER: data.excesses.Property.WATER.map(mapOption),
        }
    };

    return result;
}
