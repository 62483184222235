import React from 'react'
import Step from '../../components/Step'
import ButtonSelect from '../../elements/ButtonSelect'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const options = [
    {
        type: "NA",
        value: 0,
        description: "0",
    },
    {
        type: "NA",
        value: 1,
        description: "1",
    },{
        type: "NA",
        value: 2,
        description: "2",
    },{
        type: "NA",
        value: 3,
        description: "3",
    },{
        type: "NA",
        value: 4,
        description: "4",
    },{
        type: "NA",
        value: 5,
        description: "5",
    }
]


const numberOfPoolsOrSpas = fieldName('numberOfPoolsOrSpas');


export const rules = {
    [numberOfPoolsOrSpas]: [],
}


export default () => {
    const navigation = Resi.useNavigation();
    const fields = Resi.useFields();

    function onSelected(value:number){
        fields.setValue(numberOfPoolsOrSpas)(value);
        navigation.goToNext();
    }

    return (
        <Step>
            <h2>How many pools or spas in the building? <HelpIcon label={numberOfPoolsOrSpas} message=""/></h2>
            <ButtonSelect 
                options={ options }
                selected={fields.value(numberOfPoolsOrSpas)}
                colsLg={ 3 }
                colsSm={ 1 }
                callback={ onSelected }
            />
        </Step>
    )
};
