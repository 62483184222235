import calculateInstalments from "./calculateInstalments";
import details from "./details"

export default async (token:string) => {
    const detailsResult = await details(token);
    const instalmentsResult = await calculateInstalments();
    
    return {
        ...detailsResult,
        instalments: instalmentsResult,
    }
}