import { Fields } from './FieldsResi'

// The field values relevant to the additional cover options don't need to be retained in the fields object.
// The field values are just getting generated at the time the quote is called and appended to request object.
export const getExtrasData = (fields:Fields) => {
    const result:any = {};
    
    // Set the field top to the result value if:
    // The additional cover option is mandatory
    // OR
    // The additional cover option is optional and the user has selected the additional cover option
    const set = (optionality:'optional'|'mandatory'|'no', selection:boolean, resultName:keyof Fields, resultValue:any) => {
        if((optionality!='no' && selection) || optionality=='mandatory'){
            result[resultName] = resultValue;
        }else{
            if(result[resultName]==undefined) result[resultName] = undefined;
        }
    }

    // Set the field to the result value if NOT:
    // The additional cover option is mandatory
    // OR
    // The additional cover option is optional and the user has selected the additional cover option
    const not = (optionality:'optional'|'mandatory'|'no', selection:boolean, resultName:keyof Fields, resultValue:any) => {
        if((optionality=='no') || (optionality=='optional' && !selection)){
            result[resultName] = resultValue;
        }else{
            if(result[resultName]==undefined) result[resultName] = undefined;
        }
    }

    // Workers Compensation
    not(fields.__WC__, fields.__wc__, 'workersCompensationRequired', false)
    set(fields.__WC__, fields.__wc__, 'workersCompensationRequired', true)
    set(fields.__WC__, fields.__wc__, 'estimatedWages', 1)
    set(fields.__WC__, fields.__wc__, 'employeesEstimatedWages', 1)
    set(fields.__WC__, fields.__wc__, 'labourOnlyEstimatedWages', 1)
    set(fields.__WC__, fields.__wc__, 'labourMaterialsEstimatedWages', 1)
    set(fields.__WC__, fields.__wc__, 'labourPlantEstimatedWages', 1)
    set(fields.__WC__, fields.__wc__, 'labourPlantMaterialsEstimatedWages', 1)
    
    // Flex+ options
    not(fields.__EC__, fields.__ec__, 'exploratoryCostsRequested', false)
    set(fields.__EC__, fields.__ec__, 'exploratoryCostsRequested', true)
    not(fields.__ET__, fields.__et__, 'extendedTempAccomodationLossRentRequested', false)
    set(fields.__ET__, fields.__et__, 'extendedTempAccomodationLossRentRequested', true)
    not(fields.__FU__, fields.__fu__, 'fusionRequested', false)
    set(fields.__FU__, fields.__fu__, 'fusionRequested', true)
    not(fields.__FF__, fields.__ff__, 'floatingFloorsRequested', false)
    set(fields.__FF__, fields.__ff__, 'floatingFloorsRequested', true)
    not(fields.__SB__, fields.__sb__, 'specialBenefitsExtensionRequested', false)
    set(fields.__SB__, fields.__sb__, 'specialBenefitsExtensionRequested', true)
    
    // Liability
    set(fields.__LI__, fields.__li__, 'liabilitySumInsured', 20_000_000)

    // Voluntary Workers
    not(fields.__VW__, fields.__vw__, 'voluntaryWorkersRequired', false)
    set(fields.__VW__, fields.__vw__, 'voluntaryWorkersRequired', true)
    set(fields.__VW__, fields.__vw__, 'voluntaryWorkersDeath', 50_000)

    // Fidelity Guarantee
    not(fields.__FG__, fields.__fg__, 'fidelityRequired', false)
    set(fields.__FG__, fields.__fg__, 'fidelityRequired', true)
    set(fields.__FG__, fields.__fg__, 'fidelitySumInsured', 50_000)

    // Office Bearers Legal Liability
    not(fields.__OB__, fields.__ob__, 'officeBearersRequired', false)
    set(fields.__OB__, fields.__ob__, 'officeBearersRequired', true)
    set(fields.__OB__, fields.__ob__, 'officeBearersSumInsured', 500_000)
        
    // Machinery Breakdown
    not(fields.__MB__, fields.__mb__, 'machineryBreakdownRequired', false)
    set(fields.__MB__, fields.__mb__, 'machineryBreakdownRequired', true)
    set(fields.__MB__, fields.__mb__, 'machineryBreakdownSumInsured', 5_000)
    set(fields.__MB__, fields.__mb__, 'airConditioningLocation', "NONE");
    set(fields.__MB__, fields.__mb__, 'carStackerTurnTable', true);
    set(fields.__MB__, fields.__mb__, 'chillersOrCoolingTowers', true);
    set(fields.__MB__, fields.__mb__, 'electronicMotors5KW', true);

    // Catastrophe Insurance
    not(fields.__CC__, fields.__cc__, 'catastropheRequired', false)
    set(fields.__CC__, fields.__cc__, 'catastropheRequired', true)
    set(fields.__CC__, fields.__cc__, 'catastrophePercent', 15)
    
    // Government Audit
    not(fields.__GA__, fields.__ga__, 'governmentAuditIncluded', false)
    set(fields.__GA__, fields.__ga__, 'governmentAuditIncluded', true)
    set(fields.__GA__, fields.__ga__, 'governmentAuditAmount', 10_000)
    set(fields.__GA__, fields.__ga__, 'appealExpensesAmount', 40_000)
    set(fields.__GA__, fields.__ga__, 'legalDefenceAmount', 15_000)

    // Lot Owners
    not(fields.__LO__, fields.__lo__, 'lotOwnersIncluded', false)
    set(fields.__LO__, fields.__lo__, 'lotOwnersIncluded', true)
    set(fields.__LO__, fields.__lo__, 'lotOwnersSumInsured', 250_000)

    return result;
}
