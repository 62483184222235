import Request from './request';
import Common from '../Common';

type Type = "TaxInvoice"|"Quote";

type DocumentsResult = any;


export default async (type:Type) => {
    const url = Common.config.services.documents;

    let data:DocumentsResult = await Request.post(url, {
        type
    });
    
    return data;
}
