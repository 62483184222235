import React from 'react'
import Grid from '../Grid'


const ChuAwardsText = () => {
    return (
        <div className="chu-awards">
            <Grid colsLg={3}
                    colsSm={1}
                    heightLg='inherit'
                    heightSm='inherit'
                    columnGapLg='1em'
                    columnGapSm='0.5em'
                    rowGapLg='0.5em'
                    rowGapSm='0.6em'
            >
                <div className="chu-award-text">
                    <div className="chu-award-icon"><img src={require('../../assets/chu/awards/Icon-Cover-for-your-building-and-common-area-contents.png').default} alt="Award icon" /></div>
                    <div>
                        <div className="chu-award-header">Almost 1 million properties protected</div>
                        <div className="chu-award-body">CHU protects almost 1 million apartments, units and townhouses across&nbsp;Australia.</div>
                    </div>
                </div>
                <div className="chu-award-text">
                    <div className="chu-award-icon"><img src={require('../../assets/chu/awards/icon-24hr2x.png').default} alt="Award icon" /></div>
                    <div>
                        <div className="chu-award-header">24/7 Claims Process and Emergency Hotline</div>
                        <div className="chu-award-body">When you need help the most CHU’s claim process strives to be quick and&nbsp;accessible.</div>
                    </div>
                </div>
                <div className="chu-award-text">
                    <div className="chu-award-icon"><img src={require('../../assets/chu/awards/Icon-Award-winning.png').default} alt="Award icon" /></div>
                    <div>
                        <div className="chu-award-header">Multi Award Winning</div>
                        <div className="chu-award-body">Awards include back to back winners of the Australian Underwriting Agency of the Year and Insurance Business Gold&nbsp;Award.</div>
                    </div>
                </div>
            </Grid>
        </div>
    )
}

const ChuAwardsImage = () => {
    return (
        <div className="chu-awards">
            <div className="chu-awards-img">
                <img src={require("../../assets/chu/awards/CHU_AwardsStripBanner_mobile600x260_v2.jpg").default } alt="CHU awards" />
            </div>
        </div>
    )
}


export default {
    ChuAwardsText,
    ChuAwardsImage,
};
