import { RouteSet } from '../types'


export const routes = [ 

    [RouteSet.BEFORE_ESTIMATE        , "/duty-of-disclosure"               , require("./steps/duty-of-disclosure"                )],
    //[RouteSet.BEFORE_ESTIMATE        , "/jetty-lake-spa-lifts"             , require("./steps/jetty-lake-spa-lift"             )],
    [RouteSet.BEFORE_ESTIMATE        , "/requester-email"                  , require("./steps/requester-email"                   )],
    [RouteSet.BEFORE_ESTIMATE        , "/number-claims"                    , require("./steps/number-of-claims"                  )],
    [RouteSet.BEFORE_ESTIMATE        , "/limited"                          , require("./steps/limited"                           )],
    [RouteSet.BEFORE_ESTIMATE        , "/property-declined"                , require("./steps/property-declined"                 )],
    [RouteSet.BEFORE_ESTIMATE        , "/address"                          , require('./steps/resi-address'                      )],
    [RouteSet.BEFORE_ESTIMATE        , "/building-name"                    , require("./steps/building-name"                     )],
    [RouteSet.BEFORE_ESTIMATE        , "/choose-your-cover"                , require("./steps/choose-your-cover"                 )],
    [RouteSet.BEFORE_ESTIMATE        , "/owners-corp-type"                 , require("./steps/owners-corp-type"                  )],
    //[RouteSet.BEFORE_ESTIMATE        , "/management-type"                  , require("./steps/management-type"                   )],
    [RouteSet.BEFORE_ESTIMATE        , "/current-insurer"                  , require("./steps/current-insurer"                   )],
    [RouteSet.BEFORE_ESTIMATE        , "/plan-number-available"            , require("./steps/plan-number-available"             )],
    [RouteSet.BEFORE_ESTIMATE        , "/owners-corporation-number"        , require("./steps/owners-corporation-number"         )],
    [RouteSet.BEFORE_ESTIMATE        , "/property-expiring-excess"         , require("./steps/property-expiring-excess"          )],
    [RouteSet.BEFORE_ESTIMATE        , "/year-built"                       , require("./steps/year-built"                        )],
    [RouteSet.BEFORE_ESTIMATE        , "/lots"                             , require("./steps/lots"                              )],
    [RouteSet.BEFORE_ESTIMATE        , "/floors"                           , require("./steps/floors"                            )],
    [RouteSet.BEFORE_ESTIMATE        , "/basements"                        , require("./steps/basements"                         )],
    [RouteSet.BEFORE_ESTIMATE        , "/equipment-in-basements"           , require("./steps/equipment-in-basements"            )],
    [RouteSet.BEFORE_ESTIMATE        , "/heritage-listed"                  , require("./steps/heritage-listed"                   )],
    [RouteSet.BEFORE_ESTIMATE        , "/walls-material"                   , require("./steps/walls-material"                    )],
    [RouteSet.BEFORE_ESTIMATE        , "/roof-material"                    , require("./steps/roof-material"                     )],
    [RouteSet.BEFORE_ESTIMATE        , "/floors-material"                  , require("./steps/floors-material"                   )],
    [RouteSet.BEFORE_ESTIMATE        , "/external-cladding"                , require("./steps/external-cladding"                 )],
    [RouteSet.BEFORE_ESTIMATE        , "/ground-floor"                     , require("./steps/ground-floor"                      )],
    [RouteSet.BEFORE_ESTIMATE        , "/commercial-percent"               , require("./steps/commercial-percent"                )],
    [RouteSet.BEFORE_ESTIMATE        , "/sprinklers"                       , require("./steps/sprinklers"                        )],
    [RouteSet.BEFORE_ESTIMATE        , "/fire-alarm"                       , require("./steps/fire-alarm"                        )],
    [RouteSet.BEFORE_ESTIMATE        , "/fire-extinguishers"               , require("./steps/fire-extinguishers"                )],
    [RouteSet.BEFORE_ESTIMATE        , "/hydrants"                         , require("./steps/hydrants"                          )],
    [RouteSet.BEFORE_ESTIMATE        , "/fully-occupied"                   , require("./steps/fully-occupied"                    )],
    [RouteSet.BEFORE_ESTIMATE        , "/lifts"                            , require("./steps/lifts"                             )],
    [RouteSet.BEFORE_ESTIMATE        , "/pools"                            , require("./steps/pools"                             )],
    //[RouteSet.BEFORE_ESTIMATE        , "/playground"                       , require("./steps/playground"                        )],
    [RouteSet.BEFORE_ESTIMATE        , "/jetty"                            , require("./steps/jetty"                             )],
    [RouteSet.BEFORE_ESTIMATE        , "/lake"                             , require("./steps/lake"                              )],
    [RouteSet.BEFORE_ESTIMATE        , "/gymnasium"                        , require("./steps/gymnasium"                         )],
    [RouteSet.BEFORE_ESTIMATE        , "/defects"                          , require("./steps/defects"                           )],
    [RouteSet.BEFORE_ESTIMATE        , "/building-sum-insured"             , require("./steps/building-sum-insured"              )],
    [RouteSet.BEFORE_ESTIMATE        , "/year-rewired"                     , require("./steps/year-rewired"                      )],
    [RouteSet.BEFORE_ESTIMATE        , "/year-refurbished"                 , require("./steps/year-refurbished"                  )],
    [RouteSet.BEFORE_ESTIMATE        , "/year-replumbed"                   , require("./steps/year-replumbed"                    )],
    [RouteSet.BEFORE_ESTIMATE        , "/common-area"                      , require("./steps/common-area"                       )],
    [RouteSet.BEFORE_ESTIMATE        , "/flood-requested"                  , require("./steps/flood-requested"                   )],
    [RouteSet.BEFORE_ESTIMATE        , "/inception-date"                   , require("./steps/inception-date"                    )],
    [RouteSet.BEFORE_ESTIMATE        , "/additional-cover-options"         , require("./steps/additional-cover-options"          )],
    [RouteSet.BEFORE_FINAL_PRICE     , "/final-price"                      , require("./steps/final-price"                       )],
    [RouteSet.BEFORE_PAYMENT         , "/requester"                        , require("./steps/requester"                         )],
    [RouteSet.BEFORE_PAYMENT         , "/property-contact"                 , require("./steps/property-contact"                  )],
    [RouteSet.BEFORE_PAYMENT         , "/strata-manager-contact"           , require("./steps/strata-manager-contact"            )],
    [RouteSet.PAYMENT                , "/payment"                          , require('./steps/common/payment'                    )],
    [RouteSet.PAYMENT                , "/payment-by-card"                  , require('./steps/common/payment-by-card'            )],
    [RouteSet.END                    , "/payment-offline"                  , require('./steps/common/payment-offline'            )],
    [RouteSet.END                    , "/payment-result-success"           , require('./steps/common/payment-result-success'     )],
    [RouteSet.END                    , "/payment-result-fail"              , require('./steps/common/payment-result-fail'        )],
    [RouteSet.END                    , "/referred"                         , require('./steps/common/quote-referred'             )],
    [RouteSet.END                    , "/declined"                         , require('./steps/common/quote-declined'             )],
    [RouteSet.MISC                   , "/restore"                          , require('./steps/common/restore'                    )],
    [RouteSet.MISC                   , "/instalments-success"              , require("./steps/common/instalments-success"        )],
    [RouteSet.MISC                   , "/instalments-error"                , require("./steps/common/instalments-error"          )],
    [RouteSet.MISC                   , "/instalments-cancel"               , require("./steps/common/instalments-cancel"         )],
    
] as const;