import React from 'react'
import Common from '../common/Common'
import { Type } from './Input'
import { Option as OptionBase } from '../types'


type OptionValue = string | string[] | number | undefined;

type Option<T extends OptionValue> = OptionBase<T>

type Props<T extends OptionValue> = {
    options: Option<T>[],
    field: string | State<T>,
    label?: React.ReactNode,
    size?: "size-50",
    center?:boolean,
    caption?: React.ReactNode,
    onSelectChange?: (value:string)=>void,
    type?: Type,
}


type State<T> = [T, (value:T)=>void];


function createOption<T extends OptionValue>(type:Type, option:Option<T>, index:number){
    return <option value={option.value} key={index}>{ option.description}</option>
}


function Select<T extends OptionValue>({ label, field, options, onSelectChange, type='text' }:Props<T>){
    const fields = Common.useFields();
    const [ fieldValue, setFieldValue ] = typeof field === "string"
        ? [ fields.value(field), fields.setValue(field) ] as const
        : field;

    function onChange(value:string){
        if(type === 'dollar' || type === 'postcode' || type === 'year' || type === 'percent'){
            setFieldValue(parseInt(value) as any);
        }else{
            setFieldValue(value as any);
        }
        if(onSelectChange) onSelectChange(value)
    }

    return (
        <div className="custom-select-container">
            { label &&
                <label className="main-label">{ label }</label>
            }
            <select className="custom-select" value={fieldValue} onChange={ e => onChange(e.target.value) }>
                <option disabled selected={!fieldValue}/>
                { options.map((option, index) => createOption(type, option, index)) }
            </select>
        </div>
    )
}

export default Select;
