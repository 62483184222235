import React from 'react'
import Step from '../../../components/Step'


export default function(){
    return (
        <Step>
            <h2>Instalments cancel</h2>
        </Step>
    )
}    