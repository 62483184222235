import React from 'react'
import Step from '../../components/Step'
import Button from '../../elements/Button'
import ButtonContainer from '../../elements/ButtonContainer'
import Input from '../../elements/Input'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const yearRewired = fieldName('yearRewired')
const yearBuilt = fieldName('yearBuilt')
    
export const rules = {
    [yearRewired]: [
        enforce.required("Required"),
        enforce.greaterThanEqualField(yearBuilt)("Must be after the year built."),
        enforce.yearPast("Must be a valid year"),
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();

    async function next(){
        try{
            await validation.validate(rules)
            navigation.goToNext();
        }catch(e){}
    }

    return (
        <Step>
            <h2>When was the building last rewired? <HelpIcon label={yearRewired} message="What year was the electrical wiring last fully replaced?"/></h2>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input field={yearRewired} type="number" autoFocus/>
                </div>
            </div>
            
            <ButtonContainer>
                <Button label="Continue" icon="fas fa-chevron-right" type="primary" callback={ next } />
            </ButtonContainer>
        </Step>
    )
};
