import React from 'react'
import Common from '../common/Common'
import { Type } from './Input'
import { Dropdown } from 'react-bootstrap'
import { Option as OptionBase } from '../types'


type OptionValue = string | string[] | number | undefined;

type Option<T extends OptionValue> = OptionBase<T>

type Props<T extends OptionValue> = {
    options: Option<T>[],
    field: string | State<T>,
    label?: React.ReactNode,
    size?: "size-50",
    center?:boolean,
    caption?: React.ReactNode,
    onSelectChange?: (value:string)=>void,
    type?: Type,
}


type State<T> = [T, (value:T)=>void];



function ButtonMenu<T extends OptionValue>({ label, field, options, type='text', onSelectChange }:Props<T>){
    const fields = Common.useFields();
    const [ fieldValue, setFieldValue ] = typeof field === "string"
        ? [ fields.value(field), fields.setValue(field) ] as const
        : field;

    function onChange(value:string){
        if(type === 'dollar' || type === 'postcode' || type === 'year' || type === 'percent'){
            setFieldValue(parseInt(value) as any);
        }else{
            setFieldValue(value as any);
        }
        if(onSelectChange) onSelectChange(value)
    }

    return (
        <div className="button-menu" data-singular={options.length<=1}>
            <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                    { options.find(option => option.value == fieldValue)?.description } <span className="button-menu-icon icon-right float-right"><i className="fas fa-lg fa-chevron-circle-down"></i></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    { options.map((option, index) => <Dropdown.Item key={index} as="button" onClick={e => onChange(option.value as any)}>{ option.description }</Dropdown.Item>) }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default ButtonMenu;
