import React from 'react'
import Step from '../../components/Step'
import Input from '../../elements/Input'
import Button from '../../elements/Button'
import ButtonContainer from '../../elements/ButtonContainer'
import AddressAutoComplete from '../../elements/AddressAutoComplete'
import Select from '../../elements/Select'
import {fieldName, routeName, enforce} from '../HelpersResi'
import Landlords from '../Resi'
import googleAnalytics from '../../common/services/google-analytics'


const address = fieldName('__address__')
const deliveryPoint = '__deliveryPoint_NOT_USED__'
const postcode = fieldName('ownersCorporationAddress.postcode')
const streetNumber = fieldName('ownersCorporationAddress.streetNumber')
const streetName = fieldName('ownersCorporationAddress.streetName')
const suburb = fieldName('ownersCorporationAddress.suburb')
const state = fieldName('ownersCorporationAddress.state')

const addressNotFound = fieldName('addressNotFound');

const gnafPid = '__gnafPid_NOT_USED__'


export const rules = {
    [postcode]: [
        enforce.required("Required"),
        enforce.number("Must be a valid postcode"),
    ],
    [address]: [
        enforce.required("Required"),
    ],
    [streetNumber]:[
        enforce.required("Required")
    ],
    [streetName]:[
        enforce.required("Required")
    ],
    [suburb]:[
        enforce.required("Required")
    ],
    [state]:[
        enforce.required("Required")
    ],
    /*
    [gnafPid]:[
        enforce.optional("Required")
    ],
    */
    [addressNotFound]:[]
}


const STATES = ["NSW", "VIC", "QLD", "WA", "SA", "ACT", "TAS", "NT"].map(state => ({
    type: "STATE",
    value: state,
    description: state,
}))


export default () => {
    const fields = Landlords.useFields();
    const navigation = Landlords.useNavigation();
    const validation = Landlords.useValidation();
    

    async function next(){
        googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "state", fields.value(state))
        try{
            await validation.validate(rules)
            navigation.goToNext();
        }catch(e){}
    }

    function onAddressFocus(){
        fields.setValue(address)("");
    }

    return (
        <Step title="Address">
            <div>
                <h2>What is the address of the property that you want to insure?</h2>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <Input autoFocus field={postcode} type="postcode" label="Postcode" />
                    </div>
                    <div className="col-12 col-md-8">       
                    <Input field={address} type="address" label="Address" caption="eg. 20 Smith St or 1/20 Smith St">
                        <AddressAutoComplete onFocus={onAddressFocus} notFound={fields.value(address)==="NOT_FOUND"} hasDeliveryPoint={false} fieldNames={{
                                            postcode,
                                            deliveryPoint,
                                            streetNumber,
                                            streetName,
                                            suburb,
                                            state,
                                            gnafPid,
                                            address,
                                            }}/>
                    </Input>
                
                    </div>
                </div>
                { fields.value(address) === "NOT_FOUND" &&
                    <>
                        <hr></hr>
                        <h3>Sorry we couldn't find your address, please enter it manually:</h3>
                        <div className="row">
                            <div className="col-12 col-md-4">
                                <Input field={streetNumber} type="text" label="Street / House number" autoFocus/>
                            </div>
                            <div className="col-12 col-md-8">
                                <Input field={streetName} type="text" label="Street" />
                            </div>
                            <div className="col-12 col-md-6">
                                <Input field={suburb} type="text" label="Suburb"/>
                            </div>
                            <div className="col-12 col-md-3">
                                <Input field={postcode} type="text" label="Postcode" />
                            </div>
                            <div className="col-12 col-md-3">
                                <Input field={state} type="text" label="State">
                                    <Select options={ STATES }
                                            field={ state }/>
                                </Input>
                            </div>
                        </div>
                    </>
                }
                <ButtonContainer>
                    <Button label="Continue" icon="fas fa-chevron-right" type="primary" callback={ next }/>
                </ButtonContainer>
            </div>
        </Step>
    )
}
