import React, { useState, forwardRef, useEffect, ReactNode } from 'react'
import { Collapse } from 'react-bootstrap';
import LoadingIndicator from './LoadingIndicator';


type Props = {
    heading?: React.ReactNode,
    headingIcon? :React.ReactNode,
    collapsable?:boolean,
    loading?: boolean,
    preContent?: React.ReactNode,
    preChildren?: React.ReactNode,
    children: ReactNode,
    onClick?: ()=>void,
    startOpen?: boolean,
    colorHeading?: 'primary'|'secondary'|'success'|'white'
    color?: 'primary'|'secondary'|'success'|'white',
}


const Section = ({startOpen, colorHeading, color, heading, preContent, preChildren, loading, children, headingIcon, onClick, collapsable = false}:Props, ref: React.Ref<HTMLDivElement>) => {
    const [open, setOpen] = useState(collapsable === true && !startOpen ? false : true);


    const toggle = () => {
        if(onClick) onClick();
        if(collapsable) setOpen(!open);
    }
    
    if(headingIcon) headingIcon = <span className="show-text">{headingIcon}</span>

    const hasChildren = React.Children.toArray(children).length > 0;

    if(collapsable){
        headingIcon = (
            <>
                <div className={open?'d-none':''}><i className={`fas fa-chevron-down`}/></div>
                <div className={!open?'d-none':''}><i className={`fas fa-chevron-up`}/></div>
            </>
        )
    }

    return (
        <div className={`section ${collapsable?"collapsable":""} ${onClick?"clickable":""}`} ref={ ref }>
            <LoadingIndicator loading={!!loading}>
                { heading && 
            <div className="section-header" data-color={colorHeading} onClick={ toggle }>
                    <h2>{ heading }</h2>
                    <div className={`section-header-icon ${!headingIcon?'d-none':''}`}>
                        { headingIcon }
                    </div>
                </div>
                }
                { preContent }

                { preChildren &&
                <div className="section-children" data-color={color}>
                    { preChildren }
                </div>
                }
                { hasChildren && 
                    <Collapse in={open}>
                        <div className="section-children" data-color={color}>
                            { children }
                        </div>
                    </Collapse>
                }
            </LoadingIndicator>
        </div>
    )
}

export default forwardRef(Section);
