import React from 'react'
import Step from '../../components/Step'
import ButtonSelect from '../../elements/ButtonSelect'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const groundFloorOccupancy = fieldName('groundFloorOccupancy');

export const rules = {
    [groundFloorOccupancy]: []
}



export default () => {
    const navigation = Resi.useNavigation();
    const fields = Resi.useFields();
    const options = Resi.useOptions();


    function onSelected(value:string){
        fields.setValue(groundFloorOccupancy)(value);
        navigation.goToNext();
    }


    return (
        <Step>
            <h2>What type of occupancy is on the ground floor?</h2>
            <ButtonSelect 
                options={ options.data.product.GROUND_FLOOR }
                selected={fields.value(groundFloorOccupancy)||null}
                colsLg={ 3 }
                colsSm={ 1 }
                callback={ onSelected }
            />
           
        </Step>
    )
};