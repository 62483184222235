import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const knownDefects = fieldName('knownDefects');

export const rules = {
    [knownDefects]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Are there any known defects of the building?&nbsp;<HelpIcon label={knownDefects} message="An issue with a building counts as a building defect if it is a result of defective design, faulty workmanship, substandard materials or non-compliance."/></h2>
            <ButtonsYesNo field={knownDefects} callback={ navigation.goToNext } />
        </Step>
    )
}