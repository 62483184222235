import React from 'react'
import { Option } from "../types"
import Button from "./Button"
import ButtonContainer from "./ButtonContainer"
import ButtonMenu from './ButtonMenu'
import Price from "./Price"
import PriceBreakdown from './PriceBreakdown'
import Section from "./Section"
import Select from "./Select"


type Props = {
    onContinue:()=>void,
    scheduleField:string,
    priceOptions:Option<string>[],
    onUpdateClicked?:()=>void,
    hideContinueSmall?:boolean,
    isLoading?:boolean,
    amount:number,
    dirty?:boolean,
}


const SectionUpdatablePrice = ({dirty, amount, isLoading, hideContinueSmall, priceOptions, onContinue, onUpdateClicked, scheduleField}:Props) => {
    return (
        <div className="section-updatable" data-loading={isLoading==true}>
            <Section>
                <div className="section-updateable-blur-container" data-dirty={dirty}>
                    <div className="section-updatable-price-container">
                        <Price amount={amount} />
                    </div>
                    <div className="section-updatable-charges">Including GST & Gov Charges.</div>
                    <ButtonMenu options={priceOptions} field={'__paymentSchedule__'} />
                    <PriceBreakdown />
                </div>
                <div>
                    <ButtonContainer>
                        <Button label={dirty ? "Update Price" : "Continue"} icon={dirty ? "" : "fas fa-chevron-right"} type="primary" callback={ dirty ? onUpdateClicked! : onContinue } />
                    </ButtonContainer>
                </div>
            </Section>
        </div>
    )
}

export default SectionUpdatablePrice;