import { Option } from "../../types";


export function setOptionHelp<T>(options:Array<Option<T>>, value:T, help:string){
    var option = options.find(option => option.value == value);
    if(option) option.help = help;
}


// Lower number is higher priority,
// therefore lower numbers come first.
export function setOptionPriority<T>(options:Array<Option<T>>, value:T, priority:number){
    var option = options.find(option => option.value == value);
    if(option) option.priority = priority;
    sortByPriority(options);
}


function sortByPriority<T>(options:Array<Option<T>>){
    options.sort((a, b) => {
        if((a.priority||0) > (b.priority||0)) return 1;
        if((a.priority||0) == (b.priority||0)) return 0;
        return -1;
    });
}