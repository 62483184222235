import React, { useState } from 'react';

type Props = {
    title: React.ReactNode,
    body: React.ReactNode,
    onClick: () => void,
    hidden?:{
        title: React.ReactNode
        body: React.ReactNode,
    }
}


function CoverOptionSelectorMobile<T>({onClick, title, body, hidden}:Props){
    const [state, setState] = useState({
        showing:false
    });

    function toggle(){
        setState({
            ...state,
            showing: !state.showing,
        })
    }

    return (
        <div className="cos-mobile">
            <div className="cos-title">{title} <span className="cos-continue" onClick={onClick}>Select <i className="fas fa-chevron-right"></i></span></div>
            <div className="cos-body">
                <div className="cos-content">{body}</div>
                { hidden &&     
                    <div className="cos-hidden" data-show={state.showing} onClick={toggle}>
                        <div className="cos-hidden-title">{hidden?.title} <span className="cos-chevron" data-show={!state.showing}><i className="fas fa-chevron-down"/></span><span className="cos-chevron" data-show={state.showing}><i className="fas fa-chevron-up"/></span></div>
                        <div className="cos-hidden-body" data-show={state.showing}>{hidden?.body}</div>
                    </div>
                }
            </div>
        </div>
    )
}

export default CoverOptionSelectorMobile;
