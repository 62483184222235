import React, { useEffect } from 'react'
import ButtonSelect from "./ButtonSelect";
import Common from '../common/Common';
import commonServices from '../common/services';
import CurrencyFormatter from 'currency-formatter'
import { Option } from '../types';
import googleAnalytics from '../common/services/google-analytics';


const PaymentButtons = () => {
    const navigation = Common.useNavigation();
    const options = Common.useOptions();
    const loading = Common.useLoading();
    const fields = Common.useFields();
    const [payOfflineResult, payOfflineLoading, payOfflineFetch, payOfflineCount] = Common.useFetch(commonServices.documents);
    
    useEffect(() => {
        if(payOfflineLoading && loading.isLoading === false) loading.beginLoading();
    },[payOfflineLoading, loading.isLoading]);


    useEffect(() => {
        if(payOfflineResult){
            googleAnalytics.offlinePaymentsSuccess(fields.all.quoteNumber!, fields.all.totalPayable!);
            navigation.goTo('/payment-offline')
            loading.endLoading();
        }
    }, [payOfflineResult]);


    function payInstalments(){
        const instalmentsURL = commonServices.instalments();
        googleAnalytics.customInteraction(googleAnalytics.Actions.BUTTON_CLICK, "payment", "instalments")
        navigation.setExtenalRedirect(instalmentsURL);
    }
    
    function payOffline(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.BUTTON_CLICK, "payment", "offline")
        payOfflineFetch('TaxInvoice');
    }

    function payCard(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.BUTTON_CLICK, "payment", "card")
        navigation.goTo('/payment-by-card');
    }

    
    function dollar(value:number|undefined){
        if(value===undefined) return '0.00';
        return '$' + CurrencyFormatter.format(value, {
            thousand: ",",
            precision: 2
        })
    }


    function getButtonLabel(option:Option<any>){
        switch(option.value){
            case 'BRAINTREE': return `<div>${option.description}</div><div>${dollar(fields.all.totalPayable)} Yearly</div>`// + "$" + fields.all.totalPayable + " Yearly";
            case 'OFFLINE': return `<div>${option.description.replace(' (', '<br>(')}</div><div>${dollar(fields.all.totalPayable)} Yearly</div>`// + "$" + fields.all.totalPayable + " Yearly";
            case 'INHOUSE_INSTALMENTS': return `<div>${option.description}</div><div>${dollar(fields.all.instalments?.instalmentPayments[0].instalmentAmount)} Monthly</div>`// + "$" + fields.all.instalments?.instalmentPayments[0].instalmentAmount + " Monthly";
            default: throw("Invalid payment option: " + option.value);
        }
    }

    const totalPayableValue:number = fields.valueFor('totalPayable') as any;
    const greaterThanPremiumMin = totalPayableValue >= options.data.payment.mpfPremiumMin
    const lessThanPremiumMax = totalPayableValue <= options.data.payment.mpfPremiumMax

    const paymentOptions = options.data.payment.paymentOptions
        .filter(option =>  option.value !== 'INHOUSE_INSTALMENTS' || (greaterThanPremiumMin && lessThanPremiumMax)) // Hide instalments button if total payable falls outside of the min and max range
        .filter(option => option.value !== 'INHOUSE_INSTALMENTS' || !fields.value('referenceCode')) // Hide instalments button if referenceCode is present
        .map(option => ({
        ...option,
        description: getButtonLabel(option)
    }))

    return (
        <>
            <ButtonSelect options={ paymentOptions }
                        selected={ null }
                        colsLg={ paymentOptions.length }
                        colsSm={ 1 }
                        heightLg="110px"
                        heightSm="80px"
                        callback={ (option) => {
                            switch(option){
                                case 'BRAINTREE': payCard(); break;
                                case 'OFFLINE': payOffline(); break;
                                case 'INHOUSE_INSTALMENTS': payInstalments(); break;
                        }}}
            />
        </>
    )
    
}

export default PaymentButtons;