import React, { useEffect } from 'react';
import Step from '../../../components/Step'
import Common from '../../../common/Common';
import Input from '../../../elements/Input';
import { fieldName, routeName, enforce } from '../../HelpersResi';
import ButtonContainer from '../../../elements/ButtonContainer'
import Button from '../../../elements/Button';
import env from '../../../env';
import { RouteSet } from '../../../types';
import { getExtrasData } from '../../ExtrasResi';


const requesterFirstName = fieldName('requester.firstName')
const requesterSurname = fieldName('requester.surname')
const requesterPhone = fieldName('requester.phone')
const requesterEmail = fieldName('requester.email')

const propertyContactFullname = fieldName('propertyContact.fullName');
const propertyContactEmail = fieldName('propertyContact.email');
const propertyContactPhone = fieldName('propertyContact.phone');


export const rules = {
    [requesterFirstName]: [ 
        enforce.required('Required'),
        enforce.notBlank('Required'),
    ],
    [requesterSurname]: [ 
        enforce.required('Required'),
        enforce.notBlank('Required'),
    ],
    [requesterPhone]: [
        enforce.required('Required'),
        enforce.phoneNumber("Must be a valid phone number"),
        enforce.notBlank('Required'),
    ],
    [requesterEmail]: [ 
        enforce.required('Required'),
        enforce.email("Must be a valid email"),
        enforce.notBlank('Required'),
    ],
}


const Referred = () => {
    const fields = Common.useFields();
    const referenceCodeValue = fields.valueFor('referrals.REFERENCECODE');
    const validation = Common.useValidation();
    const alert = Common.useAlert();
    const loading = Common.useLoading();
    const referrals = fields.all.referrals || [];

    const [ fetchQuote, loadingQuote, fetchQuoteCount ] = Common.useFetchQuote({
        routeSets: [RouteSet.BEFORE_ESTIMATE, RouteSet.BEFORE_FINAL_PRICE],
        extraData: getExtrasData(fields.all as any),
        quoteType: 'FULL_QUOTE',
        onSuccess: ()=>{},
    });

    useEffect(() => {
        fields.setValue(propertyContactFullname)(fields.value(requesterFirstName) + " " + fields.value(requesterSurname));
    }, [fields.value(requesterFirstName), fields.value(requesterSurname)]);


    useEffect(() => {
        fields.setValue(propertyContactEmail)(fields.value(requesterEmail));
    }, [fields.value(requesterEmail)]);


    useEffect(() => {
        fields.setValue(propertyContactPhone)(fields.value(requesterPhone));
    }, [fields.value(requesterPhone)]);


    useEffect(() => {
        if(loadingQuote){
            loading.beginLoading();
        }else{
            loading.endLoading();
        }
    }, [loadingQuote, fetchQuoteCount]);

    useEffect(() => {
        if(loadingQuote == false && fetchQuoteCount > 0){
            alert.show({
                message: <>
                    <h5 className="pt-2"><b>One of our team members will contact you soon to discuss your quote.</b></h5>
                    <p className="pt-2">Please refer to the important information relating to this product in the Product Disclosure Statement and Policy Wording.</p>
                </>,
                redirect: env.ERROR_REDIRECT
            });
        }
    }, [loadingQuote, fetchQuoteCount]);


    async function onSubmit(){
        try{
            await validation.validate(rules);
            fetchQuote();
        }catch(e){}
    }

    
    return (
        <Step showBack={false} warnUnload={false}>
            <strong>
                <h2><b>We're sorry, we can't give you a price just yet...</b></h2>
                <p>One of our team members will contact you soon to discuss your quote.</p>
                <ul>
                    { Object.keys(referrals).map(key => <li>{referrals[key]}</li>) }
                </ul>
                <p>Please refer to the important information relating to this product in the Product Disclosure Statement and Policy Wording.</p>
                {(referenceCodeValue) &&
                    <h4 className="pt-2"> Our Underwriting Team would like to review your request to ensure that you are getting the best possible quote as part of our partnering with Collective Insurance Brokers. </h4>
                }
                <h3>Contact details</h3>
                
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Input field={requesterFirstName} type="text" label="First name"/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input field={requesterSurname} type="text" label="Surname"/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input field={requesterPhone} type="tel" label="Phone number"/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input field={requesterEmail} type="email" label="Email"/>
                    </div>
                </div>
                <ButtonContainer>
                    <Button label="Submit" type="primary" icon="fas fa-chevron-right"  callback={ onSubmit }/>
                </ButtonContainer>
            </strong>
        </Step>
    )

}

export default Referred;