import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const floodRequested = fieldName('floodRequested');

export const rules = {
    [floodRequested]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Would you like flood cover? <HelpIcon label={floodRequested} message=""/></h2>
            <ButtonsYesNo field={floodRequested} callback={ navigation.goToNext } />
        </Step>
    )
}