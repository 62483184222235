import React, { MutableRefObject, useRef } from 'react'
import Step from '../../components/Step'
import Button from '../../elements/Button'
import ButtonContainer from '../../elements/ButtonContainer'
import ParagraphCard from '../../elements/ParagraphCard'
import Resi from '../Resi'
import {fieldName, enforce} from '../HelpersResi'
import env from '../../env'
import { OwnersCorpHelpText } from './owners-corporation-number'
import googleAnalytics from '../../common/services/google-analytics'
import ReCAPTCHA from "react-google-recaptcha";

const dutyOfDisclosure = fieldName('dutyOfDisclosure')
const referenceCode = fieldName('referenceCode')
const recaptcha = fieldName('recaptcha')


export const rules = {
    [ dutyOfDisclosure]: enforce.required("Required"),
    [ referenceCode ]: enforce.optional(''),
    [recaptcha]:[ enforce.required("Required")],

    [fieldName('hasPlayground')]:[],
    [fieldName('selfManaged')]:[],

    [fieldName('lossOfRentPercent')]:[],
    [fieldName('paintBenefit')]: [],
    [fieldName('epsPercent')]: [],
   
    [fieldName('requester.firstName')]:[],
    [fieldName('requester.surname')]:[],
    [fieldName('requester.phone')]:[],
    [fieldName('requester.email')]:[],

    [fieldName('propertyContact.fullName')]: [],
    [fieldName('propertyContact.email')]: [],
    [fieldName('propertyContact.phone')]: [],

    [fieldName('strataManagementCompanyName')]:[],
    [fieldName('strataManagerContact.fullName')]:[],
    [fieldName('strataManagerContact.email')]:[],
    [fieldName('strataManagerContact.phone')]:[],
}


export default () => {
    const navigation = Resi.useNavigation();
    const fields = Resi.useFields();
    const alert = Resi.useAlert();
    const recaptchaRef = useRef() as MutableRefObject<ReCAPTCHA>;

    const setDisclosure = (accepted:boolean) => async () => {

        const token = await (recaptchaRef.current as any).executeAsync();

        if(!token){
            alert.show({
                title: "Error",
                message: "Invalid Recaptcha",
            })
            return;
        }

        fields.setValues({
            [dutyOfDisclosure]: accepted,
            [recaptcha]: token
        })

        if(accepted){
            navigation.goToNext();
        }else{
            alert.show({
                title: "We can't offer you insurance right now",
                message: <>Due to our underwriting criteria we are unable to offer you insurance at this current time. Please contact us on <a href={"tel:"+env.CONTACT_PHONE}>{env.CONTACT_PHONE}</a> for more information.</>,
                redirect: env.ERROR_REDIRECT,
            })
        }
    }

    function showOwnersCorpText(){
        alert.show({
            message: <OwnersCorpHelpText />
        })
    }

    function onOwnersCorporationNumberClicked(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.LINK_CLICK,  "owners corporation number")
        showOwnersCorpText()
    }

    function onOwnersCorporationClicked(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.LINK_CLICK,  "Owners Corporation")
    }

    function onPDSClicked(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.LINK_CLICK,  "PDS")
    }

    function onPrivacyPolicyClicked(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.LINK_CLICK,  "Privacy Policy")
    }

    function onLegalNoticesClicked(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.LINK_CLICK,  "Legal Notices")
    }

    function onDutyToNotMispresent(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.LINK_CLICK,  "Duty to not misrepresent")
    }

    return (
        <Step warnUnload={false} showBack={false}>
            <h2>You're 5 minutes away from your quote. But first a few important things…</h2>
            <p>Applying is easy. The application should take 5-10 minutes. You'll need both your <a className="anchor" onClick={onOwnersCorporationNumberClicked}>owners corporation number</a> and your property's construction details to complete the form.</p>
            <ParagraphCard>
                <p>By continuing with this quote you agree that you have the authority to obtain a
quote on behalf of the <a className="anchor" onClick={onOwnersCorporationClicked}>Owners Corporation</a>; the right <a href="https://www.flexinsurance.com.au/residential-strata/#importantdocuments" target="_blank" rel="noopener noreferrer" onClick={onPDSClicked}>PDS</a> has been made
available to you; you have read and agree with our <a href="https://flexinsurance.com.au/resource/privacy-policy/" target="_blank" rel="noopener noreferrer" onClick={onPrivacyPolicyClicked}>Privacy Policy</a>, <a href="https://flexinsurance.com.au/resource/legal-notices/" target="_blank" rel="noopener noreferrer" onClick={onLegalNoticesClicked}>Legal Notices</a> and <a href=" https://www.flexinsurance.com.au/resource/duty-to-not-misrepresent" target="_blank" rel="noopener noreferrer" onClick={onDutyToNotMispresent}>Duty to not misrepresent</a> and consent to receiving policy
documents via electronic means and relevant Flex Insurance marketing communications.</p>
            </ParagraphCard>
            <div className="pt-2">
                <ButtonContainer>
                    <Button label="Continue" type="primary" icon="fas fa-chevron-right" callback={ setDisclosure(true) } />
                </ButtonContainer>
                <br/>
                <br/>
                <ReCAPTCHA ref={recaptchaRef} badge='inline' sitekey={env.RECAPTCHA} size="invisible"/>
            </div>
        </Step>
    )
};


