import React from 'react'
import Step from '../../components/Step'
import ButtonSelect from '../../elements/ButtonSelect'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'

const roofConstruction = fieldName('roofConstruction')

export const rules  = {
    [roofConstruction]:[]
}


export default () => {
    const navigation = Resi.useNavigation();
    const fields = Resi.useFields();
    const options = Resi.useOptions();


    function onSelected(value:string){
        fields.setValue(roofConstruction)(value);
        navigation.goToNext();
    }

    return (
        <Step>
            <h2>Tell us about the main building material of the roof.</h2>
            <p>If different building materials have been used, please select the primary type of material.</p>
            <ButtonSelect 
                options={ options.data.product.ROOF_CONSTRUCTION }
                selected={fields.value(roofConstruction)}
                colsLg={ 3 }
                colsSm={ 1 }
                callback={ onSelected }
            />
        </Step>
    )
};