import React, { useEffect, useState } from 'react'
import SaveAndEmailButton from '../elements/SaveAndEmailButton';
import { useLocation } from 'react-router-dom';


const SaveAndEmailHover = () => {  
    let [show, setShow] = useState(true)
    let location = useLocation();

    useEffect(() => {
        if(location.pathname == "/final-price"){
            setShow(true)
        }else{
            setShow(false)
        }
    }, [location.pathname])
    
    if(!show) return null
    return (
        <div className="save-and-email-hover d-none d-lg-block d-xl-block">
            <SaveAndEmailButton />
        </div>
    )
}

export default SaveAndEmailHover;