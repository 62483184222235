import { useEffect } from "react";
import Common from "../Common";
import Services from "../services";
import { RouteSet } from "../../types";
import googleAnalytics from "../services/google-analytics";

type QuoteType = "QUICK_QUOTE"|"FULL_QUOTE"|"PAYMENT";


function getQuoteService(type:QuoteType){
    switch(type){
        case "QUICK_QUOTE": return Services.quote("QUICK_QUOTE");
        case "FULL_QUOTE": return Services.quoteAndInstalments;
        case "PAYMENT": return Services.quoteAndInstalments;
    }
}

export function useFetchQuote({routeSets, extraData, quoteType, onSuccess}:{routeSets:RouteSet[], extraData:Record<string, any>, quoteType:"QUICK_QUOTE"|"FULL_QUOTE"|"PAYMENT", onSuccess:()=>void}){
    const service = getQuoteService(quoteType);
    const [ quote, loadingQuote, fetchQuote, fetchQuoteCount ] = Common.useFetch(service);
    const fields = Common.useFields();
    const navigation = Common.useNavigation();
    const alert = Common.useAlert();

    useEffect(() => {
        if(quote){
            if(quote!.errors){
                
            }else{
                if(fetchQuoteCount == 1 && (quoteType=="QUICK_QUOTE" || quoteType=="FULL_QUOTE")) Services.googleAnalytics.quoteCreated(quote!.quoteNumber);
                fields.setFromQuoteData(quote, routeSets);
            
                if(quote!.declined){
                    googleAnalytics.declined();
                    navigation.goTo('/declined');
                }else if(quote!.referred){
                    googleAnalytics.referred();
                    navigation.goTo('/referred');
                }else{
                    onSuccess()
                }
            }
        }
    }, [quote, fetchQuoteCount])


    async function fetchQuoteWithArgs(){
        const dataToSend = {
            ...fields.prepareData(routeSets),
            ...Common.util.object.unflatten(extraData),
        }
        await fetchQuote(dataToSend)
    }

    return [ fetchQuoteWithArgs, loadingQuote, fetchQuoteCount ] as const;
}