import { useEffect } from 'react'
import { useCommonEffects } from '../common/EffectsCommon'
import { intialFieldValues } from './FieldsResi'
import { routes } from './RoutesResi'
import Services from './services'
import Resi from './Resi'
import {fieldName, routeName} from './HelpersResi'
import moment from 'moment'

export const olderThanYears = (fields:ReturnType<typeof Resi.useFields> ) => (years:number) => moment().year() - fields.value('yearBuilt') > years;
    

function useHandleFieldConditions(){
    const fields = Resi.useFields();
    const conditions = Resi.useConditions();
    const { valueFor, value } = fields;

    const state = valueFor('ownersCorporationAddress.state');

    // Field and route conditions.
    // Every time there is a field change, we recalculte the 'conditions'.
    // See the conditions change in the Redux Dev Tools when you change a 
    // relevant field.
    useEffect(() => {
        conditions.setConditions({
            // Field conditions
            [fieldName('paintBenefit')]: valueFor('ownersCorporationAddress.state')=='ACT' || valueFor('ownersCorporationAddress.state')=='NSW',
            [fieldName('additionalComments')]: valueFor('numberOfClaims') != '0',
            [fieldName('valueOfClaims')]: valueFor('numberOfClaims') != '0',
            [fieldName('propertyContact.fullName')]: valueFor('selfManaged')==true,
            [fieldName('propertyContact.email')]: valueFor('selfManaged')==true,
            [fieldName('propertyContact.phone')]: valueFor('selfManaged')==true,

            // Route conditions
            [routeName('/plan-number-available')]: valueFor('currentInsurer')=='NEW_STRATA_PLAN',
            [routeName('/owners-corporation-number')]: valueFor('currentInsurer')!='NEW_STRATA_PLAN' || valueFor('strataPlanNumberAvailable')==true,
            [routeName('/owners-corp-type')]: valueFor('ownersCorporationAddress.state') == 'VIC',
            [routeName('/year-rewired')]: olderThanYears(fields)(57) && valueFor('buildingSumInsured') > 1_000_000,
            [routeName('/year-refurbished')]: olderThanYears(fields)(57) && valueFor('buildingSumInsured') > 1_000_000,
            [routeName('/year-replumbed')]: olderThanYears(fields)(57) && valueFor('buildingSumInsured') > 1_000_000,
            [routeName('/heritage-listed')]: olderThanYears(fields)(80),
            [routeName('/building-name')]: valueFor('ownersCorporationAddress.state')=='QLD' || valueFor('ownersCorporationAddress.state') == 'WA',
            [routeName('/property-expiring-excess')]: valueFor('currentInsurer') !== "NOT_CURRENTLY_INSURED" && valueFor('currentInsurer') !== "NOT_KNOWN",
            [routeName('/additional-cover-options')]: valueFor('__coverType__') == 'choose' ||  !valueFor('__coverType__'),
            [routeName('/property-contact')]: valueFor('selfManaged') == true,
            [routeName('/strata-manager-contact')]: valueFor('selfManaged') == false,
            [routeName('/equipment-in-basements')]: valueFor('numberOfBasements') > 0,
            [routeName('/property-expiring-excess')]: valueFor('currentInsurer')!="NOT_KNOWN" && valueFor('currentInsurer')!="NEW_STRATA_PLAN" && valueFor('currentInsurer')!="NOT_CURRENTLY_INSURED",
        });
    }, [fields.all])

    // Every time the __coverType__ selection changes, we recalculate the selection type
    // for the addition cover options.
    useEffect(() => {
        switch(valueFor('__coverType__')){
            case 'basic':
                fields.setValues({
                    [fieldName('__LI__')]: 'mandatory',
                    [fieldName('__VW__')]: state=='NSW' ? 'mandatory' : 'no',
                    [fieldName('__WC__')]: 'no',
                    [fieldName('__FG__')]: state=='SA' ? 'mandatory' : 'no',
                    [fieldName('__OB__')]: 'no',
                    [fieldName('__MB__')]: 'no',
                    [fieldName('__CC__')]: 'no',
                    [fieldName('__GA__')]: 'no',
                    [fieldName('__LO__')]: 'no',
                    [fieldName('__EC__')]: 'no',
                    [fieldName('__ET__')]: 'no',
                    [fieldName('__FU__')]: 'no',
                    [fieldName('__FF__')]: 'no',
                    [fieldName('__SB__')]: 'no',
                })    
                break;

            case 'choose':
                fields.setValues({
                    [fieldName('__LI__')]: 'mandatory',
                    [fieldName('__VW__')]: state=='NSW' ? 'mandatory' : 'optional',
                    [fieldName('__WC__')]: state=='WA'||state=='TAS'||state=='ACT' ? 'optional' : 'no',
                    [fieldName('__FG__')]: state=='SA' ? 'mandatory' : 'optional',
                    [fieldName('__OB__')]: 'optional',
                    [fieldName('__MB__')]: 'optional',
                    [fieldName('__CC__')]: 'optional',
                    [fieldName('__GA__')]: 'optional',
                    [fieldName('__LO__')]: 'optional',
                    [fieldName('__EC__')]: 'optional',
                    [fieldName('__ET__')]: 'optional',
                    [fieldName('__FU__')]: 'optional',
                    [fieldName('__FF__')]: 'optional',
                    [fieldName('__SB__')]: 'optional',
                })  
                break;
            case 'all':
                fields.setValues({
                    [fieldName('__LI__')]: 'mandatory',
                    [fieldName('__VW__')]: 'mandatory',
                    [fieldName('__WC__')]: state=='WA'||state=='TAS' ? 'mandatory' : 'no',
                    [fieldName('__FG__')]: 'mandatory',
                    [fieldName('__OB__')]: 'mandatory',
                    [fieldName('__MB__')]: 'mandatory',
                    [fieldName('__CC__')]: 'mandatory',
                    [fieldName('__GA__')]: 'mandatory',
                    [fieldName('__LO__')]: 'mandatory',
                    [fieldName('__EC__')]: 'mandatory',
                    [fieldName('__ET__')]: 'mandatory',
                    [fieldName('__FU__')]: 'mandatory',
                    [fieldName('__FF__')]: 'mandatory',
                    [fieldName('__SB__')]: 'mandatory',
                })  
                break;
        }

    }, [valueFor('__coverType__')])
  
}


export const useResiEffects = () => {
    useCommonEffects(intialFieldValues, routes, Services.resiOptions)
    useHandleFieldConditions()
}
