import React from 'react'
import Common from '../common/Common'
import googleAnalytics from '../common/services/google-analytics';


const chevron = (collapsed:boolean) => (
    <>
        <span className={collapsed?'d-none':''}><i className={`fas fa-chevron-down`}/></span>
        <span className={!collapsed?'d-none':''}><i className={`fas fa-chevron-up`}/></span>
    </>
)

const ShowLegalButton = () => {
    const fields = Common.useFields();
    const showLegal = fields.value('__showLegal__');

    function onClick(){
        if(showLegal) googleAnalytics.customInteraction(googleAnalytics.Actions.BUTTON_CLICK, "Legal", "hide")
        else googleAnalytics.customInteraction(googleAnalytics.Actions.BUTTON_CLICK, "Legal", "show")
        fields.setValue('__showLegal__')(!showLegal);
    }

    return (
        <div className="show-legal-button">
            <div className="btn btn-secondary" onClick={onClick}>{ showLegal ? "Legal" : "Legal" } { chevron(!showLegal)}</div>
        </div>
    )
}


export default ShowLegalButton;
