import Request from './request';
import Common from '../Common';


export default async (quoteNumber:string, requesterEmail:string) => {
    const url = Common.config.services.findToken

    let data:any = await Request.postWithoutToken(url, {
        quoteNumber,
        requesterEmail,
    });
    
    return data;
}
