import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const commerciallyUsed = fieldName('commerciallyUsed');


export const rules = {
    [commerciallyUsed]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Is more than 20% of your building (by floor area) used for commercial purposes? <HelpIcon label={commerciallyUsed} message=""/></h2>
            <ButtonsYesNo field={commerciallyUsed} callback={ navigation.goToNext } />
        </Step>
    )
}