import React, { useEffect, useState } from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import Input from '../../elements/Input'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const strataPlanNumberAvailable = fieldName('strataPlanNumberAvailable')
const ownersCorporationNumber = fieldName('ownersCorporationNumber')


export const rules = {
    [ownersCorporationNumber]:[
        enforce.required("Required")
    ],
}


export default () => {
    const navigation = Resi.useNavigation();

    return (
        <Step>
            <h2>Do you have a plan number? <HelpIcon label={strataPlanNumberAvailable} message=""/></h2>
            <ButtonsYesNo field={strataPlanNumberAvailable } callback={ navigation.goToNext } />
        </Step>
    )
}

