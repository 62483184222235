import { RouteDefinition, RouteSet, /*RouteGroup,*/ RoutesMap } from '../../types'
import { flatten } from 'lodash/fp'


const routeGroup = (route:RouteDefinition) => route[0];
const routeName = (route:RouteDefinition) => route[1];
const routeComponent = (route:RouteDefinition) => route[2];


const fieldNamesForComponent = (component:any) => {
    if(!component.rules) throw("'export const rules' is required on mobule: " + component);
    return Object.keys(component.rules);
}


const routeIs = (routes:RoutesMap, set:RouteSet) => routes.filter(route => (routeGroup(route) == set))

const fieldNamesWhereRouteIs = (routes:RoutesMap, set:RouteSet) => {
    const relevantRouteData = routeIs(routes, set);
    const relevantRouteComponents = relevantRouteData.map(routeComponent);
    return flatten(relevantRouteComponents.map(fieldNamesForComponent));
}

const routeNamesWhereRouteIs = (routes:RoutesMap, set:RouteSet) => routeIs(routes, set).map(routeName);

export default {
    fieldNamesWhereRouteIs,
    routeNamesWhereRouteIs,
}
