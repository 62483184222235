import React from 'react';
import googleAnalytics from '../common/services/google-analytics';
import Common from './../common/Common'

type Props = {
    size?: 'fa-lg',
    title?:React.ReactNode,
    label:string,
    message:React.ReactNode
}


export const HelpIcon = ({size, title, message, label}:Props) => {
    const alert = Common.useAlert();

    if(message == "") return null;
    
    const showAlert = (e:{ stopPropagation:Function, preventDefault:Function }) => {
        
        e.stopPropagation();
        e.preventDefault();

        var ieEvent:any = e;
        if(ieEvent.returnValue) ieEvent.returnValue = false;
        if(ieEvent.stopImmediatePropagation) ieEvent.stopImmediatePropagation();
        ieEvent.cancelBubble = true;
        //if(e.returnValue)
        
        googleAnalytics.customInteraction(googleAnalytics.Actions.INFO_CLICK, label)

        alert.show({
            title,
            message,
        })

        return false;
    }
    
    return (
        <div className="help-icon" onClick={ showAlert }>
            <i className={`fas fa-info-circle fa-2x ${size?size:""}`} />
        </div>
    )
}

export default HelpIcon