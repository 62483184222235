import env from '../../env'
import Request from './request'
import Common from '../Common'


type CalculateInstalmentsResponse = {
    effectiveDate: number,
    expiryDate: number,
    inceptionDate: number,
    instalmentPayments:{
        instalmentDate: number,
        instalmentAmount: number,
    }[],
    insuredName: string,
    productName: string,
    quoteOrPolicyNumber: string,
    situationAddress:{
        additionalInfo:null|any,// TODO: Find out what type this is
        deliveryPoint:null|string,
        floodGradeFallBackLevelEnum:null|string,// TODO: Find out what type this is,
        gnafAddressFactors:{
            bushfireRelativity:number,
            chubb26Parallel: string,
            chubbBushFireRisk: string,
            chubbFloodRisk: string,
            floodBuildingRatingZone: string
            hailRelativity: number,
            nonHailRelativity: number, 
            postcodePerilFactors:{
                earthquakeRelativity: number,
                windstormRelativity: number,
            },
        },
        gnafFactorsLevelEnum: string, // TODO: Get enum values
        gnafPid: string,
        ignoreValidation: boolean,
        level: null|any, // TODO: Find out what type this is
        postcode: string,
        score: null|any, // TODO: Find out what type this is
        state: "VIC" | "NSW" | "QLD" | "NT" | "SA" | "WA"| "TAS" | "ACT",
        streetName: string,
        streetNumber: string,
        suburb: string,
    },
    totalPayable: number,
}


export default async function(){
    const externalId = Request.getToken();
  
    // when testing in local, we might not get a token sometimes due to api issues, so return null to proceed with the quote
    /*if (!externalId) {
        return null;
    }*/
    
    
    const result:CalculateInstalmentsResponse = await Request.get(Common.config.services.calculateInstalments, {externalId});
    
    return {
        ...result,
        effectiveDate: new Date(result.effectiveDate),
        expiryDate: new Date(result.expiryDate),
        inceptionDate: new Date(result.inceptionDate),
        instalmentPayments: result.instalmentPayments.map(payment => ({
            ...payment,
            instalmentDate: new Date(payment.instalmentDate),
        })),
    };
}