import Request from './request';
import Common from '../Common';

// The quote can have more fields then this, but we are
// just specifying some important fields for applying logic.
// If you need to access more fields from the /quote response
// in common code, add them here.
type QuoteResult = {
  quoteNumber: string,
  referred: boolean,
  referrals: object,
  declined: boolean,
  totalPrice: number,
  showPrice: number,
  totalPayable?: number,
  errors?: Record<string, string>
}


export default (type:'QUICK_QUOTE'|'FULL_QUOTE') => async (fields:any) => {
    const url = Common.config.services.quote;

    const token = Request.getToken();
    if(token){
      fields = {
        ...fields,
        id: token,
        token: token,
      }
    }
    
    const data:QuoteResult = await Request.postWithoutToken(url, fields);
    return data;
}
