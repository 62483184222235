import { Fields as CommonFields } from '../common/FieldsCommon'
import { intialFieldValues as initialFieldValuesCommon } from '../common/FieldsCommon'
import { fieldName } from './HelpersResi'
import QueryString from 'query-string'
import moment from 'moment'

// All the request and response fields are specified in the Fields type.
// We also specificy some other miscellaneous/incidental fields that are
// generally useful that are specific to the quote request/response.
// As a convention we use underscores (__fieldName__) to denote these
// intciental fields.
// Note: The value type of the field is NOT enforced. You can end up with
// a different value type depending on how the component handles the field.
export type Fields = CommonFields & {
    // Quote fields
    'referenceCode':null, 
    'propertyInsuranceDeclined': boolean,
    'coverLimited': boolean,
    'numberOfClaims': string,
    'valueOfClaims': number,
    'additionalComments': string,
    'startDate': string,
    'expiryDate': string,
    'currentInsurer': string,
    'otherInsurer': string,
    'actualAccountCommissionRate': number,
    'requester.firstName': string,
    'requester.surname': string,
    'requester.phone': string,
    'requester.email': string,
    'strataPlanNumberAvailable':boolean,
    'ownersCorporationAddress.streetNumber': string,
    'ownersCorporationAddress.streetName': string,
    'ownersCorporationAddress.suburb': string,
    'ownersCorporationAddress.state': string,
    'ownersCorporationAddress.postcode': string,
    'ownersCorporationNumber': string,
    'addressNotFound':boolean,
    "strataManagementCompanyName":string, 
    "strataManagerContact.fullName":string,
    "strataManagerContact.email":string,
    "strataManagerContact.phone":string,
    'propertyContact.fullName': string,
    'propertyContact.email': string,
    'propertyContact.phone': string,
    'selfManaged': boolean,
    'ownersCorporationType': string,
    'yearBuilt': number,
    'yearRewired': number,
    'yearRefurbished': number,
    'yearReplumbed': number,
    'heritageListed': boolean,
    'numberOfLots': number,
    'numberOfFloors': number,
    'numberOfBasements': number,
    'buildingName': string,
    'wallConstruction': string,
    'roofConstruction': string,
    'floorConstruction': string,
    'epsPercent': number,
    'aluminiumCompositePanels': string,
    'percentageCovered': number,
    'commonIncome': number,
    'numberOfLifts': number,
    'numberOfPoolsOrSpas': number,
    'groundFloorOccupancy': string,
    'commerciallyUsed': number,
    'sprinklersLocation': string,
    'fireAlarm': boolean,
    'fireExtinguishers': boolean,
    'fireHydrantsHoseReels': boolean,
    'fullyOccupied': boolean,
    'hasPlayground': boolean,
    'jettyWharfPontoonMarina': boolean,
    'lakePondWaterway': boolean,
    'gymUsedByPublic': boolean,
    'knownDefects': boolean,
    'buildingSumInsured': number,
    'contentsSumInsured': number,
    'currentExcess': number,
    'floodRequested': boolean,
    'exploratoryCostsRequested': boolean,
    'extendedTempAccomodationLossRentRequested': boolean,
    'fusionRequested': boolean,
    'floatingFloorsRequested': boolean,
    'specialBenefitsExtensionRequested': boolean,
    'waterDamageExcess': number,
    'propertyExcess': number,
    'liabilitySumInsured': number,
    
    'lossOfRentPercent': number,
    'lossOfRentSum': number,
    
    'voluntaryWorkersRequired': any,
    'voluntaryWorkersDisablementWeekly':number,
    'voluntaryWorkersDeath': any,
    'voluntaryWorkersDisablement': any,
    
    'workersCompensationRequired': any,
    'estimatedWages': number,
    'employeesEstimatedWages': number,
    'labourOnlyEstimatedWages': number,
    'labourMaterialsEstimatedWages': number,
    'labourPlantEstimatedWages': number,
    'labourPlantMaterialsEstimatedWages': number,
    
    'fidelityRequired': any,
    'fidelitySumInsured': any,
    
    'officeBearersRequired': any,
    'officeBearersSumInsured': any,
    
    'machineryBreakdownRequired': any,
    'machineryBreakdownSumInsured': any,
    'carStackerTurnTable': any,
    'airConditioningLocation': any,
    'electronicMotors5KW': any,
    'chillersOrCoolingTowers': any,

    'catastropheRequired': any,
    'catastrophePercent': any,
    'catastropheCoverAmount': any,
    'storageAndEvacuationAmount': any,
    
    'temporaryAccommodationAmount': any,
    'temporaryAccommodationEscalationOfCostsAmount': any,
    
    'governmentAuditIncluded': any,
    'governmentAuditAmount': any,
    'appealExpensesAmount': any,
    'legalDefenceAmount': any,
    
    'lotOwnersIncluded': any,
    'lotOwnersSumInsured': any,

    'paintBenefit': any,
    'equipmentInBasements':boolean,

    '__owners-corp-prefix__':string, // This is just a dummy value that doesn't get sent to the server

    // Not yet relevant, but this will be used to keep track of the user's
    // payment selection.
    '__paymentSchedule__': string,

    // This keeps track of the user's selection of "Address not found" on the 
    // address auto complete step
    '__address__': string | "NOT_FOUND",


    // Below are a bunch of incidental fields relating managing the state
    // of the user's selection of additional cover options.
    
    // Keeps track of the user's cover type selection
    '__coverType__': 'basic' | 'choose' | 'all',

    // These fields are set via an effect. They denote the selection type available
    // for the additional cover options. See EffectsResi.ts.
    '__LI__':'optional'|'mandatory'|'no', // Liability
    '__VW__':'optional'|'mandatory'|'no', // Voluntary workers
    '__WC__':'optional'|'mandatory'|'no', // Workers Compensation
    '__FG__':'optional'|'mandatory'|'no', // Fidelity Guarantee
    '__OB__':'optional'|'mandatory'|'no', // Office Bearers
    '__MB__':'optional'|'mandatory'|'no', // Machinery Breakdown
    '__CC__':'optional'|'mandatory'|'no', // Catastrophe Cover
    '__GA__':'optional'|'mandatory'|'no', // Government Audit
    '__LO__':'optional'|'mandatory'|'no', // Lot Owner’s Fixtures and Improvements
    '__EC__':'optional'|'mandatory'|'no', // Exploratory costs (Flex+)
    '__ET__':'optional'|'mandatory'|'no', // Extended Temporary accommodation or Loss of Rent (Flex+)
    '__FU__':'optional'|'mandatory'|'no', // Fusion (Flex+)
    '__FF__':'optional'|'mandatory'|'no', // Floating floors (Flex+)
    '__SB__':'optional'|'mandatory'|'no', // Special benefits (Flex+)

    // These fields denote the user's selection of additional cover options.
    '__li__':boolean, // Liability
    '__vw__':boolean, // Voluntary workers
    '__wc__':boolean, // Workers Compensation
    '__fg__':boolean, // Fidelity Guarantee
    '__ob__':boolean, // Office Bearers
    '__mb__':boolean, // Machinery Breakdown
    '__cc__':boolean, // Catastrophe Cover
    '__ga__':boolean, // Government Audit
    '__lo__':boolean, // Lot Owner’s Fixtures and Improvements
    '__ec__':boolean, // Exploratory costs (Flex+)
    '__et__':boolean, // Extended Temporary accommodation or Loss of Rent (Flex+)
    '__fu__':boolean, // Fusion (Flex+)
    '__ff__':boolean, // Floating floors (Flex+)
    '__sb__':boolean, // Special benefits (Flex+)

    // Selection for property contact checkboxes
    '__pc_same_as_requester__':boolean,

    'referrals'?:Record<string, string>,

    'recaptcha':string|null,
}


// These field values are set on form initialisation.
// If fields are to be prepulated or hard coded, they are typically
// set here.
export const intialFieldValues = {
    ...initialFieldValuesCommon,

    [fieldName('__owners-corp-prefix__')]: 'SP',
    [fieldName('__paymentSchedule__')]: 'YEARLY',
    [fieldName('addressNotFound')]: false,

    [fieldName('lossOfRentPercent')]: 15,
    [fieldName('epsPercent')]: 0,
    [fieldName('airConditioningLocation')]: "NONE",
    [fieldName('carStackerTurnTable')]: false,
    [fieldName('chillersOrCoolingTowers')]: false,
    [fieldName('electronicMotors5KW')]: false,
    [fieldName('paintBenefit')]: false,
    [fieldName('startDate')]: moment().format("DD/MM/YYYY"),

    // We are setting prepopulating the requester, strata manager, and
    // property contact details to BLANK, because they are nessessary for
    // a successful quote, but we don't want to ask them before the quote.
    // Note: There is a special condition of the Input component, where if
    // the value is BLANK, it will appear as an empty text field.
    // Also Note: A special rule must be added to the relevant field in the
    // step to properly validate a field with value BLANK. See steps/requester.tsx
    // as an example.
    [fieldName('requester.firstName')]: 'BLANK',
    [fieldName('requester.surname')]: 'BLANK',
    [fieldName('requester.phone')]: 'BLANK',
    [fieldName('requester.email')]: 'BLANK',
    [fieldName('strataManagementCompanyName')]: "BLANK",
    [fieldName('strataManagerContact.fullName')]: "BLANK",
    [fieldName('strataManagerContact.email')]: "BLANK",
    [fieldName('strataManagerContact.phone')]: "BLANK",
    [fieldName('propertyContact.fullName')]: "BLANK",
    [fieldName('propertyContact.email')]: "BLANK",
    [fieldName('propertyContact.phone')]: "BLANK",

    [fieldName('hasPlayground')]: false,
    [fieldName('selfManaged')]: true,

    [fieldName("referenceCode")]: QueryString.parse(QueryString.extract(window.location.href)).referenceCode,
};
