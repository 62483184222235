import React from 'react'
import Step from '../../../components/Step'
import Common from '../../../common/Common'
import env from '../../../env'
import QueryString from 'query-string'


const PaymentResult = () => {
    const policyNumber = QueryString.parse(QueryString.extract(window.location.href)).policyNumber;

    return (
        <Step warnUnload={false} showBack={false} preventRestore={true}>
            <h2>Thank you</h2>
            <h3>Your policy number is { policyNumber }</h3>
            <p>You will also receive a copy of your policy via email to the address you provided.</p>
            <a href={ env.ERROR_REDIRECT } className="btn btn-primary btn-lg mt-2">Return Home</a>
        </Step>
    )
}

export default PaymentResult;
