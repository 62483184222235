import querystring from 'querystring';
import axios from 'axios';
import services from '.';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

let token:string|null = null;


async function request<P extends object>(method:Method, url:string, payload?:P){
    const response = await axios(url, {
        method,
        //mode: 'no-cors',
        data: payload ? JSON.stringify(payload) : undefined,
        validateStatus: (status) => (status >= 200 && status < 300) || status==400,
        headers:{
            'Content-Type': 'application/json'
        }
    });

    try{
        const json = await response.data;
        if(json.errors){
            services.googleAnalytics.businessValidationError(json.errors);
            return json;
        }else if(response.status == 400){
            throw(response.data);
        }else{
            if(json.token) token = json.token;
            return json;
        }
    }catch(e){
        console.log(e);
    }
}


async function get(url:string, params?:querystring.ParsedUrlQueryInput){
    url = params
        ? url+'?'+querystring.stringify(params)
        : url;
    return request('GET', url);
}


async function post<P extends object>(url:string, payload:P){
    payload = { ...payload, token };
    return request('POST', url, payload);
}


async function postWithoutToken<P extends object>(url:string, payload:P){
    return request('POST', url, payload);
}



export default {
    get,
    post,
    postWithoutToken,
    getToken: () => token,
}