import React, { useEffect } from 'react'
import Step from '../../components/Step'
import Input from '../../elements/Input'
import ButtonContainer from '../../elements/ButtonContainer'
import Button from '../../elements/Button'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import Checkbox from '../../elements/Checkbox'


const propertyContactFullName = fieldName('propertyContact.fullName')
const propertyContactEmail = fieldName('propertyContact.email')
const propertyContactPhone = fieldName('propertyContact.phone')


export const rules = {
    [propertyContactFullName]: [ 
        enforce.required('Required')
    ],
    [propertyContactPhone]: [
        enforce.required('Required'),
        enforce.phoneNumber("Must be a valid phone number"),
    ],
    [propertyContactEmail]: [ 
        enforce.required('Required'),
        enforce.email("Must be a valid email"),
    ]
}

export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();
    const fields = Resi.useFields();

    async function onContinue(){
        try{
            await validation.validate(rules);
            navigation.goToNext();
        }catch(e){}
    }


    useEffect(() => {
        if(fields.value('__pc_same_as_requester__')){
            fields.setValues({
                [propertyContactFullName]: fields.valueFor('requester.firstName') + " " + fields.valueFor('requester.surname'),
                [propertyContactEmail]: fields.valueFor('requester.email'),
                [propertyContactPhone]: fields.valueFor('requester.phone'),
            });
        }else{
            fields.setValues({
                [propertyContactFullName]: 'BLANK',
                [propertyContactEmail]: 'BLANK',
                [propertyContactPhone]: 'BLANK',
            });
        }
    },[fields.value('__pc_same_as_requester__')]);


    return (
        <Step>
            <h2>Property contact</h2>
            <hr></hr>
            <Checkbox label="Same as policy holder" field="__pc_same_as_requester__"/>
            <hr></hr>
            <div className="row">
                <div className="col-12">
                    <Input field={propertyContactFullName} type="text" label="Property contact name(s)" disabled={fields.value('__pc_same_as_requester__')}/>
                </div>
                <div className="col-12 col-md-6 mt-2 mt-md-4">
                    <Input field={propertyContactPhone} type="tel" label="Property contact phone number" disabled={fields.value('__pc_same_as_requester__')}/>
                </div>
                <div className="col-12 col-md-6 mt-md-4">
                    <Input field={propertyContactEmail} type="email" label="Property contact email" disabled={fields.value('__pc_same_as_requester__')}/>
                </div>
            </div>
            <ButtonContainer>
                <Button label="Continue" type="primary" icon="fas fa-chevron-right"  callback={ onContinue }/>
            </ButtonContainer>
        </Step>
    )
};