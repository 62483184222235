import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'

const coverLimited = fieldName('coverLimited', 0);

export const rules = {
    [coverLimited]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
    
    return (
        <Step>
            <h2>Has the cover under your current policy been limited or restricted in any way?</h2>
            <ButtonsYesNo field={coverLimited} callback={ navigation.goToNext } />
        </Step>
    )
}