import Request from '../../common/services/request'
import Common from '../../common/Common'
import { Option } from '../../types'


type SumsInsuredOptionResponse = {
    riskClass: string,
    amount: number,
    description: string,
}

type SumsInsuredResponse = {
    productSumsInsured:{
        Fidelity: SumsInsuredOptionResponse[]
        GovernmentAuditAppealExpenses: SumsInsuredOptionResponse[]
        GovernmentAuditCosts: SumsInsuredOptionResponse[]
        GovernmentAuditDefenceExpenses: SumsInsuredOptionResponse[]
        Liability: SumsInsuredOptionResponse[]
        LotOwnersImprovements: SumsInsuredOptionResponse[]
        MachineryBreakdown: SumsInsuredOptionResponse[]
        OfficeBearersLiability: SumsInsuredOptionResponse[]
        VoluntaryWorkers: SumsInsuredOptionResponse[]
    }
}

type OptionResult = Option<number>[];

export type ResiSumsInsuredResult = {
    Fidelity: OptionResult
    GovernmentAuditAppealExpenses: OptionResult
    GovernmentAuditCosts: OptionResult
    GovernmentAuditDefenceExpenses: OptionResult
    Liability: OptionResult
    LotOwnersImprovements: OptionResult
    MachineryBreakdown: OptionResult
    OfficeBearersLiability: OptionResult
    VoluntaryWorkers: OptionResult
}


export default async () => {
    const url = Common.config.services.sumsInsured;

    let data:SumsInsuredResponse = await Request.get(url);

    function mapOption(option:any):OptionResult[number]{
        return {
            description: option.description,
            value: option.amount,
            type: option.riskClass,
        };
    }
    
    const result:ResiSumsInsuredResult = {
        Fidelity: data.productSumsInsured.Fidelity.map(mapOption),
        GovernmentAuditAppealExpenses: data.productSumsInsured.GovernmentAuditAppealExpenses.map(mapOption),
        GovernmentAuditCosts: data.productSumsInsured.GovernmentAuditCosts.map(mapOption),
        GovernmentAuditDefenceExpenses: data.productSumsInsured.GovernmentAuditDefenceExpenses.map(mapOption),
        Liability: data.productSumsInsured.Liability.map(mapOption),
        LotOwnersImprovements: data.productSumsInsured.LotOwnersImprovements.map(mapOption),
        MachineryBreakdown: data.productSumsInsured.MachineryBreakdown.map(mapOption),
        OfficeBearersLiability: data.productSumsInsured.OfficeBearersLiability.map(mapOption),
        VoluntaryWorkers: data.productSumsInsured.VoluntaryWorkers.map(mapOption),
    }

    return result;
}