import Common from '../Common'
import CurrencyFormatter from 'currency-formatter';
import { merge } from 'lodash/fp';
import { Option, RouteSet } from '../../types';
import { Fields as CommonFields } from '../../common/FieldsCommon'

export type Fields = CommonFields & {[key:string]:any};
export const fields = {} as Partial<Fields>;


export const useFields = <F extends Fields>() => {
    const store = Common.useStore();
    const conditions = Common.useConditions();
    const navigation = Common.useNavigation();
    const fields = store.get('fields');
    const set = store.set('fields');
    const any = fields as any;
    

    type State<T> = [T, (value:T)=>void];
    function state<T>(nameOrState:string | State<T>){
        if(typeof nameOrState === 'string'){
            return [
                value(nameOrState),
                setValue(nameOrState),
            ] as State<T>;
        }else{
            return nameOrState;
        }
    }


    function isOptionOther(name:string, options:Option<string>[]){
        const fieldValue = value(name);
        const notInOptions:boolean = options.filter(option => option.value === fieldValue).length === 0;
        const notEmpty = !!fieldValue || fieldValue === "";
        return notInOptions && notEmpty; 
    }

    
    function value(name:string):any{
        return fields[name];
    }


    function setValue(name:string){
        return function(value:any){
            set("setValue", state => ({
                [name]: value
            }))
        }
    }


    function setValues(partial:any, ...$:number[]){
        const result:any = {};
        const keys = Object.keys(partial);
        
        for(var k = 0; k < keys.length; k++){
            var key = keys[k];
            const name = Common.util.fields.fieldName(key, $);
            const value:any = partial[key];
            result[name] = value;
        }
        set("setValues", state => ({
            ...result,
        }))
    }


    function prepareData(routeSets:RouteSet[]){
        var fieldNames:string[] = []
        routeSets.forEach(routeSet => fieldNames = [
            ...fieldNames,
            ...navigation.fieldsForRoutes(routeSet)
        ])
        fieldNames = fieldNames.filter(conditions.passes);
        const toSend:any = {};
        fieldNames.forEach(fieldName => {
            toSend[fieldName] = fields[fieldName]
        });
        return Common.util.object.unflatten(toSend);
    }

    
    function valueFor<N extends keyof F&string, T extends F[N]>(key:N, ...$:number[]):T{
        const name = Common.util.fields.fieldName(key, $)
        return value(name);
    }


    function asText<N extends keyof F&string>(key:N, ...$:number[]):string|null{
        const name = Common.util.fields.fieldName(key, $);
        if(conditions.passes(name) === false) return null;
        return value(name);
     }


     function asDollar<N extends keyof F&string>(key:N, ...$:number[]):string|null{
        const name = Common.util.fields.fieldName(key, $);
        if(conditions.passes(name) === false) return null;
        return "$" + CurrencyFormatter.format(value(name), {
            thousand: ",",
            precision: 0
        })
     }


     function asYesNo<N extends keyof F&string>(key:N, ...$:number[]):string|null{
        const name = Common.util.fields.fieldName(key, $);
        if(conditions.passes(name) === false) return null;
        return value(name) ? "Yes" : "No"
     }


     function asOption<N extends keyof F&string>(options:Option<string>[], key:N, ...$:number[]):string|null{
        const name = Common.util.fields.fieldName(key, $);
        if(conditions.passes(name) === false) return null;
        return options[value(name)]?.description;
     }


    function setFromQuoteData(data:any, routeSets:RouteSet[]){
        var includingFields:string[] = []
        routeSets.forEach(routeSet => includingFields = [
            ...includingFields,
            ...navigation.fieldsForRoutes(routeSet)
        ])

        const allRouteSets = [RouteSet.BEFORE_ESTIMATE, RouteSet.BEFORE_FINAL_PRICE, RouteSet.BEFORE_PAYMENT];
        var allFields:string[] = []
        allRouteSets.forEach(routeSet => allFields = [
            ...allFields,
            ...navigation.fieldsForRoutes(routeSet)
        ])

        const flattenedData:any = Common.util.object.flatten(data, allFields);
        const merged = merge(fields, flattenedData);
        const result:any = {};
        Object.keys(merged).forEach(fieldName => {
            if(includingFields.indexOf(fieldName) !== -1 || allFields.indexOf(fieldName) === -1){
                result[fieldName] = merged[fieldName];
            }
        })
        set('setFromQuoteData', state => result);
    }


    return {
        isOptionOther,
        setFromQuoteData,
        prepareData,
        state,
        any,
        value,
        setValue,
        valueFor,
        asDollar,
        asYesNo,
        asOption,
        setValues,
        asText,
        all: fields as Partial<F>,
    }

}
