import React from 'react';
import googleAnalytics from '../common/services/google-analytics';
import Common from './../common/Common'

type Props = {
    title?:React.ReactNode,
    label:string,
    message:React.ReactNode
}


export const HelpIconTable = ({title, message, label}:Props) => {
    const alert = Common.useAlert();

    if(message == "") return null;
    
    const showAlert = (e:{ stopPropagation:Function, preventDefault:Function }) => {
        
        e.stopPropagation();
        e.preventDefault();

        var ieEvent:any = e;
        if(ieEvent.returnValue) ieEvent.returnValue = false;
        if(ieEvent.stopImmediatePropagation) ieEvent.stopImmediatePropagation();
        ieEvent.cancelBubble = true;

        googleAnalytics.customInteraction(googleAnalytics.Actions.INFO_CLICK, label)
        
        alert.show({
            title,
            message,
        })

        return false;
    }

    return (
        <div className="help-icon help-icon-table" onClick={ showAlert }>
            <i className={`far fa-question-circle fa-2x`}/>
        </div>
    )
}

export default HelpIconTable