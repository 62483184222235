import React from 'react'
import Step from '../../components/Step'
import Input from '../../elements/Input'
import ButtonContainer from '../../elements/ButtonContainer'
import Button from '../../elements/Button'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'


const strataManagementCompanyName = fieldName('strataManagementCompanyName')
const strataMangerContactFullName = fieldName('strataManagerContact.fullName')
const strataMangerContactEmail = fieldName('strataManagerContact.email')
const strataMangerContactPhone = fieldName('strataManagerContact.phone')


export const rules = {
    [strataManagementCompanyName]: [ 
        enforce.required('Required'),
        enforce.notBlank('Required'),
    ],
    [strataMangerContactFullName]: [ 
        enforce.required('Required'),
        enforce.notBlank('Required'),
    ],
    [strataMangerContactPhone]: [
        enforce.required('Required'),
        enforce.notBlank('Required'),
        enforce.phoneNumber("Must be a valid phone number"),
    ],
    [strataMangerContactEmail]: [ 
        enforce.required('Required'),
        enforce.notBlank('Required'),
        enforce.email("Must be a valid email"),
    ]
}

export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();

    async function onContinue(){
        try{
            await validation.validate(rules);
            navigation.goToNext();
        }catch(e){}
    }

    return (
        <Step>
            <h2>Strata Management Company Contact</h2>
            <div className="row">
                <div className="col-12 col-md-6 mt-2">
                    <Input field={strataManagementCompanyName} type="text" label="Strata management company name"/>
                </div>
                <div className="col-12 col-md-6 mt-2 mt-md-4">
                    <Input field={strataMangerContactFullName} type="text" label="Strata management contact name(s)"/>
                </div>
                <div className="col-12 col-md-6 mt-2 mt-md-4">
                    <Input field={strataMangerContactPhone} type="tel" label="Strata management contact phone number"/>
                </div>
                <div className="col-12 col-md-6 mt-2 mt-md-4">
                    <Input field={strataMangerContactEmail} type="email" label="Strata manager contact email"/>
                </div>
            </div>
            <ButtonContainer>
                <Button label="Continue" type="primary" icon="fas fa-chevron-right"  callback={ onContinue }/>
            </ButtonContainer>
        </Step>
    )
};