import React, { useEffect, useState } from 'react'
import Common from '../common/Common'
import Services from '../common/services'
import Button from './Button'
import ButtonContainer from './ButtonContainer'
import { RouteSet } from '../types'
import ContactDetailsModal from './ContactDetailsModal'


type FinalPriceSaveEditProps = {
    loadingQuote:boolean,
    coverOptionsRef:React.MutableRefObject<any>,
    emailField:string,
    extraData:Record<string, any>,
}

const FinalPriceSectionSaveEdit = ({emailField, loadingQuote, coverOptionsRef, extraData}:FinalPriceSaveEditProps) => {
    const scroll = Common.useScroll();
    const fields = Common.useFields();
    const alert = Common.useAlert();
    const loading = Common.useLoading();
    const [ documents, loadingDocuments, fetchDocuments, fetchDocumentsCount ] = Common.useFetchQuoteAndSave({
        routeSets: [RouteSet.BEFORE_ESTIMATE, RouteSet.BEFORE_FINAL_PRICE],
        extraData,
        quoteType: 'FULL_QUOTE',
        onSuccess: ()=>{},
    });

    const [state, setState] = useState({
        showModal:false,
        requestCount:0,
    });


    function showModal(){
        setState({
            ...state,
            showModal: true,
        });
    }

    function cancelModal(){
        setState({
            ...state,
            showModal: false,
        });
    }

    function saveQuote(){
        setState({
            ...state,
            showModal:false,
            requestCount: state.requestCount+1,
        })
    }

    useEffect(() => {
        if(state.requestCount > 0){
            fetchDocuments('Quote');
        }
    }, [state.requestCount]);


    useEffect(()=> {
        if(loadingDocuments){
            loading.beginLoading();
        }else{
            loading.endLoading();
        }
    }, [loadingDocuments]);


    // Documents fetch handler
    useEffect(() => {
        if(documents){
            Services.googleAnalytics.quoteSavedAndEmailed(fields.valueFor('quoteNumber'))
            alert.show({
                message: (
                    <div>
                        <p>Thank you for saving your quotation. We have emailed you a link so that you may view your quotation.</p>
                        <p>If you don’t receive our email, please check your junk or spam folders.</p>
                    </div>
                )
            })
        }
    }, [ documents]);

    return (
        <div className="FinalPriceSectionSaveEdit">
            <ContactDetailsModal show={state.showModal} onRequest={saveQuote} onCancel={cancelModal}  />
            {/*
            <ButtonContainer>
                <Button label="Save and email quote" type="outline-secondary" icon='fas fa-envelope fa-lg' callback={showModal}/>
            </ButtonContainer>
            */}
       </div>
    )
}

export default FinalPriceSectionSaveEdit;
