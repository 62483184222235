import React, { useRef } from 'react'
import Step from '../../components/Step'
import DatePicker from '../../elements/DatePicker'
import ButtonContainer from '../../elements/ButtonContainer'
import Button from '../../elements/Button'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'


const startDate = fieldName('startDate');

export const rules = {
    [ startDate ]:[
        enforce.required("Required")
    ]
}

export default () => {
    const navigation = Resi.useNavigation();

    const cta = useRef(null);

    return (
        <Step>
            <h2>When do you want the cover to start?</h2>
            <DatePicker field={ startDate } endDate="+90d" cta={cta}/>
            <ButtonContainer>
                <Button ref={cta} label="Continue" type="primary" icon="fas fa-chevron-right" callback={ navigation.goToNext } />
            </ButtonContainer>
        </Step>
    )
}