import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const lakePondWaterway = fieldName('lakePondWaterway');

export const rules = {
    [lakePondWaterway]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Does the property contain a lake, pond, or waterway? <HelpIcon label={lakePondWaterway} message=""/></h2>
            <ButtonsYesNo field={lakePondWaterway} callback={ navigation.goToNext } />
        </Step>
    )
}