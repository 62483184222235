import React from 'react'
import Step from '../../../components/Step'


export default function(){
    return (
        <Step showBack={false}>
            <h2>Instalments fail</h2>
        </Step>
    )
}    