
import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import ProgressBar from '../components/ProgressBar';
import Contact from '../components/Contact';
import Legal from '../components/Legal';
import Alert from '../components/Alert';
import ShowLegalButton from '../components/ShowLegalButton';
import Resi from './Resi'
import { HashRouter } from 'react-router-dom'
import env from '../env'
import Common from '../common/Common';
import Step from '../components/Step';
import { Redirect, Route, Switch } from "react-router-dom";
import QueryString from 'query-string'
import SaveAndEmailFooter from '../components/SaveAndEmailFooter'
import SaveAndEmailHover from '../components/SaveAndEmailHover';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


export default () => (
    <div className={"app " + "app-brand-"+env.BRAND + " " + "app-form-"+env.FORM}  data-testid="app">
        <Resi.Container>
            <HashRouter>
                <RoutesWithEffects />
            </HashRouter>
        </Resi.Container>
    </div>
)


export const RoutesWithEffects = () => {
    Resi.useResiEffects();
    return (
        <>
            <Alert />
            <Header formName={ 'Residential Strata' } />
            <ProgressBar />
            <Routes />
            <ShowLegalButton />
            <SaveAndEmailFooter />
            <SaveAndEmailHover />
            <Contact />
            <Legal />
        </>
    )
}


const Routes = () => {
    const navigation = Common.useNavigation();
    const conditions = Common.useConditions();

    var queryString = QueryString.extract(window.location.href)
    if(queryString) queryString = "?" + queryString;

    if(!conditions.passes("/*")) return <Step showBack={false} preventRestore={true}></Step>;
    return (
        <Switch>
            { navigation.getRouteComponents(navigation.routesMap) }
            <Route exact path="/"><Redirect to={"/duty-of-disclosure"+queryString}/></Route>
        </Switch>
    )
}
