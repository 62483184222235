import React from 'react'
import Step from '../../components/Step'
import Button from '../../elements/Button'
import ButtonContainer from '../../elements/ButtonContainer'
import Input from '../../elements/Input'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const numberOfFloors = fieldName('numberOfFloors')
    
export const rules = {
    [numberOfFloors]: [
        enforce.required("Required"),
        enforce.greaterThan(0)("Please enter a number greater than 0"),
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();

    async function next(){
        try{
            await validation.validate(rules)
            navigation.goToNext();
        }catch(e){}
    }

    return (
        <Step>
            <h2>How many floors are there in your building?</h2>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input autoFocus field={numberOfFloors} type="number" caption="This includes the ground floor, but excludes any basements or floors below ground."/>
                </div>
            </div>
            
            <ButtonContainer>
                <Button label="Continue" icon="fas fa-chevron-right" type="primary" callback={ next } />
            </ButtonContainer>
        </Step>
    )
};
