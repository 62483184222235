import Request from './request'
import Common from '../Common';

export type PaymentInitiateResult = {
    paymentClientToken:string,
    paymentEnvironment:any,
}


export default async () => {
    const url = Common.config.services.paymentInitiate;

    let data:PaymentInitiateResult = await Request.post(url, {});
    return data;
}
