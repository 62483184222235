import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { RouteSet, RoutesMap } from '../../types'
import Common from '../Common'


export type Navigation = {
    routes: string[],
    routesMap: RoutesMap,
    progressRoutes: string[],
    completedRoute: string|null,
    formIsRestored: boolean,
    externalRedirect: string|null,
    previousRoute: string|null,
}


export const navigation:Navigation = {
    routes: [],
    routesMap: [],
    progressRoutes: [],
    completedRoute: null,
    previousRoute: null,
    formIsRestored: false,
    externalRedirect: null,
}



export function useNavigationEffects(){
    const store = Common.useStore()
    const state = store.get('navigation')
    const set = store.set('navigation')
    const navigation = Common.useNavigation()
    const conditions = Common.useConditions()
    const unloadHandler = Common.useUnloadHandler();

    const completedRoute = state.completedRoute;


    useEffect(() => {
        if(unloadHandler.shouldDisplayUnloadWarning === false && navigation.externalRedirect){
            window.location.href = navigation.externalRedirect;
        }
    }, [unloadHandler.shouldDisplayUnloadWarning, navigation.externalRedirect])

    
    useEffect(() => {
        if(completedRoute){
            let nextRoute:string|null = null;
            let pendingNext = false;
            const { routes } = state;

            for(var i = 0 ; i < routes.length; i++){
                var route = routes[i];
                if(pendingNext && conditions.passes(route) !== false){
                    nextRoute = route;
                    break;
                }
                if(route === completedRoute){
                    pendingNext = true;
                }
            }
            if(nextRoute){
                navigation.goTo(nextRoute);
                set('setCompletedRoutes', state => ({
                    completedRoute: null,
                    previousRoute: nextRoute,
                }))
            }
            else console.log("Cannot go to next route");
        }
    }, [completedRoute, conditions.all]);

    
}


export const useNavigation = <R extends RoutesMap>() => {
    const store = Common.useStore();
    const scroll = Common.useScroll();
    const conditions = Common.useConditions();
    const history = useHistory();
    const set = store.set('navigation')

    const navigation = store.get('navigation')

    
    function fieldsForRoutes(routeSet:RouteSet){
        const result = Common.util.routes.fieldNamesWhereRouteIs(navigation.routesMap, routeSet);
        return result;
    }


    function setExtenalRedirect(url:string){
        set('setExtenalRedirect', state => ({
            externalRedirect: url,
        }))
    }


    function setRestoredForm(){
        set('setRestoredForm', state => ({
            formIsRestored: true,
        }))
    }


    function validRoutes(){
        if(conditions.passes('/*') === false) return [];
        return navigation.routes.filter(route => conditions.passes(route));
    }


    function goToNext(){
        // HACK: The setTimeout is here to ensure that all the other side effects
        // have run first (mainly the conditions) before moving on, as the next route
        // is determined by the conditions.
        setTimeout(() => {
            set('goToNext', state => ({
                completedRoute: getCurrentRoute()
            }))
        }, 1);
    }


    function getCurrentRoute(){
        // We perform a bit of logic here to only check against the non-nested routes,
        // as the navigation module doesn't concern itself with nested routes. A pathname
        // like /specified-items/edit will be checked as /specified-items, in order 
        // allow nested routes in the app, that don't break our navigation module logic.
        return '/' + history.location.pathname.split('/')[1];
    }


    function anyRoute(){
        return '/*'
    }

    
    function currentRouteIsValid(){
        const currentRoute = getCurrentRoute();
        const isValid = validRoutes().indexOf(currentRoute) !== -1;
        return isValid;
    }


    function redirect(nextRoute:R[number][1]){
        history.replace(nextRoute);
        scroll.toTop(false);
    }


    function goTo(nextRoute:R[number][1]){
        history.replace(nextRoute);
        scroll.toTop(false);
    }


    function goBack(){
        var currentRoute = getCurrentRoute();
        var currentRouteIndex = navigation.routes.indexOf(currentRoute);
        for(var i=currentRouteIndex-1; i>=0; i--){
            var route = navigation.routes[i];
            if(conditions.passes(route) !== false){
                goTo(route);
                return;
            }
        }
        throw("Could not go back from: " + currentRoute);
    }


    function setupRoutes(routesMap:R){
        set('setupRoutes', state => ({
            routes: routesMap.map(route => route[1]),
            routesMap,
            progressRoutes: routesMap.filter(route => route[0]===RouteSet.BEFORE_ESTIMATE || route[0]===RouteSet.BEFORE_FINAL_PRICE).map(r => r[1])
        }));
    }


    function progress(){
        const navigation = store.get('navigation');
        const checkedRoute = history.location.pathname;
        if(checkedRoute === '/restore') return 0;
        if(navigation.progressRoutes.indexOf(checkedRoute) === -1) return 1;
        const currentRouteIndex = navigation.progressRoutes.indexOf(checkedRoute);
        return currentRouteIndex / (navigation.progressRoutes.length-1);
    }


    function getRouteComponents(routesMap:R):JSX.Element[]{
        return routesMap.map(route => {
            const path = route[1];
            const component = route[2].default;
            
            if(path === "/"){
                return <Route key={path} component={ component } />
            }else{
                return <Route path={path} key={path} component={ component } />
            }
        })
    }


    return {
        previousRoute: navigation.previousRoute,
        fieldsForRoutes,
        setExtenalRedirect,
        externalRedirect: navigation.externalRedirect,
        formIsRestored: navigation.formIsRestored,
        setRestoredForm,
        allRoutes: () => navigation.routes,
        redirect,
        validRoutes,
        currentRouteIsValid,
        getCurrentRoute,
        anyRoute,
        routesMap: navigation.routesMap,
        setupRoutes,
        getRouteComponents,
        goToNext,
        goTo,
        goBack,
        progress,
    }

}