import React from 'react'
import Common from '../common/Common';
import { options } from '../common/modules/OptionsModule';
import Grid from '../elements/Grid';
import Services from '../common/services'


type Option = {
    label: string,
    value: string|null|undefined|boolean|number,
    show?:boolean,
    route?:string,
    onEdit?: () => void,
}

    
type Props = {
    options: Option[],
    cols?:number,
}


function trim(value:string, maxChars:number){
    if(value.length > maxChars){
        value = value.slice(0, maxChars-3) + '...'
    }
    return value;
}

const createCoverOption = (onEdit:(option:Option)=>void) => (option:Option, index:number) => {
    return (
        <div className="cover-option" key={index} >
            <div className="cover-option-top">
                <div className="cover-option-title">{ option.label }</div>
                { (option.onEdit || option.route) && 
                <div className="cover-option-edit" onClick={() => onEdit(option) }>
                    <span className="cover-option-edit-icon"><i className="fas fa-pencil-alt" /></span>
                    <span className="cover-option-edit-label">Edit</span>
                </div>
                }
            </div>
            <div className="cover-option-value" data-transparent={option.value=="Not insured"}>{   (option.value===null || option.value == undefined)
                                                    ? <span style={{color:'#aaaaaa'}}>Null</span>
                                                    : typeof option.value === "boolean"
                                                                     ? (option.value?'Yes':'No')
                                                    : typeof option.value === "number"
                                                                     ? option.value
                                                                     : trim(option.value!, 26) }
            </div>
        </div>
    )  
}


const CoverOptions = ({options, cols}:Props) => {
    const navigation = Common.useNavigation();

    function onEditPressed(option:Option){
        if(option.route){
            Services.googleAnalytics.fieldEdit(option.route);
            navigation.goTo(option.route);
        }else if(option.onEdit){
            option.onEdit!()
        }
    }

    return (
        <div className="cover-options" data-disable-edit={false&&navigation.formIsRestored}>
            <Grid colsLg={cols || 2}
                colsSm={1}
                heightLg="50px"
                heightSm="50px"
                rowGapLg="12px"
                rowGapSm="6px"
                columnGapLg="22px"
                columnGapSm="32px"
            >
                { options.filter(option => option.show != false).map(createCoverOption(onEditPressed)) }
            </Grid>
        </div>
    )
}


export default CoverOptions;
