import React from 'react'
import Step from '../../../components/Step'
import Common from '../../../common/Common'
import PaymentButtons from '../../../elements/PaymentButtons'
import env from '../../../env'


const PaymentResult = () => {
    const payment = Common.usePayment();

    if(payment.result === null) throw(new Error("Payment result should not be null to show this page"))
    if(payment.result?.successful) throw(new Error("Payment result should not be successful to show this page"))

    return (
        <Step showBack={ false }>
            <h2>Payment wasn't successful</h2>
            <h4>We're sorry but your payment could not be completed due to: { payment.result && payment.result.reason }</h4>
            { env.BRAND == "flex"
            ? <p>If you could please try again by clicking the payment button. If you are still experiencing issues please contact <strong>Flex Insurance</strong> directly on <strong>1300 001 293</strong>.</p>
            : <p>If you could please try again by clicking the payment button. If you are still experiencing issues please contact <strong>CHU</strong> directly on <strong>1300 201 021</strong>.</p>
            }
            <PaymentButtons />
        </Step>
    )
}

export default PaymentResult;
