import Request from './request'
import Common from '../Common';

export type SmartAddressSuggestion = {
    deliveryPoint: string|null,
    streetNumber: string,
    streetName: string,
    suburb: string,
    postcode: string,
    state: string,
    gnafPid: string|null,
}

export type SmartAddressResult = SmartAddressSuggestion[]


export default async (postcode:string|undefined|null, streetAddress:string|undefined|null, hasDeliveryPoint:boolean, limit:number) => {
    const url = Common.config.services.smartAddress;

    // Allow for undefined parameters for convenience
    if(!postcode) return [];
    if(!streetAddress) return [];

    // Setup the request
    let params:any = {
        postcode,
        hasDeliveryPoint,
        streetAddress,
    }

    // Perform request
    let data:{
        flat_number: string|null,
        street_number: string,
        street: string,
        suburb: string,
        state: string,
        postcode: string,
        add_dt_pid: string,
    }[] = await Request.get(url, params);

    
    data = data.reduce((acc:typeof data, item, index) => index < limit ? [ ...acc, item ] : acc, [])

    const result:SmartAddressResult = data.map(item => ({
        deliveryPoint: item.flat_number,
        streetNumber: item.street_number,
        streetName: item.street,
        suburb: item.suburb,
        postcode: item.postcode,
        state: item.state,
        gnafPid: item.add_dt_pid,
    }));

    return result;
}
