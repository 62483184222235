import React, { useEffect, useState } from 'react'
import AutoSuggestion from 'react-autosuggest'
import Common from '../common/Common'
import smartAddress, { SmartAddressSuggestion } from '../common/services/smartAddress'

type Suggestion = SmartAddressSuggestion | 'NOT_FOUND';

type Props = {
    hasDeliveryPoint:boolean,
    notFound:boolean,
    onFocus: ()=>void,
    fieldNames:{
        postcode: string,
        deliveryPoint: string,
        streetNumber: string,
        streetName: string,
        suburb: string,
        state: string,
        gnafPid: string,
        address: string,
    },
}


const AddressAutoComplete = ({ onFocus, notFound, hasDeliveryPoint, fieldNames }:Props) => {
    const [ suggestions, setSuggestions ] = useState([] as Suggestion[])
    const fields = Common.useFields();
    const errors = Common.useErrors();

    const {
        postcode,
        deliveryPoint,
        streetNumber,
        streetName,
        suburb,
        state,
        gnafPid,
        address,
    } = fieldNames;

    useEffect(() => {
        const shouldSet = fields.value(streetNumber)
                       && fields.value(streetName)
                       && fields.value(suburb)
                       && fields.value(state)

        if(shouldSet){
            let addressValue = fields.value(streetNumber) +" "+ fields.value(streetName) +", "+ fields.value(suburb) +", "+ fields.value(state);
            if(fields.value(deliveryPoint)) addressValue = "U " + fields.value(deliveryPoint) + " " + addressValue;
            fields.setValue(address)(addressValue)
        }
    }, [deliveryPoint, streetNumber, streetName, suburb, state])

    async function fetchSuggestions(){
        var addressValue:string = fields.value(address);
        if(!addressValue) return;

        while(addressValue[0] == " ") addressValue = addressValue.slice(1);
        if(addressValue && addressValue.length < 2) clearSuggestions();

        const newSuggestions = await smartAddress(fields.value(postcode), addressValue, hasDeliveryPoint, 5)
        setSuggestions([
            ...newSuggestions,
            'NOT_FOUND'
        ])
    }

    function clearSuggestions(){
        setSuggestions([])
    }

    function getSuggestionValue(item:Suggestion){
        if(item === 'NOT_FOUND') return 'NOT_FOUND';
        return (item.deliveryPoint ? `U${item.deliveryPoint} ` : "")
              + item.streetNumber + " "
              + item.streetName + ", "
              + item.suburb + ", "
              + item.state
    }

    function renderSuggestion(item:Suggestion){
        if(item === "NOT_FOUND"){
            return <div className="address-suggestion address-cant-find">Can't find your address? Click here</div>
        }else{
            return <div className="address-suggestion">{ getSuggestionValue(item) }</div>
        }
    }

    function onSuggestionSelected(suggestion:Suggestion, suggestionValue:string){
        if(suggestion === "NOT_FOUND"){
            fields.setValues({
                [ deliveryPoint ]: undefined,
                [ streetNumber ]: undefined,
                [ streetName ]: undefined,
                [ suburb ]: undefined,
                [ state ]: undefined,
                [ gnafPid ]: undefined,
                [ address ]: suggestionValue,
            })
        }else{
            fields.setValues({
                [ deliveryPoint ]: suggestion.deliveryPoint,
                [ streetNumber ]: suggestion.streetNumber,
                [ streetName ]: suggestion.streetName,
                [ suburb ]: suggestion.suburb,
                [ state ]: suggestion.state,
                [ gnafPid ]: null,
                [ address ]: suggestionValue,
            })
        }
    }


    return (
        <AutoSuggestion
            suggestions={ suggestions }
            onSuggestionsFetchRequested={ fetchSuggestions }
            onSuggestionsClearRequested={ clearSuggestions }
            getSuggestionValue={ getSuggestionValue }
            renderSuggestion={ renderSuggestion }
            onSuggestionSelected={(e, {suggestion, suggestionValue}) => onSuggestionSelected(suggestion, suggestionValue)}
            inputProps={{
                value: notFound ? "" : (fields.value(address) || ""),
                id:"input-"+address,
                type:'text',
                autoComplete:"do-not-autofill", // Chrome hack
                onFocus: () => notFound && onFocus(),
                className:`form-control ${errors.hasErrors(address)?"is-invalid":""}`,
                onChange: (event:any) => {
                    fields.setValue(address)((event.target.value))
                },
            }}/>
    )
}


export default AddressAutoComplete;
