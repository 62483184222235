import React from 'react'
import Step from '../../components/Step'
import ButtonSelect from '../../elements/ButtonSelect'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'


const ownersCorporationType = fieldName('ownersCorporationType')

export const rules = {
    [ownersCorporationType]:[],
}



export default () => {
    const navigation = Resi.useNavigation();
    const fields = Resi.useFields();
    const options = Resi.useOptions();

    function onSelected(value:string){
        fields.setValue(ownersCorporationType)(value);
        navigation.goToNext();
    }

    return (
        <Step>
            <h2>What is your Owners Corporation type?</h2>
            <ButtonSelect 
                options={ options.data.product.OWNERS_CORP_TYPE }
                selected={fields.value(ownersCorporationType)}
                colsLg={ 3 }
                colsSm={ 1 }
                callback={ onSelected }
            />
        </Step>
    )
};