import React from 'react'
import ButtonMultiSelect from './ButtonMultiSelect'
import { Option } from '../types'


type Props<T> = {
    options:Option<T>[],
    selected: T|null,
    colsLg:number,
    colsSm:number,
    centered?: boolean,
    heightLg?: string,
    heightSm?: string,
    showHelp?: boolean,
    sort?:boolean,
    callback:(arg0:T) => void,
}


function ButtonSelect<T>(props:Props<T>){
    const propsToMulti = {
        ...props,
        hideIcon: true,
        selected: (props.selected !== null) ? [props.selected] : null,
    }
    return <ButtonMultiSelect {...propsToMulti} />
}


export default ButtonSelect;
