import { useEffect } from "react";
import Common from "../Common";
import Services from "../services";
import { RouteSet } from "../../types";


export function useFetchQuoteAndSave({routeSets, extraData, quoteType, onSuccess}:{routeSets:RouteSet[], extraData:Record<string, any>, quoteType:"QUICK_QUOTE"|"FULL_QUOTE"|"PAYMENT", onSuccess:()=>void}){
    const [ documents, loadingDocuments, fetchDocuments, fetchDocumentsCount ] = Common.useFetch(Services.documents);
    const [ fetchQuote, loadingQuote, fetchQuoteCount ] = Common.useFetchQuote({
        routeSets,
        extraData,
        quoteType,
        onSuccess,
    });

    useEffect(() => {
        if(loadingQuote==false && fetchQuoteCount>0){
            fetchDocuments('Quote');
        }
    }, [loadingQuote, fetchQuoteCount]);


    return [ documents, loadingQuote||loadingDocuments, fetchQuote, fetchDocumentsCount ];
}