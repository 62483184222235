import React from 'react';
import { prefix } from 'inline-style-prefixer';


type Props = {
    colsLg:number
    colsSm:number
    heightLg:string
    heightSm:string
    columnGapLg:string
    rowGapLg:string
    columnGapSm:string
    rowGapSm:string
    marginTop?:string
    marginBottom?:string
    children:React.ReactNode
}


const gridStyles = (numItems:number, cols:number, height:string, columnGap:string, rowGap:string) => {
    var rows = Math.ceil(numItems/cols);
    var rowsString = "";
    for(var i = 0; i < rows; i++){
        rowsString += height;
        if(i < rows-1) rowsString += " ";
    }

    var colString = "";
    for(var i = 0; i < cols; i++){
        colString += '1fr';
        if(i < cols-1) colString += " "
    }

    const result =  prefix({
        "gridTemplateColumns": colString,
        "gridTemplateRows": rowsString,
        "gridColumnGap": columnGap,
        "gridRowGap": rowGap,
        "gridGap": rowGap
    })
    return result;
}


const containerStyles = (marginTop:string|undefined, marginBottom:string|undefined) => ({
    marginTop: marginTop||0,
    marginBottom: marginBottom||0
})


const cellItem = (cols:number) =>  (child:React.ReactNode, index:number) => {
    const style = prefix({
        gridRow: Math.floor(index/cols) + 1,
        gridColumn: (index%cols) + 1,
        width: '100%',
        height: '100%',
    })
    return <div className="grid-cell" style={style}>{child}</div>
}


const Grid= ({ colsLg, colsSm, heightLg, heightSm, columnGapLg, rowGapLg, columnGapSm, rowGapSm, marginTop, marginBottom, children }:Props) => (
    <div className="grid" style={containerStyles(marginTop, marginBottom)}>
        <div className="grid-lg" style={ gridStyles(React.Children.count(children), colsLg, heightLg, columnGapLg, rowGapLg) } >
            { React.Children.map(children, cellItem(colsLg)) }
        </div>
        <div className="grid-sm" style={ gridStyles(React.Children.count(children), colsSm, heightSm, columnGapSm, rowGapSm) } >
            { React.Children.map(children, cellItem(colsSm)) }
        </div>
    </div>
)

export default Grid;
