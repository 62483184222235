import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const gymUsedByPublic = fieldName('gymUsedByPublic');

export const rules = {
    [gymUsedByPublic]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>If there is a gymnasium, is it used by the public? <HelpIcon label={gymUsedByPublic} message=""/></h2>
            <ButtonsYesNo field={gymUsedByPublic} callback={ navigation.goToNext } />
        </Step>
    )
}