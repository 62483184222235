import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const fullyOccupied = fieldName('fullyOccupied');

export const rules = {
    [fullyOccupied]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Is the building fully occupied? <HelpIcon label={fullyOccupied} message=""/></h2>
            <ButtonsYesNo field={fullyOccupied} callback={ navigation.goToNext } />
        </Step>
    )
}