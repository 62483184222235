import { RuleMap } from '../../../types'
import moment from 'moment'
import { get } from 'lodash'


const createCheckForRegex = 
(regex:RegExp) => 
    (value:any) => 
        regex.test(value)

function isNumeric(str:string){
    return /^\d+$/.test(str);
}

const rules ={
    optional: (value:any) => true,
    number: createCheckForRegex(/^\d+$/),
    numberDecimal: createCheckForRegex(/^\d*\.?\d*$/),
    year: createCheckForRegex(/^\d{4}$/),
    name: createCheckForRegex(/^[a-z ,.'-]+$/i),
    phoneNumber: createCheckForRegex(/^[\w+ \(\)-]+$/),
    email: createCheckForRegex(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    //ownersCorporationNumber: createCheckForRegex(/^([a-zA-Z0-9/]){1,11}$/),
    required: (value:any) => value === 0 || value === "0" || value === false || value === true || ( value != "" && value != undefined && value != null && typeof(value) != "undefined" && typeof(value) != null ),
    isTrueOrFalse: (value:any) => value === true || value === false,
    isTrue: (value:any) => value === true,
    date: (value:any) => moment( moment(value, "DD/MM/YYYY").format() ).isValid(),
    hasAtLeastOneEntry: (value:any) => value != null && value != undefined && value.length > 0,
    notBlank: (value:any) => value != 'BLANK',
    yearPast: (value:any) => value <= moment().year(),
    ownersCorporationNumber: (stateField:string) => (value:any, fields:any) => {
        if(!value) return false;
        if(typeof value != "string") return false; 
        var state = fields[stateField];
        if(state == "NT"){
            if(value.length < 3) return false;
            if(value.indexOf("/") > 4) return false;
            if(value.indexOf("/") < value.length-5) return false;
            for(var i = 0; i < value.length; i++){
                if('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ/'.indexOf(value[i].toUpperCase()) == -1) return false;
            }
        }else{
            if(value.length > 11) return false;
            if(value.length == 0) return false;
            if(!isNumeric(value[0])) return false;
            for(var i = 0; i < value.length; i++){
                if(i < value.length-2 && !isNumeric(value[i])) return false;
                else if(i > 8 && isNumeric(value[i])) return false;
                else if('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(value[i].toUpperCase()) == -1) return false;
                
                if('ABCDEFGHIJKLMNOPQRSTUVWXYZ'.indexOf(value[i].toUpperCase()) != -1){
                    if(isNumeric(value[value.length-1])) return false;
                }
            }
        }
        return true;
    },

    requiredIfCondition: (condition:(fields:any)=>Boolean) => (value:any, fields:any) => !condition(fields) && rules.required(value),
    greaterThanEqualField: (fieldName:string) => (value:any, fields?:any) => value >= fields[fieldName],
    greaterThan: (x:number) => (value:any) => value > x,
    greaterThanEqual: (x:number) => (value:any) => value >= x,
    lessThan: (x:number) => (value:any) => value < x,
    lessThanEqual: (x:number) => (value:any) => value <= x,
    rangeInclusive: (x:number, y:number) => (value:any) => value >= x && value <= y,
    predicate: (fn:(value:any)=>boolean) => (value:any) => fn(value),
    regex: (regex:RegExp) => createCheckForRegex(regex),
}


const create = (rule:(value:any, fields?:any)=>boolean) => (message:string) => (value:any, fields?:any) => rule(value, fields) ? null : message;
const createCompound = <R extends (...args:any)=>(value:any, fields?:any)=>boolean, P extends Parameters<R>>(rule:R) => (...args:P) => (message:string) => (value:any, fields?:any) => rule(...args as any)(value, fields) ? null : message;

const enforce = {
    optional                : create(rules.optional),
    number                  : create(rules.number),
    numberDecimal           : create(rules.numberDecimal),
    year                    : create(rules.year),
    yearPast                : create(rules.yearPast),
    name                    : create(rules.name),
    phoneNumber             : create(rules.phoneNumber),
    email                   : create(rules.email),
    required                : create(rules.required),
    isTrueOrFalse           : create(rules.isTrueOrFalse),
    isTrue                  : create(rules.isTrue),
    date                    : create(rules.date),
    hasAtLeastOneEntry      : create(rules.hasAtLeastOneEntry),
    notBlank                : create(rules.notBlank),

    ownersCorporationNumber : createCompound(rules.ownersCorporationNumber),
    requiredIfCondition     : createCompound(rules.requiredIfCondition),
    greaterThanEqualField   : createCompound(rules.greaterThanEqualField),
    greaterThan             : createCompound(rules.greaterThan),
    greaterThanEqual        : createCompound(rules.greaterThanEqual),
    lessThan                : createCompound(rules.lessThan),
    lessThanEqual           : createCompound(rules.lessThanEqual),
    rangeInclusive          : createCompound(rules.rangeInclusive),
    predicate               : createCompound(rules.predicate),
    regex                   : createCompound(rules.regex),
}


function getErrors<F extends Record<string,any>>(data:F, rulesMap:RuleMap<F>){
    const result = {} as Record<keyof Partial<F>, string[]>;

    const fieldNames = Object.keys(rulesMap) as (keyof F)[];
    fieldNames.forEach(fieldName => {
        const value = get(data, fieldName);
        const rules = rulesMap[fieldName];
        const messagesWithNulls = rules!.map(rule => rule(value, data))
        const messages = messagesWithNulls.filter(result => !!result) as string[]
        if(messages.length > 0) result[fieldName] = messages;
    })
    
    return Object.keys(result).length == 0 ? null : result;
}

export default {
    ...rules,
    enforce,
    getErrors,
}
