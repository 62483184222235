import Common from '../Common';
import env from '../../env/';
import { useEffect } from 'react';


export type UnloadHandler = {
    shouldDisplayUnloadWarning: boolean;
}


export const unloadHandler:UnloadHandler = {
    shouldDisplayUnloadWarning: false,
}


export function useUnloadHandlerEffects(){
    const store = Common.useStore();
    const state = store.get('unloadHandler');
    const set = store.set('unloadHandler');
    const navigation = store.get('navigation');

    useEffect(() => {
        if(navigation.externalRedirect){
            set('disbaleForExternalRedirect', state => ({
                shouldDisplayUnloadWarning: false,
            }))
        }
    }, [navigation.externalRedirect]);

    
    useEffect(() => {
        function handleUnload(e:any){
            if(state.shouldDisplayUnloadWarning){
                var confirmationMessage = "Changes that you made may not be saved.";
                (e || window.event).returnValue = confirmationMessage; //Gecko + IE
                return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
            }
        }

        if(state.shouldDisplayUnloadWarning) {
            if(env.DEBUG_SUPRESS_UNLOAD_WARNING){
                console.log("DEBUG: Should be listening for unload")
                return;
            }
            window.addEventListener('beforeunload', handleUnload);
        }else{
            if(env.DEBUG_SUPRESS_UNLOAD_WARNING){
                console.log("DEBUG: Should have stopped listening for unload")
                return;
            }
            window.removeEventListener('beforeunload', handleUnload)
        }

        return () => window.removeEventListener('beforeunload', handleUnload)
        
    }, [state])
    
}


export const useUnloadHandler = () => {
    const store = Common.useStore();
    const state = store.get('unloadHandler');
    const set = store.set('unloadHandler');

    function displayUnloadWarning(){
        if(state.shouldDisplayUnloadWarning) return;
        set('displayUnloadWarning', state => ({
            shouldDisplayUnloadWarning: true
        }))
    }

    function dontDisplayUnloadWarning(){
        if(state.shouldDisplayUnloadWarning === false) return;
        set('dontDisplayUnloadWarning', state => ({
            shouldDisplayUnloadWarning: false
        }))
    }

    
    return {
        shouldDisplayUnloadWarning: state.shouldDisplayUnloadWarning,
        displayUnloadWarning,
        dontDisplayUnloadWarning,
    }

}
