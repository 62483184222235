export enum RouteSet {
    TEST = 'TEST',
    MISC = 'MISC',
    END = 'END',
    PAYMENT = 'PAYMENT',
    BEFORE_ESTIMATE = 'BEFORE_ESTIMATE',
    BEFORE_FINAL_PRICE = 'BEFORE_FINAL_PRICE',
    BEFORE_PAYMENT = 'BEFORE_PAYMENT',
    REFERRED = 'REFERRED',
}


export type Rule = (value:any, fields?:any)=>string|null;
export type RuleMap<F extends {}> = {[K in keyof F]:Rule[]};
export type RouteDefinition = Readonly<[RouteSet, string, any]>;
export type RoutesMap = Readonly<RouteDefinition[]>;


export type Option<T> = {
    type: string,
    value:T,
    description:string,
    default?:T,
    help?: string,
    priority?: number,
}
