import calculateInstalments from "./calculateInstalments";
import quote from "./quote";


export default async function(fields:any){
    const quoteResult = await quote("FULL_QUOTE")(fields);
    const instalmentsResult = await calculateInstalments();

    return {
        ...quoteResult,
        instalments: instalmentsResult,
    }
}