import Request from '../../common/services/request'
import Common from '../../common/Common'
import { Option } from '../../types'
import { setOptionHelp, setOptionPriority } from '../../common/util/option'


type OptionResponse = {
    type: string,
    code: string,
    description: string,
}

type ResponseOptionResponse = Record<string, OptionResponse>

type OptionResult = Option<string>[];

type ResiOptionsResult = {
    OWNERS_CORP_TYPE: OptionResult,
    INSURER: OptionResult,
    OPTIONAL_IMPOSED_EXCESS: OptionResult,
    WALL_CONSTRUCTION: OptionResult,
    MB_AIRCON: OptionResult,
    OCCUPANCY: OptionResult,
    ROOF_CONSTRUCTION: OptionResult,
    GROUND_FLOOR: OptionResult,
    SPRINKLERS: OptionResult,
    LOSS_OF_RENT_PERCENT: OptionResult,
    CATASTROPHE_PERCENT: OptionResult,
    FLOOR_CONSTRUCTION: OptionResult,
} 

export default async () => {
    const url = Common.config.services.options;

    let data:{
        productOptions:{
            OWNERS_CORP_TYPE: ResponseOptionResponse,
            INSURER: ResponseOptionResponse,
            OPTIONAL_IMPOSED_EXCESS: ResponseOptionResponse,
            WALL_CONSTRUCTION: ResponseOptionResponse,
            MB_AIRCON: ResponseOptionResponse,
            OCCUPANCY: ResponseOptionResponse,
            ROOF_CONSTRUCTION: ResponseOptionResponse,
            GROUND_FLOOR: ResponseOptionResponse,
            SPRINKLERS: ResponseOptionResponse,
            LOSS_OF_RENT_PERCENT: ResponseOptionResponse,
            CATASTROPHE_PERCENT: ResponseOptionResponse,
            FLOOR_CONSTRUCTION: ResponseOptionResponse,
        } & Record<string, Record<string, OptionResponse>>, // Help text
    } = await Request.get(url);
    
   
    const toFormOption = (allOptions:Record<string, ResponseOptionResponse>, responseOption:ResponseOptionResponse) => {
        return Object.keys(responseOption).map((key) => {
            const option = responseOption[key];
            const helpEntry = allOptions[option.type + "_HELP_TEXT"];
            const optionHelp = helpEntry ? helpEntry[option.code] : null;
            const help = optionHelp ? optionHelp.description : undefined;

            return {
                type: option.type,
                value: option.code,
                description: option.description.split("/").join('/<wbr>'),
                help,
            }
        })
    }

    const result:ResiOptionsResult = {
        OWNERS_CORP_TYPE: toFormOption(data.productOptions, data.productOptions.OWNERS_CORP_TYPE),
        INSURER: toFormOption(data.productOptions, data.productOptions.INSURER).filter(option => option.description.toLowerCase().indexOf("(redundant)") === -1),
        OPTIONAL_IMPOSED_EXCESS: toFormOption(data.productOptions, data.productOptions.OPTIONAL_IMPOSED_EXCESS),
        WALL_CONSTRUCTION: toFormOption(data.productOptions, data.productOptions.WALL_CONSTRUCTION),
        MB_AIRCON: toFormOption(data.productOptions, data.productOptions.MB_AIRCON),
        OCCUPANCY: toFormOption(data.productOptions, data.productOptions.OCCUPANCY),
        ROOF_CONSTRUCTION: toFormOption(data.productOptions, data.productOptions.ROOF_CONSTRUCTION),
        GROUND_FLOOR: toFormOption(data.productOptions, data.productOptions.OCCUPANCY),
        SPRINKLERS: toFormOption(data.productOptions, data.productOptions.SPRINKLERS),
        LOSS_OF_RENT_PERCENT: toFormOption(data.productOptions, data.productOptions.LOSS_OF_RENT_PERCENT),
        CATASTROPHE_PERCENT: toFormOption(data.productOptions, data.productOptions.CATASTROPHE_PERCENT),
        FLOOR_CONSTRUCTION: toFormOption(data.productOptions, data.productOptions.FLOOR_CONSTRUCTION),
    }

        
    setOptionPriority(result.GROUND_FLOOR, 'GARAGES', 1);
    setOptionPriority(result.GROUND_FLOOR, 'RESIDENTIAL', 2);
    setOptionPriority(result.GROUND_FLOOR, 'COMMERCIAL', 3);
    setOptionHelp(result.GROUND_FLOOR, 'COMMERCIAL', 'Property used for business activities eg. retail shops, office spaces etc');

    setOptionPriority(result.INSURER, 'NEW_STRATA_PLAN', -3);
    setOptionPriority(result.INSURER, 'NOT_CURRENTLY_INSURED', -2);
    setOptionPriority(result.INSURER, 'NOT_KNOWN', -1);
    setOptionPriority(result.INSURER, "OTHER", 999);

    setOptionPriority(result.WALL_CONSTRUCTION, 'DOUBLE_BRICK', -4);
    setOptionPriority(result.WALL_CONSTRUCTION, 'BRICKVENEER_CEMENT', -3);
    setOptionPriority(result.WALL_CONSTRUCTION, 'CLADDING_FIBRO', 1);
    setOptionPriority(result.WALL_CONSTRUCTION, 'CLADDING_TIMBER', 2);

    setOptionPriority(result.ROOF_CONSTRUCTION, 'CEMENT', -3)
    setOptionPriority(result.ROOF_CONSTRUCTION, 'TILES_SHINGLE', -2)
    setOptionPriority(result.ROOF_CONSTRUCTION, 'TILES_TERRACOTTA', -1)

    return {
        ...result,
        INSURER: {
            NEW: result.INSURER.slice(0, 3),
            INSURERS: result.INSURER.slice(3),
        }
    };
}