import Request from './request'
import Common from '../Common'
import { Option } from '../../types'

type PaymentOption = 'BRAINTREE'|'OFFLINE'|'INHOUSE_INSTALMENTS'|'MPF'

export type PaymentOptionsResponse = {
    paymentOptions: PaymentOption[]
    mpfPremiumMin: number,
    mpfPremiumMax: number,
}

export type PaymentOptionsResult = {
    mpfPremiumMin: number,
    mpfPremiumMax: number,
    paymentOptions: Option<PaymentOption>[]
}


export default async () => {
    const url = Common.config.services.paymentOptions;

    let data:PaymentOptionsResponse = await Request.get(url);
    const result:PaymentOptionsResult = {
        mpfPremiumMin: data.mpfPremiumMin,
        mpfPremiumMax: data.mpfPremiumMax,
        paymentOptions: data.paymentOptions
            .filter(option => option != 'MPF') // Remove MPF from payment options
            .map(option => {
                switch(option){
                    case 'BRAINTREE': return {
                        type: 'PAYMENT_OPTION' as const,
                        value: 'BRAINTREE' as PaymentOption,
                        description: 'Pay by Visa or Mastercard'
                    };
                    case 'OFFLINE': return {
                        type: 'PAYMENT_OPTION' as const,
                        value: 'OFFLINE' as PaymentOption,
                        description: 'Pay offline (EFT or BPay)'
                    }
                    case 'INHOUSE_INSTALMENTS': return {
                        type: 'PAYMENT_OPTION' as const,
                        value: 'INHOUSE_INSTALMENTS' as PaymentOption,
                        description: 'Pay by instalments',
                    }
                    default: throw("UNSUPPORTED PAYMENT OPTION: " + option)
                }
            }
        )
    }

    // Disable instalments
    //result.paymentOptions = result.paymentOptions.filter(option => option.value != 'MPF');

    return result;
}
