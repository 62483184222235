import { useEffect, useState } from "react";
import Common from '../Common';


export function useFetch<A extends any[], T>( service:((...args:A)=>Promise<T>), throwOnError:boolean = true) {
    const initialState = {
        data: null as (T|null),
        loading: false,
        count: 0,
        errors: null as null|Record<string, string>,
    }
    const [state, setState] = useState(initialState)
    const alert = Common.useAlert();
    const requestErrors = Common.useRequestErrors();


    useEffect(() => {
        if(state.errors){
            requestErrors.set(state.errors);
        }
    }, [state.errors]);


    useEffect(() => {
        if(state.data){
            requestErrors.clear();
        }
    }, [state.data]);

    
    async function load(serviceArgs:A){
        try{
            const response = await service.apply(null, serviceArgs);
            
            if((response as any).errors){
                setState({
                    ...state,
                    errors: (response as any).errors,
                    loading: false,
                });
            }else{
                setState(state => ({
                    ...state,
                    data: response,
                    loading: false,
                    count: state.count + 1,
                }))
            }
        }catch(e:any){
            setState({
                ...state,
                loading: false,
                //error: !throwOnError ? e : null
            })
            if(throwOnError) alert.showError(e as Error);
        }
    }

    function fetch(...serviceArgs:A) {  
        setState({
            ...state,
            loading: true,
        })
        load(serviceArgs);
    }
    

    return [state.data, state.loading, fetch, state.count, state.errors] as const;
}
