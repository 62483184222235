import React from 'react'
import Step from '../../components/Step'
import Button from '../../elements/Button'
import ButtonContainer from '../../elements/ButtonContainer'
import Input from '../../elements/Input'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'

const buildingName = fieldName('buildingName', 0)
    
export const rules = {
    [buildingName]: [
        enforce.name("Please enter a name")
    ]
}

export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();

    async function next(){
        try{
            await validation.validate(rules)
            navigation.goToNext();
        }catch(e){}
    }

    return (
        <Step>
            <h2>Building Name</h2>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input field={buildingName} type="text" placeholder="Optional"/>
                </div>
            </div>
            
            <ButtonContainer>
                <Button label="Continue" icon="fas fa-chevron-right" type="primary" callback={ next } />
            </ButtonContainer>
        </Step>
    )
};
