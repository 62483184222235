import React from 'react'
import googleAnalytics from '../common/services/google-analytics'
import env from '../env'


export default () => {
    function onEmailClick(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.BUTTON_CLICK, "footer email")
    }

    function onPhoneClick(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.BUTTON_CLICK, "footer phone")
    }

    function onLinkedInClick(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.BUTTON_CLICK, "footer LinkedIn")
    }

    return (
        <div className="contact">
            <div className="footer-phone">
                <div>Get in touch:</div>
                <div>
                    <a onClick={onLinkedInClick} href={ env.CONTACT_LINKED_IN } target="_blank">
                        <span className="contact-icon contact-linkin">
                            <i className="fab fa-linkedin fa-lg"/>
                        </span>
                    </a>
                    <a onClick={onEmailClick} href={"mailto:"+env.CONTACT_EMAIL}>
                        <span className="contact-icon contact-email">
                            <i className="fas fa-envelope-square fa-lg" />
                        </span>
                    </a>
                    <a onClick={onPhoneClick} href={ 'tel:'+env.CONTACT_PHONE }>
                        <span className="contact-icon contact-phone">
                            <i className="fas fa-phone-square-alt fa-lg" />
                        </span>
                        <b dangerouslySetInnerHTML={{__html:env.CONTACT_PHONE_READABLE}} ></b>
                    </a>
                </div>
            </div>
            {/*
            <a className="footer-linkedin" href={ env.CONTACT_LINKED_IN } target="_blank">
                Follow us <i className="fab fa-linkedin-in fa-2x"></i>
            </a>
            */}
        </div>
    )
}