import React from 'react'
import Section from './Section'
import ChuAwards from './chu/ChuAwards'
import env from '../env'


const FinalPriceSectionAwards = () => {
    return env.BRAND === 'chu'
        ? ( <Section heading="Why Choose CHU?">
                <ChuAwards.ChuAwardsText />  
            </Section> )
        : null;
}


export default FinalPriceSectionAwards;
