import React from 'react';
import Quote from '../elements/Quote';
import Common from '../common/Common';
import googleAnalytics from '../common/services/google-analytics';


type Props = {
    title?: React.ReactNode,
    showBack?: boolean,
    containerWidth?: string,
}


const TitleComponent = ({containerWidth, title, showBack}:Props) => {
    const navigation = Common.useNavigation();
    const fields = Common.useFields();

    const quoteNumberValue = fields.value('quoteNumber')

    const style = !containerWidth ? {} :{
        maxWidth: containerWidth
    };

    function onBackClick(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.BUTTON_CLICK, "back", navigation.getCurrentRoute())
        navigation.goBack()
    }

    return (
        <div className="title-container">
            <div className="container" style={style}>
                <div className="row">
                    <div className="col-12 inner-container">
                        { showBack &&
                        <a onClick={ onBackClick } className="icon-container back-button">
                            <span className="icon"><i className="fas fa-chevron-left"></i></span>Back
                        </a>
                        }
                        { title ? <div className="line">|</div> : null }
                        <div className="title-text-container">
                            <div>{ title }</div>
                        </div>
                        { quoteNumberValue &&
                        <Quote textAlign="right" quoteNumber={ quoteNumberValue }/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TitleComponent;