import React, { useEffect } from 'react'
import Button from '../elements/Button';
import ButtonContainer from '../elements/ButtonContainer';
import ContactDetailsModal from '../elements/ContactDetailsModal';
import Common from '../common/Common';
import { useLocation } from 'react-router-dom';
import { RouteSet } from '../types';
import { getExtrasData } from '../form-resi/ExtrasResi';
import googleAnalytics from '../common/services/google-analytics';


const SaveAndEmailButton = () => {  
    const alert = Common.useAlert();
    const fields = Common.useFields();
    const loading = Common.useLoading();
    const [ documents, loadingDocuments, fetchDocuments, fetchDocumentsCount ] = Common.useFetchQuoteAndSave({
        routeSets: [RouteSet.BEFORE_ESTIMATE, RouteSet.BEFORE_FINAL_PRICE],
        extraData:getExtrasData(fields.all as any),
        quoteType: 'FULL_QUOTE',
        onSuccess: ()=>{},
    });
    
    let [state, setState] = React.useState({
        showModal: false,
        visible:false,
        requestCount:0,
    })

    function showModal(){
        setState({
            ...state,
            showModal: true,
        });
    }

    function cancelModal(){
        setState({
            ...state,
            showModal: false,
        });
    }


    function saveQuote(){
        setState({
            ...state,
            showModal:false,
            requestCount: state.requestCount+1,
        })
    }

    useEffect(() => {
        if(state.requestCount == 0){
            setState({
                ...state,
                visible:true,
            })
        }else{
            setState({
                ...state,
                visible:false,
            })
        }
    }, [state.requestCount])

    
    useEffect(() => {
        if(state.requestCount > 0){
            fetchDocuments('Quote');
        }
    }, [state.requestCount]);


    useEffect(()=> {
        if(loadingDocuments){
            loading.beginLoading();
        }else{
            loading.endLoading();
        }
    }, [loadingDocuments]);


    // Documents fetch handler
    useEffect(() => {
        if(documents){
            googleAnalytics.quoteSavedAndEmailed(fields.valueFor('quoteNumber'))
            alert.show({
                message: (
                    <div>
                        <p>Thank you for saving your quotation. We have emailed you a link so that you may view your quotation.</p>
                        <p>If you don't receive our email, please check your junk or spam folders.</p>
                    </div>
                )
            })
        }
    }, [ documents]);




    if(!state.visible) return null
    return (
        <>
            <ContactDetailsModal show={state.showModal} onRequest={saveQuote} onCancel={cancelModal}  />
            <Button label="Save and email quote" type="outline-secondary" icon='fas fa-envelope fa-lg' callback={showModal}/>
        </>
    )
}


export default SaveAndEmailButton

