import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'

const propertyInsuranceDeclined = fieldName('propertyInsuranceDeclined', 0);

export const rules = {
    [propertyInsuranceDeclined]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Has insurance on this property been declined in the past three (3) years? </h2>
            <ButtonsYesNo field={propertyInsuranceDeclined} callback={ navigation.goToNext } />
        </Step>
    )
}