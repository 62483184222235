import React from 'react'
import Common from '../common/Common'
import { Option } from '../types'
import ButtonSelect from './ButtonSelect'


type Props = {
    field: State|string,
    callback: () => void
}

type State = [boolean, (value:boolean)=>void];


function ButtonsYesNo({ field, callback }:Props){
    const fields = Common.useFields();
    const [value, setValue] = fields.state(field);
    const errorsModule = Common.useErrors();

    const errors = typeof field === 'string' ? errorsModule.all[field] : null;
    const hasErrors = errors && errors.length>0;
    

    const options:Option<boolean>[] = [
        {
            type: "YES_NO",
            value: true,
            description:"Yes"
        },{
            type: "YES_NO",
            value: false,
            description:"No"
        },
    ]

    function onSet(value:boolean){
        setValue(value);
        callback();
    }
    
    return (
        <div data-invalid={hasErrors}>
            <ButtonSelect
                options={ options }
                selected={ value }
                colsLg={ 3 }
                colsSm={ 1 }
                callback={ onSet }
                sort={false}
            />
            { hasErrors && 
            <label className="invalid-feedback">{errors![0]}</label>
            }
        </div>
    )
  
}

export default ButtonsYesNo;
