import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const jettyWharfPontoonMarina = fieldName('jettyWharfPontoonMarina');

export const rules = {
    [jettyWharfPontoonMarina]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Does the property have a jetty, wharf, pontoon, or marina?  <HelpIcon label={jettyWharfPontoonMarina} message=""/></h2>
            <ButtonsYesNo field={jettyWharfPontoonMarina} callback={ navigation.goToNext } />
        </Step>
    )
}