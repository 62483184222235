import Request from './request';
import Common from '../Common';


export default async (token:string) => {
    const url = Common.config.services.details

    let data:any = await Request.get(url, {
        token
    });
    
    return data;
}
