import Common from '../common/Common'
import { Fields } from './FieldsResi'
import { routes } from './RoutesResi'
import { useResiEffects } from './EffectsResi'
import { ResiOptionsResult } from './services/resiOptions'
import env from '../env'


let Resi = {
    ...Common,
    routes,
    useFields: () => Common.useFields<Fields>(),
    useNavigation: () => Common.useNavigation<typeof routes>(),
    useOptions: () => Common.useOptions<ResiOptionsResult>(),
    useResiEffects,
    config: Common.setConfig({
        services: {
            paymentOptions   : env.ENDPOINT_HOST + '/webforms/isaverresi/paymentOptions/v1',
            paymentAuthorise : env.ENDPOINT_HOST + '/webforms/isaverresi/authorise/v2',
            paymentInitiate  : env.ENDPOINT_HOST + '/webforms/isaverresi/initiate/v2',
            documents        : env.ENDPOINT_HOST + '/webforms/isaverresi/documents/v2',
            details          : env.ENDPOINT_HOST + '/webforms/isaverresi/details/v2',
            postcode         : env.ENDPOINT_HOST + '/webforms/common/postcode/v1',
            quote            : env.ENDPOINT_HOST + '/webforms/isaverresi/flatquote/v2',
            findToken        : env.ENDPOINT_HOST + '/webforms/isaverresi/retrieve/v1',
            excesses         : env.ENDPOINT_HOST + '/webforms/isaverresi/excesses/v2',
            options          : env.ENDPOINT_HOST + '/webforms/isaverresi/options/v2',
            sumsInsured      : env.ENDPOINT_HOST + '/webforms/isaverresi/sumsinsured/v2',
            limits           : env.ENDPOINT_HOST + `/webforms/isaverresi/limits/v2`,
            sumsinsured      : env.ENDPOINT_HOST + `/webforms/isaverresi/sumsinsured/v2`,
            validateAddress  : env.ENDPOINT_HOST + `/webforms/isaverresi/validateaddress/v2`,
            smartAddress     : env.ENDPOINT_COMMON_HOST + '/webforms/common/smartaddress/v1',
            instalments      : env.ENDPOINT_INSTALMENTS,
            calculateInstalments : env.ENDPOINT_HOST + "/webforms/common/calculateinstalments",
        }
    }),
}

if (env.ENVIRONMENT === "local") {
    Resi = {
        ...Common,
        routes,
        useFields: () => Common.useFields<Fields>(),
        useNavigation: () => Common.useNavigation<typeof routes>(),
        useOptions: () => Common.useOptions<ResiOptionsResult>(),
        useResiEffects,
        config: Common.setConfig({
            services: {
                paymentOptions   : env.ENDPOINT_HOST + '/api/underwriting/isaver/residential/paymentOptions/v1',
                paymentAuthorise : env.ENDPOINT_HOST + '/api/underwriting/isaver/residential/authorise/v2',
                paymentInitiate  : env.ENDPOINT_HOST + '/api/underwriting/isaver/residential/initiate/v2',
                documents        : env.ENDPOINT_HOST + '/api/underwriting/isaver/residential/documents/v2',
                details          : env.ENDPOINT_HOST + '/api/underwriting/isaver/residential/details/v2',
                postcode         : env.ENDPOINT_HOST + '/api/common/postcode/physical',
                quote            : env.ENDPOINT_HOST + '/api/underwriting/isaver/residential/flatquote/v2',
                findToken        : env.ENDPOINT_HOST + '/api/underwriting/isaver/residential/retrieve/v1',
                excesses         : env.ENDPOINT_HOST + '/api/underwriting/isaver/residential/excesses/v2',
                options          : env.ENDPOINT_HOST + '/api/underwriting/isaver/residential/options/v2',
                sumsInsured      : env.ENDPOINT_HOST + '/api/underwriting/isaver/residential/sumsinsured/v2',
                limits           : env.ENDPOINT_HOST + `/api/underwriting/isaver/residential/limits/v2`,
                sumsinsured      : env.ENDPOINT_HOST + `/api/underwriting/isaver/residential/sumsinsured/v2`,
                validateAddress  : env.ENDPOINT_HOST + `/api/underwriting/isaver/residential/validateaddress/v2`,
                smartAddress     : env.ENDPOINT_COMMON_HOST + '/api/common/streetAddressAutoComplete',
                instalments      : env.ENDPOINT_INSTALMENTS,
                calculateInstalments : env.ENDPOINT_HOST + "/api/common/instalments/calculateInstalments",
            }
        }),
    }
}

export default Resi;
