import React from 'react';
import Grid from './Grid';
import FillButton from './FillButton';
import { Option } from '../types'

type Props<T> = {
    options:Option<T>[],
    selected: T[]|null,
    colsLg:number,
    colsSm:number,
    hideIcon?:boolean,
    centered?: boolean,
    heightLg?: string,
    heightSm?: string,
    sort?:boolean,
    showHelp?:boolean,
    callback:(selected:T) => void
}


function getIcon<T>(isSelected:boolean, hideIcon:boolean, iconChecked:string, iconUnchecked:string){
    if(hideIcon) return undefined;
    return isSelected
        ? iconChecked
        : iconUnchecked;
}


function makeButton<T>(selected:T[]|null, centered:boolean, hideIcon:boolean, callback:(arg0:T)=>void, showHelp:boolean=true){
    return function(option:Option<T>, index:number){
        const isSelected = selected !== null && selected.indexOf(option.value) !== -1;
        const buttonClass = isSelected ? "btn-success" : "btn-outline-secondary";

        return <FillButton label={option.description}
                           key={ index }
                           //icon={getIcon(isSelected, hideIcon, "fas fa-check-square", "far fa-square") }
                           buttonClass={buttonClass}
                           help={ showHelp ? option.help : undefined }
                           centered={ centered }
                           callback={()=>callback(option.value)} />
    }
}


function ButtonMultiSelect<T>({ options, showHelp, colsLg, colsSm, selected, hideIcon, centered, callback, heightLg="100px", heightSm="60px" }:Props<T>){
    return (
        <div className="button-multi-select">
            <Grid
                colsLg={ colsLg }
                colsSm={ colsSm }
                heightLg={ heightLg }
                heightSm={ heightSm }
                columnGapLg="12px"
                rowGapLg="12px"
                columnGapSm="4px"
                rowGapSm="4px"
            >
                { options.map(makeButton(selected, !!centered, !!hideIcon, callback, showHelp)) }
            </Grid>
        </div>
    )
}

export default ButtonMultiSelect;
