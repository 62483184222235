import Common from '../Common'
import { RuleMap } from '../../types'


export const useValidation = () => {
    const errors = Common.useErrors();
    const fields = Common.useFields();
    const conditions = Common.useConditions();
    const rules = Common.util.validation.rules;

    async function validate(rulesMap:RuleMap<any>){
        const toCheck:RuleMap<any> = {};
        const fieldsNames = Object.keys(rulesMap);
        const filteredNames = fieldsNames.filter(fieldName => conditions.passes(fieldName) !== false);
        filteredNames.forEach(fieldName => toCheck[fieldName] = rulesMap[fieldName]);

        return new Promise((resolve, reject) => {
            const formErrors = rules.getErrors(fields.all, toCheck);
            errors.setErrors(formErrors)
            if(formErrors === null) resolve(null);
            else reject("Validation errors")
        })
    }

    return {
        validate,
        enforce: rules.enforce,
    }

}

