import { useEffect } from "react";
import Common from "../Common";
import Services from "../services";
import {RouteSet} from '../../types'
import googleAnalytics from "../services/google-analytics";


export function useFetchQuoteEstimate(onSuccess:()=>void){
    const [ quote, loadingQuote, fetchQuote, fetchQuoteCount ] = Common.useFetch(Services.quote('QUICK_QUOTE'));
    const fields = Common.useFields();
    const navigation = Common.useNavigation();

    useEffect(()=> {
        fetchQuote(fields.prepareData([RouteSet.BEFORE_ESTIMATE]));
    }, [])

    useEffect(() => {
        if(quote){
            Services.googleAnalytics.estimateQuoteCreated( quote.quoteNumber);
            
            fields.setFromQuoteData(quote, [RouteSet.BEFORE_ESTIMATE]);
            if(quote.declined){
                googleAnalytics.declined();
                navigation.goTo('/declined');
            }else if(quote.referred){
                googleAnalytics.referred();
                navigation.goTo('/referred');
            }else{
                onSuccess();
            }
        }
    }, [quote])

    return [loadingQuote];
};
