import React, { ReactNode } from 'react'

type Props = {
    children:ReactNode,
}

const Sections = ({ children }:Props) => (
    <div className="sections">
        { children }
    </div>
)

export default Sections;