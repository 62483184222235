import React, { useEffect } from 'react'
import Step from '../../../components/Step'
import QueryString from 'query-string'
import Services from '../../../common/services'
import Common from '../../../common/Common'
import {enforce} from '../../../common/HelpersCommon'
import Input from '../../../elements/Input'
import Button from '../../../elements/Button'
import LoadingIndicator from '../../../elements/LoadingIndicator'
import { RouteSet } from '../../../types'
import { Fields } from '../../FieldsResi'


const RestoreByQuoteNumber = () => {
    const fields = Common.useFields();
    const navigation = Common.useNavigation();
    const validation = Common.useValidation();
    const alert = Common.useAlert();
    const [tokenData, tokenLoading, tokenFetch, tokenCount, tokenError] = Common.useFetch(Services.findToken, false);
    const restored = useRestore(tokenData?.token);

    const quoteNumber = "__quote_number__";
    const email = "__email__";

    useEffect(() => {
        if(tokenError){
            alert.show({
                title: "Error",
                message: "Please ensure your quote number and email are correct.",
            })
        }
    }, [tokenError])

    useEffect(() => {
        if(restored) navigation.goTo('final-price');
    }, [restored]);

    
    async function submit(){
        try{
            await validation.validate({
                [ quoteNumber ]: [
                    enforce.required('Required')
                ],
                [ email ]: [
                    enforce.required('Required'),
                    enforce.email('Must be a valid email'),
                ]
            })

            tokenFetch(fields.value(quoteNumber), fields.value(email));
        }catch(e){}
    }

    return (
        <Step showBack={false}>
            <h2>Retrieve quote</h2>
            <LoadingIndicator loading={ tokenLoading }>
                <Input field={ quoteNumber } type="text" label="Your quote number"/>
                <Input field={ email } type="email" label="Your email"/>
                <Button label="Submit" type="primary" callback={ submit }/>
            </LoadingIndicator>
        </Step>
    )
}



const RestoreByToken = (to:string|undefined) => {
    const token = QueryString.parse(QueryString.extract(window.location.href)).token;
    const restored = useRestore(token as any)
    const navigation = Common.useNavigation();

    useEffect(() => {
        if(restored) navigation.goTo(to || 'final-price');
    }, [restored]);

    return <Step showBack={false}></Step>;
}


const Restore = () => {
    const navigation = Common.useNavigation();
    const token = QueryString.parse(QueryString.extract(window.location.href)).token;
    const to = QueryString.parse(QueryString.extract(window.location.href)).to;

    useEffect(() => {
        navigation.setRestoredForm();
    }, [])
    
    if(token) return RestoreByToken(to)
    else return RestoreByQuoteNumber();
}



export function useRestore(token:string){
    const [detailsData, detailsLoading, detailsFetch, detailsCount] = Common.useFetch(Services.detailsAndInstalments);
    const fields = Common.useFields();
    const alert = Common.useAlert();

    const quoteNumberValue = fields.valueFor('quoteNumber') || -1;

    useEffect(() => {
        if(token){
            detailsFetch(token);
        }
    }, [token])

    function setExtras(data:Partial<Fields>){
        var result:Partial<Fields> = {};

        result.__wc__ = data.workersCompensationRequired;
        result.__li__ = data.liabilitySumInsured == 20_000_000;
        result.__vw__ = data.voluntaryWorkersRequired;
        result.__fg__ = data.fidelityRequired;
        result.__ob__ = data.officeBearersRequired;
        result.__mb__ = data.machineryBreakdownRequired;
        result.__cc__ = data.catastropheRequired;
        result.__ga__ = data.governmentAuditIncluded;
        result.__lo__ = data.lotOwnersIncluded;
        result.__sb__ = data.specialBenefitsExtensionRequested;
        result.__ec__ = data.exploratoryCostsRequested;
        result.__et__ = data.extendedTempAccomodationLossRentRequested;
        result.__fu__ = data.fusionRequested;
        result.__ff__ = data.floatingFloorsRequested;

        return result;
    }

    useEffect(() => {
        if(detailsData!=null && typeof(detailsData)=='object' && Object.keys(detailsData).length==0){
            alert.showGenericErrorMessage();
        }else if(detailsData){
            var result = detailsData;
            if(detailsData.quoteNumber){
                result = {
                    ...detailsData,
                    ...setExtras(detailsData),
                    __coverType__: 'choose'
                }
            }

            fields.setFromQuoteData(result,  [RouteSet.BEFORE_ESTIMATE, RouteSet.BEFORE_FINAL_PRICE, RouteSet.BEFORE_PAYMENT]);
        }
    }, [detailsData])

    return quoteNumberValue != -1;
}


export default Restore;
