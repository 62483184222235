import React from 'react'
import Step from '../../components/Step'
import Input from '../../elements/Input'
import ButtonContainer from '../../elements/ButtonContainer'
import Button from '../../elements/Button'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'

const requesterFirstname = fieldName('requester.firstName')
const requesterSurname = fieldName('requester.surname')
const requesterPhone = fieldName('requester.phone')
const requesterEmail = fieldName('requester.email')


export const rules = {
    [ requesterFirstname ]: [
        enforce.required('Required'),
        enforce.notBlank('Required'),
    ],
    [ requesterSurname ]: [
        enforce.required('Required'),
        enforce.notBlank('Required'),
    ],
    [ requesterPhone ]: [
        enforce.required('Required'),
        enforce.notBlank('Required'),
        enforce.phoneNumber("Must be a valid phone number"),
    ],
    [ requesterEmail ]: [
        enforce.required('Required'),
        enforce.notBlank('Required'),
        enforce.email("Must be a valid email"),
    ],
}

export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();

    async function onContinue(){
        try{
            await validation.validate(rules);
            navigation.goToNext();
        }catch(e){}
    }

    return (
        <Step title="Interim price">
            <h2>Tell us about the policy holder</h2>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input field={requesterFirstname} type="text" label="First name"/>
                </div>
                <div className="col-12 col-md-6">
                    <Input field={requesterSurname} type="text" label="Surname"/>
                </div>
                <div className="col-12 col-md-6">
                    <Input field={requesterPhone} type="tel" label="Phone number"/>
                </div>
                <div className="col-12 col-md-6">
                    <Input field={requesterEmail} type="email" label="Email" caption="Please provide your email address so we can send you your quote."/>
                </div>
            </div>
            <ButtonContainer>
                <Button label="Continue" type="primary" icon="fas fa-chevron-right"  callback={ onContinue }/>
            </ButtonContainer>
        </Step>
    )
};