import { useEffect } from 'react';
import Common from '../Common'


export type RequestErrors = Record<string, string>|null
export const requestErrors:RequestErrors = null;


export const useRequestErrors = () => {
    const store = Common.useStore();
    const requestErrors = store.get('requestErrors');
    const set = store.set('requestErrors');


    function setRequestErrors(errors:RequestErrors|null){
        if(errors === null){
            clear();
        }else{
            set("setErrors", state => errors)
        }
    }


    function clear(){
        store.setAll('requestErrors')('clear', null)
    }

    return {
        set: setRequestErrors,
        all: requestErrors,
        clear,
    }
}
