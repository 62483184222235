import React from 'react';
import Common from '../common/Common';


export default () => {
    const navigation = Common.useNavigation();

    const style = {
        width: (navigation.progress() * 100) + "%"
    }

    return (
        <div className="progress-bar">
            <div className="progress-bar-bar" style={style}/>
        </div>
    )
}