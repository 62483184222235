import React from 'react';
import HelpIcon from './HelpIcon'

type Props = {
    callback:() => void
    label: string,
    buttonClass: string,
    icon?: string,
    centered?: boolean,
    help?:string
}


const getIcon = (icon?:string) => {
    return icon
        ? <div className="fill-button-icon"><i className={icon}></i></div>
        : null
}

const FillButton = ({ callback, label, icon, buttonClass, centered, help }:Props) => (
    <button type="button" className={`btn fill-button ${buttonClass} ${icon?"with-icon":""}`} onClick={ callback }>
        <div className="icon-container">
            { getIcon(icon) }
        </div>
        <div className={`fill-button-contents ${centered?"centered":""}`}>
            { typeof(label) == 'string'
                ? <div className="fill-button-label" dangerouslySetInnerHTML={{__html:label}}/>
                : <div className="fill-button-label">{ label }</div>
            }
            { !!help && 
            <div className="fill-button-info-body">{ help }</div>
            }

            { !!help && 
            <div className="fill-button-info">
                <HelpIcon label={label} message={help} />
            </div>
            }
        </div>
        
    </button>
)

export default FillButton;
