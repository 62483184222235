import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const fireAlarm = fieldName('fireAlarm');

export const rules = {
    [fireAlarm]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Does the building have fire alarms? <HelpIcon label={fireAlarm} message=""/></h2>
            <ButtonsYesNo field={fireAlarm} callback={ navigation.goToNext } />
        </Step>
    )
}