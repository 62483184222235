import React, { useRef, useEffect, useState, ReactNode } from 'react'
import Common from '../common/Common';

type Props = {
    condition: any,
    offset?:number,
    method?: 'show' | 'topOf',
    children:ReactNode
}


const ScrollWhenAppears = ({ children, condition, offset, method }:Props) => {
    const ref = useRef(null);
    const scroll = Common.useScroll();
    const [ cachedCondtion, setCachedCondition ] = useState(condition)

    if(offset == null) offset = 16;
    if(method == null) method = 'show'

    useEffect(() => {
        if(cachedCondtion !== condition){
            if(condition){
                if(method == 'show') scroll.show(ref, offset);
                if(method == 'topOf') scroll.toTopOf(ref, offset);
            }
            setCachedCondition(condition);
        }
    }, [ condition ])

    return (
        <div ref={ ref }>
            { condition ? children : null }
        </div>
    )
}


export default ScrollWhenAppears;
