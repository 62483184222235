import React, { useEffect } from 'react'
import Common from '../Common'
import env from '../../env'


export type Alert = {
    show: boolean,
    info:{
        title?: React.ReactNode,
        message: React.ReactNode,
        buttonLabel?: string,
        redirect?: string,
    },
}

export const alert:Alert = {
    show: false,
    info: {
        title:"",
        message: "",
        buttonLabel: undefined as string|undefined,
        redirect: undefined as string|undefined,
    }
}


export const useAlert = () => {
    const store = Common.useStore();
    const state = store.get('alert');
    const set = store.set('alert');
    const unloadHandler = Common.useUnloadHandler();
    const requestErrors = Common.useRequestErrors();

    useEffect(() => {
        if(requestErrors.all){
            console.log("Has request errors");
            showErrors(requestErrors.all);
        }
    }, [requestErrors.all]);


    useEffect(() => {
        if(state.show == false){
            requestErrors.clear();            
        }
    }, [state.show]);

    
    function show(info:Alert['info']){
        set('show', state => ({
            show: true,
            info,
        }));
    }

    function showErrors(errors:Record<string, string>){
        console.log("Show Errors");
        const info = {
            title: "Error",
            message:  <div dangerouslySetInnerHTML={{ __html: Object.keys(errors).map(key => errors[key]).join('<br>') }} />,
        }
        show(info);
    }

    function showGenericErrorMessage(){
        unloadHandler.dontDisplayUnloadWarning();
        
        if(env.DEBUG_SUPRESS_ERROR_ALERTS === false){
            const info = {
                title: "Error",
                message: <div dangerouslySetInnerHTML={{ __html: env.ERROR_MESSAGE }} />,
                redirect: env.ERROR_REDIRECT,
            }
            show(info)
        }
    }


    function showError(e:Error){
        console.error(e);
        showGenericErrorMessage();
    }


    function hide(){
        set('hide', state => ({
            show: false,
        }))
    }

    return {
        showGenericErrorMessage,
        showError,
        showErrors,
        show,
        hide,
        state,
    }
}
