import Common from "../common/Common";
import { enforce, fieldName } from '../common/HelpersCommon'
import { Modal, Button as ButtonModal } from 'react-bootstrap'
import Input from './Input'
import React from "react";


const requesterFirstName = fieldName('requester.firstName')
const requesterSurname = fieldName('requester.surname')
const requesterPhone = fieldName('requester.phone')
const requesterEmail = fieldName('requester.email')


const ContactDetailsModal = ({show, onCancel, onRequest}:{show:boolean, onCancel:()=>void, onRequest:()=>void}) => {
    const validation = Common.useValidation();

    async function onSubmit(){
        const rules = {
            [requesterFirstName]: [ 
                enforce.required('Required'),
                enforce.notBlank('Required'),
            ],
            [requesterSurname]: [ 
                enforce.required('Required'),
                enforce.notBlank('Required'),
            ],
            [requesterPhone]: [
                enforce.required('Required'),
                enforce.phoneNumber("Must be a valid phone number"),
                enforce.notBlank('Required'),
            ],
            [requesterEmail]: [ 
                enforce.required('Required'),
                enforce.email("Must be a valid email"),
                enforce.notBlank('Required'),
            ],
        }
        try{
            await validation.validate(rules);
            onRequest();
        }catch(e){}
    }

    return (
        <Modal id="app-modal-form" show={ show }>
            <Modal.Header>
                <Modal.Title>Please provide your contact details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row" style={{ backgroundColor:'rbga(0,0,0,0.5)'}}>
                    <div className="col-12 col-md-6">
                        <Input field={requesterFirstName} type="text" label="First name"/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input field={requesterSurname} type="text" label="Surname"/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input field={requesterPhone} type="tel" label="Phone number"/>
                    </div>
                    <div className="col-12 col-md-6">
                        <Input field={requesterEmail} type="email" label="Email"/>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ButtonModal variant="secondary" onClick={ onCancel }>Cancel</ButtonModal>
                <ButtonModal variant="primary" onClick={ onSubmit }>Submit</ButtonModal>
            </Modal.Footer>
        </Modal>
    )
}


export default ContactDetailsModal;