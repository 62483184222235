import { routes } from './RoutesResi';
import { Fields } from './FieldsResi'
import { enforce as commonEnforce } from '../common/HelpersCommon'

// Use this function to ensure that a string corresponds to an actual route.
export function routeName(key: typeof routes[number][1]){
    return key;
}

// Use this function to ensure that a string corresponds to an actual field.
// We typically only use the first parameter: ie fieldName("buildingSumInsured").
// The ... parameters are used if we need to attain the field name for array nested
// fields: ie fieldName("property[x].unitNumber", 1) returns property[1].unitNumber
export function fieldName(key:keyof (Fields), ...$:number[]) {
    let result:string = key;
    for(var i = 0; i < $.length; i++){
        result = result.replace('[x]', '[' + $[i] + ']') as string;
    }
    return result;
}

export const enforce = commonEnforce;