import { Brand, Form, Environment, NodeEnvironment } from './envTypes'
import { getEnvironmentValue as getValue } from './envUtil'

const nodeEnv = getValue(process.env, 'NODE_ENV', NodeEnvironment) as NodeEnvironment;

// We are doing a bit of parsing here of the raw environment variables
// to put them in structure that is statically typed. An error will be thrown 
// if the environment variable is missing.
export default {
    ENVIRONMENT: getValue(process.env, 'REACT_APP_ENVIRONMENT', Environment) as Environment,
    NODE_ENV: nodeEnv,
    FORM: getValue(process.env, 'REACT_APP_FORM', Form) as Form,
    BRAND: getValue(process.env, 'REACT_APP_BRAND', Brand) as Brand,
    ENDPOINT_HOST: getValue(process.env, 'REACT_APP_ENDPOINT_HOST'),
    REACT_APP_DECLINED_LINK: getValue(process.env, 'REACT_APP_DECLINED_LINK'),
    ENDPOINT_COMMON_HOST: getValue(process.env, 'REACT_APP_ENDPOINT_COMMON_HOST'),
    ENDPOINT_INSTALMENTS: getValue(process.env, 'REACT_APP_INSTALMENTS_ENDPOINT'),
    BRAND_STYLE: getValue(process.env, 'REACT_APP_BRAND_STYLE'),
    ERROR_MESSAGE: getValue(process.env, 'REACT_APP_ERROR_MESSAGE'),
    ERROR_REDIRECT: getValue(process.env, 'REACT_APP_ERROR_REDIRECT'),
    CONTACT_EMAIL: getValue(process.env, 'REACT_APP_CONTACT_EMAIL'),
    CONTACT_PHONE: getValue(process.env, 'REACT_APP_CONTACT_PHONE'),
    CONTACT_PHONE_READABLE: getValue(process.env, 'REACT_APP_CONTACT_PHONE_READABLE'),
    CONTACT_LINKED_IN: getValue(process.env, 'REACT_APP_CONTACT_LINKED_IN'),
    GMT: getValue(process.env, 'REACT_APP_GMT'),

    // During development, it is sometimes convenient to disable certain features.
    // (nodeEnv === 'development') ensures that disabling such a feature and
    // won't make it's way to staging or production.
    DEBUG_UNBLOCK_ROUTES: false || (nodeEnv === 'development'), // Disable redirect to duty of disclosure on form initialisation
    DEBUG_SUPRESS_ERROR_ALERTS: false && (nodeEnv === 'development'), // Disable alerts on errors
    DEBUG_SUPRESS_UNLOAD_WARNING: false && (nodeEnv === 'development'), // Disable the unload handler (warning popup) when you are closing an in-progress form

    RECAPTCHA: getValue(process.env, 'REACT_APP_RECAPTCHA'),
}
