import calculateInstalments from "./services/calculateInstalments";

export type Fields = {
    '__dirty__':boolean,

    'dutyOfDisclosure': boolean,

    'requester.firstName': string,
    'requester.surname': string,
    'requester.phone': string,
    'requester.email': string,

    'insured.name': string,
    'insured.phone': string,
    'insured.email': string,

    // Reponse fields
    'token': string | null,
    'referred': boolean,
    'referrals.REFERENCECODE': string,
    'totalPayable':number,
    'showPrice':boolean,
    'productDocuments.pdsUrl':string,
    'quoteNumber': string,
    'declined': boolean,

    'basePremium':number,
    'levies': number,
    'gst': number,
    'stampDuty':number, 
    'adminFee':number, 
    'brokerFee': number, 
    'commission': number, 
    'commissionGst':number,
    
    '__restoreToken__':string,
    '__useQuote__':boolean,

    // Instalments fields
    instalments: Awaited<ReturnType<typeof calculateInstalments>>,
};


export const intialFieldValues = {
};
