import Request from './request'
import Common from '../Common';

export type PaymentAuthoriseResult = { successful:true, paymentSuccessRedirect:string, policyNumber:string } | { successful:false, reason:string }

export default async (paymentMethodNonce:string, deviceData:any) => {
    const url = Common.config.services.paymentAuthorise;

    let data:PaymentAuthoriseResult = await Request.post(url, {
        paymentMethodNonce,
        deviceData,
    });
    return data;
}
