import React from 'react';
import Step from '../../../components/Step'
import env from '../../../env';
import Resi from '../../Resi'


const Declined = () => {
    const fields = Resi.useFields()
    const referrals = fields.all.referrals || {}

    return (
        <Step showBack={false} warnUnload={false}>
            <h2>Sorry</h2>
            <p>We are unable to provide you with a quote for a policy at this location.</p>
            <ul>
                { Object.keys(referrals).map(key => <li>{referrals[key]}</li>) }
            </ul>
            <p>For more information click this <a href={env.REACT_APP_DECLINED_LINK}>link</a>.</p>
            <a href="http://www.chu.com.au/" target="_blank" className="btn btn-primary btn-lg mt-2">Return Home</a>
        </Step>
    )

}

export default Declined;
