import React from 'react';

type Props ={
    textAlign: "left" | "center" | "right",
    quoteNumber: number
}

const Quote = ({textAlign, quoteNumber}:Props) => (
    <div className="quote" style={{textAlign}}>Quote #{quoteNumber}</div>
)

export default Quote;
