import React, { useEffect } from 'react'
import Step from '../../../components/Step'
import PaymentButtons from '../../../elements/PaymentButtons'
import Resi from '../../Resi';
import { RouteSet } from '../../../types'
import { getExtrasData } from '../../ExtrasResi';

const Payment = () => {
    const fields = Resi.useFields();

    const [ fetchQuote, loadingQuote, fetchQuoteCount ] = Resi.useFetchQuote({
        routeSets: [RouteSet.BEFORE_ESTIMATE, RouteSet.BEFORE_FINAL_PRICE, RouteSet.BEFORE_PAYMENT],
        extraData: getExtrasData(fields.all as any),
        quoteType: 'PAYMENT',
        onSuccess: ()=>{},
    });

     useEffect(() => {
        fetchQuote();
    }, []);

    return (
        <Step title="Payment" isLoading={loadingQuote}>
            <h2>Payment</h2>
            <PaymentButtons />
        </Step>
    )
}


export default Payment;