import React, { useEffect, useState } from 'react'
import Step from '../../components/Step'
import Resi from '../Resi'
import { RouteSet } from '../../types'
import SectionFlexi from '../../elements/SectionFlexi'
import Sections from '../../elements/Sections'
import Section from '../../elements/Section'
import SectionUpdatablePrice from '../../elements/SectionUpdatablePrice'
import { fieldName } from '../HelpersResi'
import { getExtrasData } from '../ExtrasResi'
import { HideOnRequestError } from '../../elements/HideOnRequestError'
import googleAnalytics from '../../common/services/google-analytics'

export const rules = {

};

const priceOptions = [
    { type:"A", label:"Yearly", value:"YEARLY", description:"Yearly"},
    { type:"A", label:"Monthly", value:"MONTHLY", description:"Monthly"},
]

export default () => {
    const navigation = Resi.useNavigation();
    const fields = Resi.useFields();
    const requestErrors = Resi.useRequestErrors();
    const [state, setState] = useState({
        dirty:false,
    })

    const [ fetchQuote, loadingQuote, fetchQuoteCount ] = Resi.useFetchQuote({
        routeSets: [RouteSet.BEFORE_ESTIMATE, RouteSet.BEFORE_FINAL_PRICE],
        extraData: getExtrasData(fields.all as any),
        quoteType: 'FULL_QUOTE',
        onSuccess: ()=>{}
    });

    useEffect(() => {
        //if(fields.valueFor('__useQuote__')){
            fetchQuote();
        //}
    }, [])

    useEffect(() => {
        if(loadingQuote == false){
            setState({
                ...state,
                dirty: false,
            })
        }
    }, [loadingQuote]);

    useEffect(() => {
        console.log("Dity: " + state.dirty)
    }, [state.dirty]);


    useEffect(() => {
        setState({
            ...state,
            dirty: true
        })
    }, [
        fields.valueFor("__vw__"),
        fields.valueFor("__wc__"),
        fields.valueFor("__fg__"),
        fields.valueFor("__ob__"),
        fields.valueFor("__mb__"),
        fields.valueFor("__cc__"),
        fields.valueFor("__ga__"),
        fields.valueFor("__lo__"),
        fields.valueFor("__ec__"),
        fields.valueFor("__et__"),
        fields.valueFor("__fu__"),
        fields.valueFor("__ff__"),
        fields.valueFor("__sb__"),
    ])


    useEffect(() => {
        if(!fields.value('__paymentSchedule__')){
            fields.setValue('__paymentSchedule__')(priceOptions[0].value);
        }
    }, []);


    const labelLookup = {
        "__vw__": "Voluntary workers cover",
        "__fg__": "Fidelity Guarantee cover",
        "__ob__": "Office Bearers cover",
        "__wc__": "Workers Compensation cover",
        "__mb__": "Machinery Breakdown cover",
        "__cc__": "Catastrophe Cover",
        "__ga__": "Government Audit and Legal Expenses cover",
        "__lo__": "Lot Owner's Fixtures and Improvements cover",
        "__ec__": "Exploratory costs",
        "__et__": "Extended Temporary accommodation or Loss of Rent",
        "__fu__": "Fusion",
        "__ff__": "Floating floors",
        "__sb__": "Special benefits",
    }


    function onUpdateClicked(){
        console.log("On click update");
        fetchQuote();
        /*
        setState({
            ...state,
            loading: true,
        }) // Do a fetch here instead
        */
    }

    function onContinue(){
        if(fields.value("__vw__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__vw__"])
        if(fields.value("__fg__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__fg__"])
        if(fields.value("__ob__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__ob__"])
        if(fields.value("__wc__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__wc__"])
        if(fields.value("__mb__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__mb__"])
        if(fields.value("__cc__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__cc__"])
        if(fields.value("__ga__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__ga__"])
        if(fields.value("__lo__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__lo__"])
        if(fields.value("__ec__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__ec__"])
        if(fields.value("__et__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__et__"])
        if(fields.value("__fu__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__fu__"])
        if(fields.value("__ff__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__ff__"])
        if(fields.value("__sb__")) googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_INPUT, "additional cover options", labelLookup["__sb__"])

        navigation.goToNext();
    }

    function onSectionClick(id:string){
        return function(){
            googleAnalytics.customInteraction(googleAnalytics.Actions.CHECKBOX_CLICK, "additional cover option: " + labelLookup[id as keyof typeof labelLookup]!, !fields.value(id))
            fields.setValue(id)(!fields.value(id));
        }
    }

    function withMandatory(field:string){
        return  fields.value(field) == 'mandatory'
            ? <span>- Compulsory</span>
            : <></>
    }

    return (
        <Step containerWidth="960px" isLoading={loadingQuote}>
            <h2>Please choose your flexible policy options&nbsp;below</h2>
            <div className="row">
                <div className="col-lg-8 order-3">
                    <Sections>
                        <Section heading="Add additional cover" colorHeading='secondary'>
                            <SectionFlexi requirement={fields.valueFor('__VW__')} selected={fields.value("__vw__")} onClick={onSectionClick("__vw__")} title={"Voluntary workers cover"} body="Cover if anyone is injured while doing unpaid work on behalf of the owners corporation." />
                            <SectionFlexi requirement={fields.valueFor('__FG__')} selected={fields.value("__fg__")} onClick={onSectionClick("__fg__")} title={"Fidelity Guarantee cover"} body="Cover if owners corporation funds are subject to fraud or theft by a third party."/>
                            <SectionFlexi requirement={fields.valueFor('__OB__')} selected={fields.value("__ob__")} onClick={onSectionClick("__ob__")} title={"Office Bearers cover"} body="Cover for legal expenses of defending a claim against a member of your strata committee."/>
                            <SectionFlexi requirement={fields.valueFor('__MB__')} selected={fields.value("__mb__")} onClick={onSectionClick("__mb__")} title={"Machinery Breakdown cover"} body="Cover for damage and repairs to machinery and plant, including but not limited to lifts, pumps and more."/>
                            <SectionFlexi requirement={fields.valueFor('__CC__')} selected={fields.value("__cc__")} onClick={onSectionClick("__cc__")} title={"Catastrophe Cover"} body="Cover for increased building expenses due to a catastrophe."/>
                            <SectionFlexi requirement={fields.valueFor('__GA__')} selected={fields.value("__ga__")} onClick={onSectionClick("__ga__")} title={"Government Audit and Legal Expenses cover"} body="Cover for fees, costs and legal expenses incurred as a result of a government audit."/>
                            <SectionFlexi requirement={fields.valueFor('__LO__')} selected={fields.value("__lo__")} onClick={onSectionClick("__lo__")} title={"Lot Owner’s Fixtures and Improvements cover"} body="Cover for additional improvements or renovations made by the lot owner in the event the building sum insured has been exhausted."/>
                            <SectionFlexi requirement={fields.valueFor('__EC__')} selected={fields.value("__ec__")} onClick={onSectionClick("__ec__")} title={"Exploratory costs"} body="Costs associated with searching for the source of a water leak.  "/>
                            <SectionFlexi requirement={fields.valueFor('__ET__')} selected={fields.value("__et__")} onClick={onSectionClick("__et__")} title={"Extended Temporary accommodation or Loss of Rent"} body="Increased limits for temp accommodation/loss of rent should a claimable event leave your property inhabitable.  "/>
                            <SectionFlexi requirement={fields.valueFor('__FU__')} selected={fields.value("__fu__")} onClick={onSectionClick("__fu__")} title={"Fusion"} body="Protection of electrical motors attached to the building for fusion damage.  "/>
                            <SectionFlexi requirement={fields.valueFor('__FF__')} selected={fields.value("__ff__")} onClick={onSectionClick("__ff__")} title={"Floating floors"} body="Protection for floorboards that are not fixed to the slab but are held in place by clips or skirting boards."/>
                            <SectionFlexi requirement={fields.valueFor('__SB__')} selected={fields.value("__sb__")} onClick={onSectionClick("__sb__")} title={"Special benefits"} body="Covers for extending limits on items such as: fallen trees, landscaping, removal and storage costs of personal contents, failure of supply services, cost of reletting, keys and lock replacements, car park liability, hiring of sporting/recreational facilities, garden equipment and court appearances."/>
                        </Section>
                    </Sections>
                </div>
                <HideOnRequestError>
                    <div className="col-lg-4 order-4 order-lg-4" >
                        <div className='sticky-price'>
                            <Sections>
                                <SectionUpdatablePrice onContinue={onContinue}
                                                    scheduleField="__paymentSchedule"
                                                    priceOptions={priceOptions}
                                                    hideContinueSmall={true}
                                                    amount={(fields.valueFor("__paymentSchedule__")=="MONTHLY" ? fields.all.instalments?.instalmentPayments[0].instalmentAmount : fields.valueFor('totalPayable')) || 0.0}
                                                    dirty={state.dirty}
                                                    onUpdateClicked={onUpdateClicked}
                                />
                            </Sections>
                        </div>
                    </div>
                </HideOnRequestError>
            </div>
        </Step>
    )
};

