import Request from './request'
import Common from '../Common';


type PostcodeResult = {
    suburb: string,
    state: string,
    postcode: string,
}[];


export default async (partialSuburb:string|undefined|null) => {
    const url = Common.config.services.postcode;

    // Allow for undefined parameters for convenience
    if(!partialSuburb) return [];

    // Setup the request
    const params = {
        partialSuburb
    }

    // Perform request
    let result:PostcodeResult = await Request.get(url, params);
    
    return result;
}
