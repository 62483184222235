import React, { FunctionComponent, ReactNode } from 'react';
import TitleContainer from './TitleContainer';
import Common from '../common/Common';
import LoadingIndicator from '../elements/LoadingIndicator';
import PriceHover from '../elements/PriceHover';


type Props = {
    title?: ReactNode,
    warnUnload?:boolean,
    showBack?:boolean,
    isLoading?:boolean,
    containerWidth?:string,
    preventRestore?:boolean,
    children?:ReactNode
}


const UnloadBlocker = ({block, children}:{block:boolean, children:ReactNode}) => {
    const unloadHandler = Common.useUnloadHandler();
    if(block) unloadHandler.displayUnloadWarning();
    else unloadHandler.dontDisplayUnloadWarning();
    
    return <>{children}</>;
}



const Step = ({ containerWidth, title, children, preventRestore, warnUnload=true, showBack=true, isLoading=false }:Props) => {
    const loading = Common.useLoading();

    const containerStyle = !containerWidth ? {} : {
        maxWidth: containerWidth
    }

    return (
        <div className="step">
            <UnloadBlocker block={warnUnload}>
                <LoadingIndicator fixed={true} loading={ loading.isLoading || isLoading }>
                    <TitleContainer containerWidth={containerWidth} title={ title } showBack={ showBack }/>
                    <div className="content-container">
                        <div className="container" style={containerStyle}>
                            <div className="row">
                                <div className="col-12">
                                    { children }
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </LoadingIndicator>
            </UnloadBlocker>
        </div>
    )
}


export default Step;
