import Common from './Common'
import {fieldName } from './HelpersCommon'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import env from '../env'
import { RoutesMap } from '../types'



// UPDATE REQUESTER ALONG WITH INSURED
function useUpdateRequester(){
    const fields = Common.useFields();
    const insuredNameValue = fields.valueFor('insured.name');
    const insuredPhoneValue = fields.valueFor('insured.phone');
    const insuredEmailValue = fields.valueFor('insured.email');
    useEffect(() => {
        const requesterFirstname = fieldName('requester.firstName');
        const requesterSurname = fieldName('requester.surname');
        const requesterEmail = fieldName('requester.email');
        const requesterPhone = fieldName('requester.phone')

        fields.setValues({
            [requesterFirstname]: insuredNameValue,
            [requesterSurname]: insuredNameValue,
            [requesterPhone]: insuredPhoneValue,
            [requesterEmail]: insuredEmailValue,
        })
    }, [insuredNameValue, insuredPhoneValue, insuredEmailValue])
}


// REDIRECT TO DUTY OF DISCLOSURE
function useRedirectToDutyOfDisclosure(){
    const fields = Common.useFields();
    const navigation = Common.useNavigation();
    const location = useLocation();

    const requesterFirstNameValue = fields.value( fieldName('requester.firstName') );
    const disclosureValue = fields.value( fieldName('dutyOfDisclosure') );
    
    useEffect(() => {
        if(location.pathname !== '/duty-of-disclosure'
        && location.pathname !== '/restore'
        && location.pathname !== '/payment'
        && location.pathname !== '/instalments-success'
        && location.pathname !== '/instalments-fail'
        && location.pathname !== '/instalments-cancel'
        && !requesterFirstNameValue
        && !disclosureValue
        && !env.DEBUG_UNBLOCK_ROUTES){
            console.log("Redirect to duty-of-disclosure from : " + location.pathname)
            navigation.redirect('/duty-of-disclosure')
        }
    }, [location, requesterFirstNameValue])
}


// HANDLE OPTIONS CONDITIONS
function useHandleOptionsConditions(){
    const navigation = Common.useNavigation();
    const options = Common.useOptions();
    const conditions = Common.useConditions();

    useEffect(() => {
        const { anyRoute } = navigation;
        conditions.setConditions({
            [ anyRoute() ]: options.loaded,  
        });
    }, [options.loaded]);
}


// LOAD OPTIONS
function useOptions(service:()=>Promise<any>){
    const options = Common.useOptions();

    useEffect(() => {
        async function load(){
            const data = await service();
            options.setOptions( data );
        }
        load();
    }, []);
}


// SET INITIAL FIELDS
function useSetInitialFields(intialFieldValues:any){
    const fields = Common.useFields();
    useEffect(() => {
        fields.setValues(intialFieldValues);
    }, [])
}


// SETUP ROUTES
function useSetupRoutes(routes:RoutesMap){
    const navigation = Common.useNavigation();
    useEffect(() => {
        navigation.setupRoutes(routes);
    }, []);
}


// EFFECT SPECIFIC TO MODULES
function useStoreEffects(){
    Common.useStoreEffects();
}


export function useCommonEffects(intialFieldValues:any, routes:RoutesMap, optionsPromise:()=>Promise<any>){
    useUpdateRequester()
    useRedirectToDutyOfDisclosure()
    useHandleOptionsConditions()
    useOptions(optionsPromise)
    useSetInitialFields(intialFieldValues)
    useSetupRoutes(routes)
    useStoreEffects()
}
