import React, { useEffect, useState } from 'react'
import Step from '../../components/Step'
import Resi from '../Resi'
import Input from '../../elements/Input'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'
import ButtonContainer from '../../elements/ButtonContainer'
import Button from '../../elements/Button'
import Select from '../../elements/Select'
import googleAnalytics from '../../common/services/google-analytics'


const ownersCorporationNumber = fieldName('ownersCorporationNumber')
const state = fieldName('ownersCorporationAddress.state')


export const rules = {
    [ownersCorporationNumber]:[
        enforce.required("Required"),
        enforce.ownersCorporationNumber("ownersCorporationAddress.state")("Owners Corporation numbers should have a maximum of 9 digits, and optionally followed by two characters. Northern Territory Owners Corporation number must have 1 to 4 digits, '/' followed by 1 to 4 digits."),
    ],
}

const prefixOptions = [
    { title: "SP", description:"SP", value:"SP", type:"", },
    { title: "UP", description:"UP", value:"UP", type:"", },
    { title: "PS", description:"PS", value:"PS", type:"", },
    { title: "RP", description:"RP", value:"RP", type:"", },
    { title: "CS", description:"CS", value:"CS", type:"", },
    { title: "CP", description:"CP", value:"CP", type:"", },
    { title: "CTS", description:"CTS", value:"CTS", type:"", },
    { title: "", description:"", value:"", type:"", },
]

export const OwnersCorpHelpText = () => {
    return(
        <ul>
            <li>NSW - Strata Plan number</li>
            <li>ACT - Unit Plan number</li>
            <li>NT - Unit Plan number</li>
            <li>WA - Strata Plan number</li>
            <li>SA - Strata Corporation number</li>
            <li>TAS - Strata Corporation number</li>
            <li>QLD - Community Title Scheme (CTS) number</li>
            <li>VIC - Owners Corporation, Cluster Plan, or Consolidated Plan number</li>
        </ul>
    )
}

export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();
    const fields = Resi.useFields();

    async function onContinue(){
        try{
            await validation.validate(rules);
            navigation.goToNext();
        }catch(e){}
    }

    function onSelectChange(value:string){
        googleAnalytics.customInteraction(googleAnalytics.Actions.SELECT, "owners corporation prefix", value)
    }

    return (
        <Step>
            <h2>What is your owner's corporation number? <HelpIcon label={ownersCorporationNumber} message={<OwnersCorpHelpText />}/></h2>
            <div className="row">
                <div className="col-4 col-md-2">
                    <Input field="__owners-corp-prefix__" type="text" label="Prefix">
                        <Select options={ prefixOptions }
                                onSelectChange={ onSelectChange }
                                field="__owners-corp-prefix__"/>
                    </Input>
                </div>
                <div className="col-8 col-md-6">
                    <Input type="ownersCorp" field={ ownersCorporationNumber } label="Number"/>
                </div>
            </div>
            <ButtonContainer>
                <Button type="primary" label="Continue" icon="fas fa-chevron-right" callback={ onContinue }/>
            </ButtonContainer>
        </Step>
    )
}

