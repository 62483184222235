import React from 'react'
import Step from '../../../components/Step'
import env from '../../../env'


const PaymentOffline = () => {
    return (
        <Step warnUnload={false} showBack={false}>
            <h2>Thank you for choosing Flex Insurance.</h2>
            <p>A Tax Invoice is on the way and will be in your inbox soon. Please follow the instructions on the tax invoice to pay for your policy.</p>
            <a href={ env.ERROR_REDIRECT } className="btn btn-primary btn-lg mt-2">Return Home</a>
        </Step>
    )
}

export default PaymentOffline;
