import React, { useEffect, useState } from 'react'
import Common from '../common/Common'


export function HideOnRequestError({children}:{children:React.ReactNode}){
    const requestErrors = Common.useRequestErrors();
    const [state, setState] = useState({
        hide:false
    });

    useEffect(() => {
        if(requestErrors.all){
            setState({
                ...state,
                hide: true
            });
        }
    }, [requestErrors.all]);


    if(state.hide) return null;

    return (
        <>{children}</>
    )
}