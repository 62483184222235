import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const fireHydrantsHoseReels = fieldName('fireHydrantsHoseReels');

export const rules = {
    [fireHydrantsHoseReels]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Does the building have fire hydrants or hose reels? <HelpIcon label={fireHydrantsHoseReels} message=""/></h2>
            <ButtonsYesNo field={fireHydrantsHoseReels} callback={ navigation.goToNext } />
        </Step>
    )
}