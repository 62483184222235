import React from 'react'
import Common from '../common/Common';

type Props = {
    selected:boolean,
    title:React.ReactNode,
    body:React.ReactNode,
    requirement: 'no'|'mandatory'|'optional',
    onClick:()=>void,
}


const SectionFlexi = ({requirement, onClick, selected, title, body}:Props) => {
    const fields = Common.useFields();

    if(requirement=='no') return null;

    const checked = selected || requirement == 'mandatory';

    return (
        <div className="section-flexi" data-compulsory={requirement=='mandatory'} onClick={onClick}>
            <div className="section-flexi-icon" data-show={checked}><i className="fas fa-check-square fa-2x"/></div>
            <div className="section-flexi-icon" data-show={!checked}><i className="far fa-square fa-2x"/></div>
            <div>
                <div className="section-flexi-title">{title} {requirement=='mandatory' && <span>- Compulsory in&nbsp;{fields.value('ownersCorporationAddress.state')}</span>}</div>
                <div className="section-flexi-body">{body}</div>
            </div>
        </div>
    )
}

export default SectionFlexi;