import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import AppResi from './form-resi/AppResi'

import '@fortawesome/fontawesome-free/js/all.js';
import 'core-js'; // IE11 Polyfill
import env from './env/'


function logEnv(){
    if(env.ENVIRONMENT !== "production"){
        console.log(process.env);
    }
}


function setupTheme(){
    // This line must reference the env variable directly so
    // that webpack can substitute the string inline
    require(process.env.REACT_APP_BRAND_STYLE!);
}


function setupApp(){
    const root = createRoot(document.getElementById('root')! )
    root.render(<AppResi />);
}


export default {
    logEnv,
    setupTheme,
    setupApp,
}