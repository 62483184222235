import { set, get } from 'lodash'


function keyMatch(a:string, b:string):("complete"|"ignore"|"proceed"){
    var regExp = /\[(.*?\])/g;
    a = a.replace(regExp,"[x]");
    b = b.replace(regExp,"[x]");
    
    if(a.indexOf(b) === 0){
        return 'complete'
    }else if(b.indexOf(a) === 0){
        return 'proceed'
    }else{
        return 'ignore'
    }
}


function flatten(object:any, keysToFlatten:string[]){
    function process(object:any, acc:Record<string, any>, path:string=""){
        if(Array.isArray(object)){
            object.forEach((value, index) => {
                process(value, acc, `${path}[${index}]`)
            })
        }else if(typeof object === "object" && object !== null){
            Object.keys(object).forEach(key => {
                const value = object[key as any];
                const nextPath = path === "" ? key : `${path}.${key}`
                var flatten = false;
                keysToFlatten.forEach(key => {
                    var match = keyMatch(nextPath, key);
                    if(match === 'proceed'){
                        flatten = true;
                    }
                })
                if(flatten){
                    process(value, acc, nextPath)
                }else{
                    acc[nextPath] = value;
                }
            })
        }else{
            acc[path] = object;
        }
    }

    let result:any = {};
    process(object, result, "");
    return result;
}


function unflatten(object:any){
    let result:any = {};
    Object.keys(object).forEach(key => {
        if(key.indexOf('[x]') != -1) throw("Invalid key: " + key);
        if(key.indexOf('__') === -1){
            set(result, key, object[key])
        }
    })
    return result;
}


export default {
    flatten,
    unflatten,
    keyMatch,
}