import React, { useEffect, useRef, useState } from 'react'
import Step from '../../../components/Step'
import Common from '../../../common/Common'
import LoadingIndicator from '../../../elements/LoadingIndicator'
import Services from '../../../common/services'
import CurrencyFormatter from 'currency-formatter'


const PaymentByCard = () => {
    const fields = Common.useFields();
    const conditions = Common.useConditions();
    const payment = Common.usePayment();
    const navigation = Common.useNavigation();
    const [ loading, setLoading ] = useState(true);
    
    const totalPayableValue = fields.valueFor('totalPayable');


    useEffect(() => {
        async function init(){
            const result = await Services.paymentInitiate();
            setLoading(false);
            setupBrainTree(result.paymentClientToken, result.paymentEnvironment);
        }
        init();
    }, []); 
    

    function setupBrainTree(paymentClientToken:string, paymentEnvironment:string){
        let deviceData:any;
    
        const onReady = (braintreeInstance:{deviceData:any}) => {
            deviceData = braintreeInstance.deviceData;
        }

        const onPaymentMethodReceived = async (paymentMethodDetails:{nonce:string, type:string}) => {
            setLoading(true);
            const result = await Services.paymentAuthorise(paymentMethodDetails.nonce, deviceData);
            setLoading(false);
            payment.setResult(result)

            if(result.successful){
                const quoteNumber = fields.valueFor('quoteNumber')
                Services.googleAnalytics.onlinePaymentsSuccess(quoteNumber, paymentMethodDetails.type, totalPayableValue);
                navigation.goTo('/payment-result-success')
            }else{
                navigation.goTo('/payment-result-fail')
            }
        }
        
        (window as any)["braintree"].setup(paymentClientToken, "dropin", {
            container: "braintree",
            onReady,
            onPaymentMethodReceived,
            dataCollector: {
                kount: {
                    environment: paymentEnvironment
                }
            },
        });
    }

    const totalAsCurrency = CurrencyFormatter.format(totalPayableValue, {
        thousand: ",",
        precision: 2
    })

    return (
        <Step>
            <h2>Payment by card</h2>
            <LoadingIndicator loading={ loading }>
                <form id="payment-form" method="post"> 
                    <div id="braintree"></div>
                    <button className="btn btn-secondary btn-lg mr-2 mt-4" onClick={ navigation.goBack }>Cancel</button>
                    <input className="btn btn-primary btn-lg mt-4 pull-right" type="submit" value={'Pay $' + totalAsCurrency} />
                </form>
            </LoadingIndicator>
        </Step>
    )
}


export default PaymentByCard;
