import React, { useState } from 'react'
import CurrencyFormatter from 'currency-formatter'
import Common from '../common/Common'
import { Collapse } from 'react-bootstrap'
import googleAnalytics from '../common/services/google-analytics'

function toCurrencyDecimal(value:number){
    return CurrencyFormatter.format(value, {
        thousand: ",",
        precision: 2
    })
}


const PriceBreakdown = () => {
    const fields = Common.useFields();
    const {basePremium, levies, gst, stampDuty, adminFee, brokerFee, commission, commissionGst } = fields.all;

    const [ state, setState ] = useState({
        open: false,
    });

    function toggleState(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.LINK_CLICK, "View premium breakdown", state.open ? "close" : "open")
        
        setState({
            ...state,
            open: !state.open,
        });
    }

    return (
        <div className="PremiumBreakdown">  
            <a onClick={e => toggleState()}>{ state.open ? "Hide premium breakdown" : "View premium breakdown"}</a>
            <br />
                <Collapse in={state.open}>
                    <div className="PremiumBreakdownTable">
                    { basePremium != null &&
                        <div className="PremiumBreakdownRow"><div>Base Premium:</div><div> ${ toCurrencyDecimal(basePremium) }</div></div>
                    }

                    { levies != null &&
                        <div className="PremiumBreakdownRow"><div>Levies:</div><div> ${ toCurrencyDecimal(levies) }</div></div>
                    }
                    
                    { gst != null && 
                        <div className="PremiumBreakdownRow"><div>GST:</div><div> ${ toCurrencyDecimal(gst) }</div></div>
                    }
                    
                    { stampDuty != null &&
                        <div className="PremiumBreakdownRow"><div>Stamp duty:</div><div> ${ toCurrencyDecimal(stampDuty) }</div></div>
                    }
                    
                    { adminFee != null &&
                        <div className="PremiumBreakdownRow"><div>Admin fee:</div><div>  ${ toCurrencyDecimal(adminFee) }</div></div>
                    }
                    
                    { (brokerFee != null && brokerFee != 0) &&
                        <div className="PremiumBreakdownRow"><div>Broker fee:</div><div>${ toCurrencyDecimal(brokerFee) }</div></div>
                    }
                    
                    { commission != null &&
                        <div className="PremiumBreakdownRow"><div>Commission:</div><div>${ toCurrencyDecimal(commission) }</div></div>
                    }
                    
                    { commissionGst != null &&
                        <div className="PremiumBreakdownRow"><div>Commission GST:</div><div>${ toCurrencyDecimal(commissionGst) }</div></div>
                    }
                    </div>
                </Collapse>
       </div>
    )
}




export default PriceBreakdown;