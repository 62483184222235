import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const equipmentInBasements = fieldName('equipmentInBasements', 0);

export const rules = {
    [equipmentInBasements]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Is there any equipment or plant in the basement(s)? <HelpIcon label={equipmentInBasements} message="eg. lift motors, exhaust systems and switchboards etc."/></h2>
            <ButtonsYesNo field={equipmentInBasements} callback={ navigation.goToNext } />
        </Step>
    )
}