import React from 'react'

type Props = {
    amount: number,
    size?: "large" | "",
    showYearly?:boolean,
    bottomCents?:boolean,
}


const fullDollars = (amount:number) => {
    return Math.floor(amount)
}

const cents = (amount:number) => {
    const decimal = amount - Math.floor(amount);
    var cents = Math.floor(decimal * 100);
    if(cents < 10) return "0" + cents;
    return cents;
}

const Price= ({ bottomCents, amount, size="", showYearly=false }:Props) => {
    if(isNaN(amount)) return null;
    
    return (
        <div className={`price ${size}`}>
            <div className="price-icon"><i className="fas fa-dollar-sign"></i></div>
            <div className="price-dollars">{ fullDollars(amount) }</div>
            <div className="price-cents" data-bottom={bottomCents}>.{ cents(amount) }{ showYearly ? <><br /><span>Yearly</span></> : <></> }</div>
        </div>
    )
}

export default Price;
