import React, { useEffect, useRef } from 'react'
import Step from '../../components/Step'
import Resi from '../Resi'
import Button from '../../elements/Button'
import Sections from '../../elements/Sections'
import Section from '../../elements/Section'
import CoverOptions from '../../elements/CoverOptions'
import FinalPriceSectionSaveEdit from '../../elements/FinalPriceSectionSaveEdit'
import ButtonContainer from '../../elements/ButtonContainer'
import FinalPriceSectionAwards from '../../elements/FinalPriceSectionAwards'
import SectionUpdatablePrice from '../../elements/SectionUpdatablePrice'
import { Option, RouteSet } from '../../types'
import CurrencyFormatter from 'currency-formatter'
import { fieldName, routeName } from '../HelpersResi'
import { getExtrasData } from '../ExtrasResi'
import { HideOnRequestError } from '../../elements/HideOnRequestError'
import googleAnalytics from '../../common/services/google-analytics'


const priceOptions = [
    { type:"A", label:"Yearly", value:"YEARLY", description:"Yearly"},
    { type:"A", label:"Monthly", value:"MONTHLY", description:"Monthly"},
]

export const rules = {};


export default () => {
    const navigation = Resi.useNavigation();
    const fields = Resi.useFields();
    const conditions = Resi.useConditions();
    const coverOptionsRef = useRef(null);
    const options = Resi.useOptions();
    const requestErrors = Resi.useRequestErrors();


    const [ fetchQuote, loadingQuote, fetchQuoteCount ] = Resi.useFetchQuote({
        routeSets: [RouteSet.BEFORE_ESTIMATE, RouteSet.BEFORE_FINAL_PRICE],
        extraData: getExtrasData(fields.all as any),
        quoteType: 'FULL_QUOTE',
        onSuccess: ()=>{},
    });
    
    useEffect(() => {
        if(fields.valueFor('__coverType__') == 'basic' || fields.valueFor('__coverType__') == 'all'){
            fetchQuote();
        }
    }, [])

    function onContinue(){
        googleAnalytics.customNonInteraction(googleAnalytics.Actions.FIELD_BIND, "payment frequency", fields.valueFor('totalPayable'))
        navigation.goToNext()
    }

    function onSelected(value:string){
        fields.setValue("__selection__")(value);
        navigation.goToNext();
    }


    function onSelectedTab(value:string){
        fields.setValue("__selection__")(value);
    }

    const valueFor = fields.valueFor;

    const addressString = valueFor('ownersCorporationAddress.streetNumber') + ' ' + valueFor('ownersCorporationAddress.streetName') + ',' + valueFor('ownersCorporationAddress.suburb') + ',' + valueFor('ownersCorporationAddress.state') + ',' + valueFor('ownersCorporationAddress.postcode')


    function option(options:Array<Option<any>>, fieldName:Parameters<typeof valueFor>[0]){
        var option = options.find(option => option.value==valueFor(fieldName));
        if(option) return option.description.split("<wbr>").join("");
        return 'ERROR_NOT_FOUND'
    }

    function dollar(fieldName:Parameters<typeof valueFor>[0]){
        return '$' + CurrencyFormatter.format(valueFor(fieldName), {
            thousand: ",",
            precision: 0
        })
    }


    function yesNo(fieldName:Parameters<typeof valueFor>[0]){
        return valueFor(fieldName) == true ? "Yes" : "No";
    }

    return (
        <Step containerWidth="960px" isLoading={loadingQuote}>
            <h2>Final price</h2>
            <div className="row">
                <div className="col-lg-8 order-3">
                    <Sections>
                        <FinalPriceSectionAwards/>
                        <h3>Your protection overview</h3>
                        <Section collapsable={true} heading="Policy" startOpen={true}>
                            <CoverOptions cols={2} options={[
                                { label:"Start date" , value:valueFor('startDate'), route:'/inception-date' },
                                { label:"Current insurer" , value:valueFor('currentInsurer')=='OTHER' ? valueFor('otherInsurer') : option([...options.data.product.INSURER.NEW, ...options.data.product.INSURER.INSURERS], 'currentInsurer'), route:'/current-insurer' },
                                { label:"Number of claims" , value:valueFor('numberOfClaims'), route:'/number-claims' },
                                { label:"Cover limited" , value:valueFor('coverLimited'), route:'/limited' },
                                { label:"Property insurance declined" , value:valueFor('propertyInsuranceDeclined'), route:'/property-declined' },
                                { label:"Current excess" , value:dollar('currentExcess'), route:'/property-expiring-excess' },
                            ]}/>
                        </Section>

                        <Section collapsable={true} heading="Owners Corporation">
                            <CoverOptions cols={2} options={[
                                { label:"Address" , value:addressString , route:'/address' },
                                //{ label:"Self managed" , value:valueFor('selfManaged'), route:'/management-type'},
                                { show:conditions.passes(routeName('/plan-number-available')), label:"Has plan number" , value:valueFor('strataPlanNumberAvailable'), route:'/plan-number-available' },
                                { show:conditions.passes(routeName('/owners-corporation-number')), label:"Owners coorperation number" , value:valueFor('ownersCorporationNumber'), route:'/owners-corporation-number' },
                                { show:valueFor('ownersCorporationAddress.state')=='VIC', label:"Owners coorperation type" , value:option(options.data.product.OWNERS_CORP_TYPE, 'ownersCorporationType'), route:'/management-type' },
                            ]}/>
                        </Section>

                        <Section collapsable={true} heading="Property Cover">
                            <CoverOptions cols={2} options={[
                                 { label:"Building sum insured" , value:dollar('buildingSumInsured'), route:'/building-sum-insured' },
                                 { label:"Common area sum insured" , value:dollar('contentsSumInsured'), route:'/common-area' },
                                 { label:"Flood Requested" , value:valueFor('floodRequested'), route:'/flood-requested' },
                            ]} />
                        </Section>

                        <Section collapsable={true} heading="Building details">
                            <CoverOptions cols={2} options={[
                                { show:conditions.passes(routeName('/year-rewired')), label:"Year rewired", value:valueFor('yearRewired') , route:'/year-rewired' },
                                { show:conditions.passes(routeName('/year-refurbished')), label:"Year refurbished", value:valueFor('yearRefurbished') , route:'/year-refurbished' },
                                { show:conditions.passes(routeName('/year-replumbed')), label:"Year replumbed", value:valueFor('yearReplumbed') , route: '/year-replumbed' },
                                { show:conditions.passes(routeName('/heritage-listed')), label:"Heritage listed", value:valueFor('heritageListed') , route:'/heritage-listed' },
                                { label:"Number of lots" , value:valueFor('numberOfLots') , route:'/lots' },
                                { label:"Number of floors" , value:valueFor('numberOfFloors') , route:'/floors' },
                                { label:"Number of basements" , value:valueFor('numberOfBasements') , route:'/basements' },
                                { show:conditions.passes(routeName('/building-name')), label:"Building name" , value:valueFor('buildingName') , route:'/building-name' },
                                { label:"Year built" , value:valueFor('yearBuilt') , route: '/year-built' },
                                { label:"Building material walls" , value:option(options.data.product.WALL_CONSTRUCTION, 'wallConstruction'), route: '/walls-material' },
                                { label:"Building material roof" , value:option(options.data.product.ROOF_CONSTRUCTION, 'roofConstruction'), route: '/roof-material' },
                                { label:"Building material floors" , value:option(options.data.product.FLOOR_CONSTRUCTION, 'floorConstruction'), route: '/floors-material' },
                                { label:"External cladding" , value:valueFor('aluminiumCompositePanels'), route: '/external-cladding' },
                                { label:"Ground floor occupancy" , value:option(options.data.product.GROUND_FLOOR, 'groundFloorOccupancy') , route: '/ground-floor' },
                                { label:"Commercial percent" , value:valueFor('commerciallyUsed') , route: '/commercial-percent' },
                                { label:"Fire spinklers location" , value:option(options.data.product.SPRINKLERS, 'sprinklersLocation'), route: '/sprinklers' },
                                { label:"Fire alarm" , value:valueFor('fireAlarm'), route: '/fire-alarm' },
                                { label:"Fire extinguishers" , value:valueFor('fireExtinguishers') , route: '/fire-extinguishers' },
                                { label:"Fire hydrants" , value:valueFor('fireHydrantsHoseReels'), route:'/hydrants' },
                                { label:"Fully occupied" , value:valueFor('fullyOccupied'), route: '/fully-occupied' },
                                { label:"Number lifts" , value:valueFor('numberOfLifts'), route: '/lifts' },
                                { label:"Number pools" , value:valueFor('numberOfPoolsOrSpas'), route: '/pools' },
                                { label:"Has playground" , value:valueFor('hasPlayground'), route: '/playground' },
                                { label:"Has jetty" , value:valueFor('jettyWharfPontoonMarina'), route: '/jetty' },
                                { label:"Contains lake" , value:valueFor('lakePondWaterway'), route: '/lake' },
                                { label:"Has gymnasium" , value:valueFor('gymUsedByPublic'), route: '/gymnasium' },
                                { label:"Known defects" , value:valueFor('knownDefects'), route: '/defects' },
                            ]}/>
                        </Section>
                        
                        { fields.valueFor('__coverType__') != 'basic' ? 
                            <>
                                <h3>Additional cover options</h3>
                                <Section>
                                    <CoverOptions cols={2} options={[
                                            { label:"Voluntary workers"     , show:fields.valueFor('__VW__')!='no'    , value:fields.valueFor('__vw__')||fields.valueFor('__VW__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__VW__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Fidelity Guarantee"    , show:fields.valueFor('__FG__')!='no'    , value:fields.valueFor('__fg__')||fields.valueFor('__FG__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__FG__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Office Bearers"        , show:fields.valueFor('__OB__')!='no'    , value:fields.valueFor('__ob__')||fields.valueFor('__OB__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__OB__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Machinery Breakdown"   , show:fields.valueFor('__MB__')!='no'    , value:fields.valueFor('__mb__')||fields.valueFor('__MB__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__MB__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Catastrophe cover"     , show:fields.valueFor('__CC__')!='no'    , value:fields.valueFor('__cc__')||fields.valueFor('__CC__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__CC__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Government Audit"      , show:fields.valueFor('__GA__')!='no'    , value:fields.valueFor('__ga__')||fields.valueFor('__GA__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__GA__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Lot Owner's"           , show:fields.valueFor('__LO__')!='no'    , value:fields.valueFor('__lo__')||fields.valueFor('__LO__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__LO__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Exploratory costs"     , show:fields.valueFor('__EC__')!='no'    , value:fields.valueFor('__ec__')||fields.valueFor('__EC__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__EC__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Loss of rent"          , show:fields.valueFor('__LO__')!='no'    , value:fields.valueFor('__lo__')||fields.valueFor('__LO__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__LO__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Fusion"                , show:fields.valueFor('__FU__')!='no'    , value:fields.valueFor('__fu__')||fields.valueFor('__FU__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__FU__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Floating floors"       , show:fields.valueFor('__FF__')!='no'    , value:fields.valueFor('__ff__')||fields.valueFor('__FF__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__FF__')=='optional' ? '/additional-cover-options' : undefined },
                                            { label:"Special benefits"      , show:fields.valueFor('__SB__')!='no'    , value:fields.valueFor('__sb__')||fields.valueFor('__SB__')=='mandatory' ? "Insured" : "Not insured"     , route: fields.valueFor('__SB__')=='optional' ? '/additional-cover-options' : undefined },
                                        ]}/>
                                </Section>
                            </>
                            : null
                        }
                    </Sections>
                </div>
                <HideOnRequestError>
                    <div className="col-lg-4 order-2 order-lg-4">
                        <div style={{ position:'sticky', top:'24px' }}>
                            <Sections>
                                <SectionUpdatablePrice onContinue={onContinue}
                                                scheduleField="__paymentSchedule__"
                                                priceOptions={priceOptions}
                                                isLoading={loadingQuote}
                                                amount={(fields.valueFor("__paymentSchedule__")=="MONTHLY" ? fields.all.instalments?.instalmentPayments[0].instalmentAmount : fields.valueFor('totalPayable')) || 0.0}
                                />
                            
                                <FinalPriceSectionSaveEdit 
                                                emailField={fieldName('requester.email')}
                                                loadingQuote={false}
                                                extraData={getExtrasData(fields.all as any)}
                                                coverOptionsRef={coverOptionsRef} />
                            </Sections>
                        </div>
                    </div>
                </HideOnRequestError>
            </div>

            <div className="d-block d-lg-none">
                <ButtonContainer>
                    <Button label="Continue" type="primary" icon="fas fa-chevron-right" callback={onContinue} />
                </ButtonContainer>
            </div>
        </Step>
    )
};
