import React from 'react'
import Step from '../../components/Step'
import ButtonsYesNo from '../../elements/ButtonsYesNo'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const aluminiumCompositePanels = fieldName('aluminiumCompositePanels', 0);

export const rules = {
    [aluminiumCompositePanels]:[
        enforce.required("Required")
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
   
    return (
        <Step>
            <h2>Does the building have external cladding?&nbsp;<HelpIcon label={aluminiumCompositePanels} message="Cladding is the application of one material over another to provide a skin or layer. In construction, cladding is used to provide a degree of thermal insulation and weather resistance, and to improve the appearance of buildings."/></h2>
            <ButtonsYesNo field={aluminiumCompositePanels} callback={ navigation.goToNext } />
        </Step>
    )
}