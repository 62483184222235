import React from 'react'
import Step from '../../components/Step'
import Button from '../../elements/Button'
import ButtonContainer from '../../elements/ButtonContainer'
import Input from '../../elements/Input'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIcon from '../../elements/HelpIcon'


const numberOfBasements = fieldName('numberOfBasements')
    
export const rules = {
    [numberOfBasements]: [
        enforce.required("Required"),
        enforce.greaterThanEqual(0)("Please enter a number no less than 0"),
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();

    async function next(){
        try{
            await validation.validate(rules)
            navigation.goToNext();
        }catch(e){}
    }

    return (
        <Step>
            <h2>How many basements or floors below ground are there in your building?</h2>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input autoFocus field={numberOfBasements} type="number"/>
                </div>
            </div>
            
            <ButtonContainer>
                <Button label="Continue" icon="fas fa-chevron-right" type="primary" callback={ next } />
            </ButtonContainer>
        </Step>
    )
};
