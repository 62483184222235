import React from 'react'
import Step from '../../components/Step'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import HelpIconTable from '../../elements/HelpIconTable'
import CoverOptionSelectorMobile from '../../elements/CoverOptionSelectorMobile'
import googleAnalytics from '../../common/services/google-analytics'


export const rules = [];


const coverType = fieldName('__coverType__');


const HiddenModal = ({type, children}:{type?:'check'|'circle'|null, children:React.ReactNode}) => {
    const alert = Resi.useAlert();
    const onClick = () => {
        googleAnalytics.customInteraction(googleAnalytics.Actions.LABEL_CLICK, "optional")
        alert.show({
            message: 'You will be prompted to choose the optional extras at the end.'
        })
    }
    if(type==null || 'circle' || type==undefined) return <span className="optional-message" onClick={onClick}>{children}</span>
    else return <>{children}</>;
}


export default () => {
    const navigation = Resi.useNavigation();
    const fields = Resi.useFields();

    function onSelected(value:'basic'|'choose'|'all'){
        return function(){
            googleAnalytics.customInteraction('choose-cover', value);
            fields.setValue(coverType)(value);
            navigation.goToNext();
        }
    }

    function onPDSClick(){
        googleAnalytics.customInteraction(googleAnalytics.Actions.LINK_CLICK, "PDS")
    }

    const voluntaryWorkersRequired = fields.value("ownersCorporationAddress.state") == "NSW";
    const fidelityGuaranteeRequired = fields.value("ownersCorporationAddress.state") == "SA";
    const workersCompensationAvailable = false;
    const requiredByLaw = <span className="greyed">(Required by law)</span>;
    const itemYourProperty         = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Your property {required && requiredByLaw}</span></HiddenModal> <HelpIconTable label="Your property" message="Cover for your building and common property against loss or damage."/></div>
    const itemLiability            = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Liability to others {required && requiredByLaw}</span></HiddenModal> <HelpIconTable label="Liability to others" message="Cover for compensation from personal injury or property damage."/></div>
    const itemVoluntaryWorkers     = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Voluntary workers cover {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Voluntary workers cover" message="Cover if anyone is injured while doing unpaid work on behalf of the owners corporation."/></div> 
    const itemFidelityGuarantee    = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Fidelity guarantee cover {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Fidelity guarantee cover" message="Cover if owners corporation funds are subject to fraud or theft by a third party. "/></div> 
    const itemWorkersCompensation  = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Workers compensation cover {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Workers compensation cover" message="Cover for loss of wages and medical costs if an employee of your owners' corporation suffers an injury at work."/></div> 
    const itemOfficeBearers        = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Office bearers cover {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Office bearers cover" message="Cover for legal expenses of defending a claim against a member of your strata committee."/></div> 
    const itemMachineryBreakdown   = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Machinery breakdown {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Machinery breakdown" message="Cover for damage and repairs to machinery and plant, including but not limited to lifts, pumps and more."/></div> 
    const itemCatasrophe           = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Catastrophe cover {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Catastrophe cover" message="Cover for increased building expenses due to a catastrophe."/></div> 
    const itemGovernmentAudit      = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Government audit and legal expenses {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Government audit and legal expenses" message="Cover for fees, costs and legal expenses incurred as a result of a Government Audit."/></div> 
    const itemLotOwners            = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Lot owner's fixtures and improvements {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Lot owner's fixtures and improvements" message="Cover for additional improvements or renovations made by the lot owner in the event the building sum insured has been exhausted."/></div> 
    const itemExploraroty          = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Exploratory costs {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Exploratory costs" message="Costs associated with searching for the source of a water leak. "/></div> 
    const itemLossOfRent           = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Extended temporary accommodation and loss of rent {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Extended temporary accommodation and loss of rent" message="Increased limits for temp accommodation/loss of rent should a claimable event leave your property inhabitable. "/></div> 
    const itemFusion               = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Fusion {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Fusion" message="Protection of electrical motors attached to the building for fusion damage. "/></div> 
    const itemFloatingFloors       = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Floating floors {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Floating floors" message="Protection for floorboards that are not fixed to the slab but are held in place by clips or skirting boards."/></div> 
    const itemSpecialBenefits      = (required:boolean, icon?:'check'|'circle') => <div className="cos-list-item">{icon && <HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span></HiddenModal>} <HiddenModal type={icon}><span className="cos-label">Special benefits {required && requiredByLaw}</span></HiddenModal><HelpIconTable label="Special benefits" message="Covers for extending limits on items such as: fallen trees, landscaping, removal and storage costs of personal contents, failure of supply services, cost of reletting, keys and lock replacements, car park liability, hiring of sporting/recreational facilities, garden equipment and court appearances."/></div> 
    const itemOptional             = (icon:'check'|'circle') => <div className="cos-list-item"><HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span> Optional extras</HiddenModal></div>
    const itemAllExtras            = (icon:'check'|'circle') => <div className="cos-list-item"><HiddenModal type={icon}><span className="cos-icon"><i className={`fas fa-${icon}`} /></span> All extras included</HiddenModal></div> ;

    return (
        <Step containerWidth="960px">
            <h2>Choose your cover</h2>
            <p className="pb-md-4 pd-2">Select from a range of options to suit your property's needs. Read the <a onClick={onPDSClick} href="https://www.flexinsurance.com.au/residential-strata/#importantdocuments" target="_blank" rel="noopener noreferrer">PDS</a> for more information.</p>
            
            <div className="for-mobile">
                <CoverOptionSelectorMobile
                    title="Standard Cover"
                    onClick={ onSelected('basic') }
                    body={
                        <div>
                            <div className="cos-body-text">Standard cover that your state/territory strata law requires.</div>
                            <div className="cos-includes">Includes the following:</div>
                            { itemYourProperty(true, 'check') }
                            { itemLiability(true, 'check') }  
                            { voluntaryWorkersRequired && itemVoluntaryWorkers(true, 'check') }
                            { fidelityGuaranteeRequired && itemFidelityGuarantee(true, 'check') } 
                        </div>
                    }
                />

                <CoverOptionSelectorMobile
                    title="Tailored Cover"
                    onClick={ onSelected('choose') }
                    body={
                        <div>
                            <div className="cos-body-text">Choose from a range of optional inclusions based on the building and strata owners' needs by selecting the continue button below. You will be prompted to choose the optional extras at the end.</div>
                            <div className="cos-includes">Includes the following:</div>
                            { itemYourProperty(true, 'check') }
                            { itemLiability(true, 'check') }
                            { voluntaryWorkersRequired && itemVoluntaryWorkers(true, 'check') }
                            { fidelityGuaranteeRequired && itemFidelityGuarantee(true, 'check') }
                            { itemOptional('circle') }
                        </div>
                    }
                    hidden={{
                        title:"See the optional extras",
                        body:(
                            <div>
                                { !voluntaryWorkersRequired && itemVoluntaryWorkers(false, 'circle') }
                                { !fidelityGuaranteeRequired && itemFidelityGuarantee(false, 'circle') }
                                { itemOfficeBearers(false, 'circle') }
                                { itemMachineryBreakdown(false, 'circle') }
                                { itemCatasrophe(false, 'circle') }
                                { itemGovernmentAudit(false, 'circle') }
                                { workersCompensationAvailable && itemWorkersCompensation(false, 'circle') }
                                { itemLotOwners(false, 'circle') }
                                { itemExploraroty(false, 'circle') }
                                { itemLossOfRent(false, 'circle') }
                                { itemFusion(false, 'circle') }
                                { itemFloatingFloors(false, 'circle') }
                                { itemSpecialBenefits(false, 'circle') }
                            </div>
                        )
                    }}
                />


                <CoverOptionSelectorMobile
                    title="All inclusive"
                    onClick={ onSelected('all') }
                    body={
                        <div>
                            <div className="cos-body-text">Includes all state/territory standard covers plus all optional inclusions for comprehensive cover.</div>
                            <div className="cos-includes">Includes the following:</div>
                            { itemYourProperty(true, 'check') }
                            { itemLiability(true, 'check') }
                            { voluntaryWorkersRequired && itemVoluntaryWorkers(true, 'check') }
                            { fidelityGuaranteeRequired && itemFidelityGuarantee(true, 'check') }
                            { itemAllExtras('check') }
                        </div>
                    }
                    hidden={{
                        title:"See the included extras",
                        body:(
                            <div>
                                { !voluntaryWorkersRequired && itemVoluntaryWorkers(false, 'check') }
                                { !fidelityGuaranteeRequired && itemFidelityGuarantee(false, 'check') }
                                { itemOfficeBearers(false, 'check') }
                                { itemMachineryBreakdown(false, 'check') }
                                { itemCatasrophe(false, 'check') }
                                { itemGovernmentAudit(false, 'check') }
                                { workersCompensationAvailable && itemWorkersCompensation(false, 'check') }
                                { itemLotOwners(false, 'check') }
                                { itemExploraroty(false, 'check') }
                                { itemLossOfRent(false, 'check') }
                                { itemFusion(false, 'check') }
                                { itemFloatingFloors(false, 'check') }
                                { itemSpecialBenefits(false, 'check') }
                            </div>
                        )
                    }}
                />
            </div>

            <table className="table cover-option-table for-desktop">
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>MOST POPULAR</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>Standard Cover</td>
                        <td>Tailored Cover</td>
                        <td>All inclusive</td>
                    </tr>
                    <tr>
                        <td style={{paddingBottom:10}}></td>
                        <td style={{paddingBottom:10}}>Standard cover that your state/territory strata law requires.</td>
                        <td style={{paddingBottom:10}}>Choose from a range of optional inclusions based on the building and strata owners' needs by selecting the continue button below. You will be prompted to choose the optional extras at the end.</td>
                        <td style={{paddingBottom:10}}>Includes all state/territory standard covers plus all optional inclusions for comprehensive cover.</td>
                    </tr>
                    <tr>
                        <td style={{borderTop:'none', paddingTop:0}}></td>
                        <td style={{borderTop:'none', paddingTop:0}}><button className="btn btn-small btn-primary btn-block" onClick={onSelected('basic')}>Continue <span className="icon-right float-right"><i className="fas fa-chevron-right" /></span></button></td>
                        <td style={{borderTop:'none', paddingTop:0}}><button className="btn btn-small btn-primary btn-block" onClick={onSelected('choose')}>Continue <span className="icon-right float-right"><i className="fas fa-chevron-right" /></span></button></td>
                        <td style={{borderTop:'none', paddingTop:0}}><button className="btn btn-small btn-primary btn-block" onClick={onSelected('all')}>Continue <span className="icon-right float-right"><i className="fas fa-chevron-right" /></span></button></td>
                    </tr>
                    <tr>
                        <td>{itemYourProperty(true)}</td>
                        <td><i className="fas fa-check" /></td>
                        <td><i className="fas fa-check" /></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    <tr>
                        <td>{itemLiability(true)}</td>
                        <td><i className="fas fa-check" /></td>
                        <td><i className="fas fa-check" /></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    {fidelityGuaranteeRequired && 
                    <tr>
                        <td>{itemFidelityGuarantee(true)}</td>
                        <td><i className="fas fa-check" /></td>
                        <td><i className="fas fa-check" /></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    }
                    <tr>
                        <td>{itemVoluntaryWorkers(voluntaryWorkersRequired)}</td>
                        <td>{ voluntaryWorkersRequired && <i className="fas fa-check" /> }</td>
                        <td>{ voluntaryWorkersRequired ? <i className="fas fa-check" /> : <span className="optional"><HiddenModal>Optional</HiddenModal></span>}</td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    {!fidelityGuaranteeRequired && 
                    <tr>
                        <td>{itemFidelityGuarantee(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    }
                    <tr>
                        <td>{itemOfficeBearers(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    <tr>
                        <td>{itemMachineryBreakdown(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    <tr>
                        <td>{itemCatasrophe(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    <tr>
                        <td>{itemGovernmentAudit(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    { workersCompensationAvailable && 
                    <tr>
                        <td>{itemWorkersCompensation(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    }
                    <tr>
                        <td>{itemLotOwners(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    <tr>
                        <td>{itemExploraroty(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    <tr>
                        <td>{itemLossOfRent(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    <tr>
                        <td>{itemFusion(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    <tr>
                        <td>{itemFloatingFloors(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    <tr>
                        <td>{itemSpecialBenefits(false)}</td>
                        <td></td>
                        <td><span className="optional"><HiddenModal>Optional</HiddenModal></span></td>
                        <td><i className="fas fa-check" /></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td><button className="btn btn-small btn-primary btn-block" onClick={onSelected('basic')}>Continue <span className="icon-right float-right"><i className="fas fa-chevron-right" /></span></button></td>
                        <td><button className="btn btn-small btn-primary btn-block" onClick={onSelected('choose')}>Continue <span className="icon-right float-right"><i className="fas fa-chevron-right" /></span></button></td>
                        <td><button className="btn btn-small btn-primary btn-block" onClick={onSelected('all')}>Continue <span className="icon-right float-right"><i className="fas fa-chevron-right" /></span></button></td>
                    </tr>
                </tbody>
            </table>
        </Step>
    )
};
