import React from 'react';
import env from '../env';

type Props = {
    formName:string
}

const getLogo = () => {
    switch(env.BRAND){
        case 'chu': return require('../assets/chu/logos/logo-chu-vert.png').default;
        case 'flex': return require('../assets/flex/logos/flex-insurance-color.svg').default;
        default: throw(new Error("Could not find logo"));
    }
}

const Header = ({formName}:Props) => (
    <header>
        <a href={env.ERROR_REDIRECT}>
            <img className="logo" alt="CHU logo" src={ getLogo() }/>
        </a>
        <div className="form-name">{ formName }</div>
    </header>
)

export default Header;
