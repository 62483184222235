import Common from "../Common";
import { RouteSet } from "../../types";
import Services from '../services'
import { useEffect } from "react";


export function useFetchQuotePayment(){
    const loading = Common.useLoading();
    const fields = Common.useFields();
    const navigation = Common.useNavigation();
    const [ quote, loadingQuote, fetchQuote, fetchQuoteCount ] = Common.useFetch(Services.quote('FULL_QUOTE'));
    
    useEffect(() => {
        if(loadingQuote === true) loading.beginLoading();
        else loading.endLoading();
    }, [loadingQuote]);


    useEffect(() => {
        if(quote){
            fields.setFromQuoteData(quote, [RouteSet.BEFORE_ESTIMATE, RouteSet.BEFORE_FINAL_PRICE, RouteSet.BEFORE_PAYMENT]);
        }
    }, [quote]);


    function fetch(){
        fetchQuote(fields.prepareData( [RouteSet.BEFORE_ESTIMATE, RouteSet.BEFORE_FINAL_PRICE, RouteSet.BEFORE_PAYMENT]));   
    }

    return [ fetch, loadingQuote, fetchQuoteCount ] as const;
}