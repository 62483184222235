import React from 'react';

type Props = {
    children:React.ReactNode,
}


const ButtonContainer= ({ children }:Props) => (
    <div className="button-container">
        { children }
    </div>
)

export default ButtonContainer;
