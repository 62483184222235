import env from '../../env'

const name = env.BRAND + " " + env.FORM;
const category = env.FORM;

const Actions = {
    LINK_CLICK: "click link",
    BUTTON_CLICK: "click button",
    INFO_CLICK: "click info",
    LABEL_CLICK: "click label",
    SELECT: "select",
    FIELD_INPUT: "field input",
    FIELD_BIND: "field bind",
    CHECKBOX_CLICK: "click checkbox",
}


function declined(){
    console.log("GA quoteDeclined");
    (window as any).dataLayer.push({
        'event': 'declined',
        'eventCategory': 'quote forms',
        'eventAction': 'abc',
        'eventLabel': 'declined',
        'eventNonInteraction': true
    });
}


function referred(){
    console.log("GA referred");
    (window as any).dataLayer.push({
        'event': 'referred',
        'eventCategory': 'quote forms',
        'eventAction': 'abc',
        'eventLabel': 'referred',
        'eventNonInteraction': true
    });
}


function onlinePaymentsSuccess(quoteNumber:string, paymentMethod:string, price:number){
    console.log("GA onlinePaymentsSuccess", quoteNumber, paymentMethod, price);

    (window as any).dataLayer.push({
        'event': 'paymentOnlineSuccess',
        'transactionId': quoteNumber,                         // String - Quote number
        'transactionAffiliation': 'Online: ' + paymentMethod, // String - Payment method
        'transactionTotal': price,                            // Numeric - Total amount paid
        'transactionProducts': [{
            'sku': quoteNumber,                               // String - Quote number
            'name': name,                                     // String - Product name
            'category': category,                             // String - Product category
            'price': price,                                   // Numeric - Total amount paid per policy
            'quantity': 1                                     // Integer - Number of policies
        }]
    });
}


function businessValidationError(errors:Record<string, string>){
    console.log("GA businessValidationErrors");
    
    Object.keys(errors).forEach(key => {
        (window as any).dataLayer.push({
            'event': 'businessValidationError',
            'eventCategory': 'quote forms',
            'eventAction':'abc',
            'eventLabel': key,
            'eventValue': errors[key],
            'eventNonInteraction': true
        });
    });
}


function offlinePaymentsSuccess(quoteNumber:string, price:number){
    console.log("GA offlinePaymentsSuccess", quoteNumber, price);

    (window as any).dataLayer.push({
        'event': 'paymentOfflineSubmitted',
        'transactionId': quoteNumber,              // String - Quote number
        'transactionAffiliation': 'Offline',       // String - Payment method
        'transactionTotal': price,                 // Numeric - Total amount paid
        'transactionProducts': [{
            'sku': quoteNumber,                    // String - Quote number
            'name': name,                          // String - Product name
            'category': category,                  // String - Product category
            'price': price,                        // Numeric - Total amount paid
            'quantity': 1                          // Integer - Leave set to 1
        }]
     });
}


function quoteCreated(quoteNumber:string){
    console.log("GA quoteCreated", quoteNumber);

    (window as any).dataLayer.push({
        'event': 'gaEvent',
        'eventCategory': 'quote forms',
        'eventAction': name + ' quote created',    // String - Type of quote created
        'eventLabel': quoteNumber,                 // String - Quote number created
        'eventValue': null,
        'eventNonInteraction': true
    });
}


function fieldEdit(fieldName:string){
    console.log("GA fieldEdit", fieldName);

    (window as any).dataLayer.push({
        'event': 'gaEvent',
        'eventCategory': 'quote forms',
        'eventAction': name + ' field edit',    // String - Type of quote created
        'eventLabel': fieldName,                // String - Quote number created
        'eventValue': null,
        'eventNonInteraction': true
    });
}


function estimateQuoteCreated(quoteNumber:string){
    console.log("GA estimateQuoteCreated:", quoteNumber);

    (window as any).dataLayer.push({
        'event': 'gaEvent',
        'eventCategory': 'quote forms',
        'eventAction': name + ' estimate quote created',    // String - Type of quote created
        'eventLabel': quoteNumber,                          // String - Quote number created
        'eventValue': null,
        'eventNonInteraction': true
    });
}


function quoteSavedAndEmailed(quoteNumber:string){
    console.log("GA quoteSavedAndEmailed:", quoteNumber);

    (window as any).dataLayer.push({
        'event': 'gaEvent',
        'eventCategory': 'quote forms',
        'eventAction': name + ' quote saved & emailed',      // String - Type of quote & event
        'eventLabel': quoteNumber,                           // String - Quote number
        'eventValue': null,
        'eventNonInteraction': true
     });
}


function customInteraction(actionName:string, label:string, value?:boolean|string|number|null){
    console.log("GA customInteraction:", actionName, label, value);

    (window as any).dataLayer.push({
        'event': 'gaEvent',
        'eventCategory': 'quote forms',
        'eventAction': name + ' ' + actionName,
        'eventLabel': label,
        'eventValue': value,
        'eventNonInteraction': false
    });
}

function customNonInteraction(actionName:string, label:string, value?:string|number|null){
    console.log("GA customNonInteraction:", actionName, label, value);

    (window as any).dataLayer.push({
        'event': 'gaEvent',
        'eventCategory': 'quote forms',
        'eventAction': name + ' ' + actionName,
        'eventLabel': label,
        'eventValue': value,
        'eventNonInteraction': true
    });
}


export default {
    Actions,
    declined,
    referred,
    businessValidationError,
    quoteSavedAndEmailed,
    onlinePaymentsSuccess,
    offlinePaymentsSuccess,
    quoteCreated,
    estimateQuoteCreated,
    fieldEdit,
    customInteraction,
    customNonInteraction,
}