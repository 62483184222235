import React, { useRef, useEffect, MutableRefObject } from 'react'
import $ from 'jquery'
import 'bootstrap-datepicker'
import Common from '../common/Common';
import moment from 'moment';


type Props = {
    field: string, // Field should be formate 'mm/dd/yyyy',
    endDate: string,
    cta?: MutableRefObject<any>,
}


const DatePicker = ({ endDate, field, cta }:Props) => {
    const fields = Common.useFields();
    const scroll = Common.useScroll();
    const ref = useRef(null);
    useEffect(() => {
        const onDateChange = (e:any) => {
            setValue(moment(e.date).format('DD/MM/YYYY'))
            if(cta) scroll.show(cta, 12);
        };

        // On Mount
        var elem:any = $(ref.current!);
        elem.datepicker({
            startDate: '0d',
            endDate: endDate,
            maxViewMode: 0,
            format:'dd/mm/yyyy',
            defaultViewDate: value,
        }).on("changeDate", onDateChange);

        // Update the value
        elem.datepicker('update', value)


        // On Dismount
        return () => elem.datepicker().off("changeDate", onDateChange);
    })

    const setValue = fields.setValue(field);
    const value = fields.value(field);

    return (
        <div id="date-picker" ref={ ref }/>
    )
}

export default DatePicker;
