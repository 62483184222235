import React from 'react'
import { Type } from './Input'


const getIconChar = (type:Type) => {
    switch(type){
        case "dollar": return <i className="fas fa-dollar-sign"></i>;
        case "percent": return <i className="fas fa-percent"></i>;
        //case "email": return "@";
        default: return null;
    }
}

const getIconPosition = (type:Type) => {
    switch(type){
        case "dollar": return "left";
        case "percent": return "right";
        //case "email": return "@";
        default: return null;
    }
}


type Props = {
    type:Type,
    position: "left"|"right",
}


const InputIcon = ({type, position}:Props) => {
    const iconChar = getIconChar(type);
    if(iconChar === null) return null;
    if(getIconPosition(type) != position) return null;
    return (
        <div className="input-group-prepend">
          <div className="input-group-text">{ iconChar }</div>
        </div>
    )
}


export default InputIcon;
