import React from 'react'
import Step from '../../components/Step'
import Input from '../../elements/Input'
import ButtonContainer from '../../elements/ButtonContainer'
import Button from '../../elements/Button'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'

const requesterEmail = fieldName('requester.email')


export const rules = {
    [ requesterEmail ]: [
        enforce.required('Required'),
        enforce.notBlank('Required'),
        enforce.email("Must be a valid email"),
    ],
}

export default () => {
    const navigation = Resi.useNavigation();
    const validation = Resi.useValidation();

    async function onContinue(){
        try{
            await validation.validate(rules);
            navigation.goToNext();
        }catch(e){}
    }

    return (
        <Step title="Interim price">
            <h2>Please provide your email address to continue</h2>
            <div className="row">
                <div className="col-12 col-md-6">
                    <Input field={requesterEmail} type="email" label="Email" caption="We will send your quote to this email address." autoFocus/>
                </div>
            </div>
            <ButtonContainer>
                <Button label="Continue" type="primary" icon="fas fa-chevron-right"  callback={ onContinue }/>
            </ButtonContainer>
        </Step>
    )
};