import Request from './request'
import Common from '../Common'


export default () => {
    const returnPath = window.location.protocol + '//' + window.location.host + window.location.pathname;
    const params:Record<string, string> = {
            externalId: Request.getToken()!,
            title: `Residential Strata`,
            success: returnPath + '#instalments-success',
            cancel: returnPath + '#restore?token=' + Request.getToken() + '&to=payment',
            error: returnPath + '#instalments-error',
    }

    const paramStrings = Object.keys(params).map(key =>  key + "=" + encodeURIComponent(params[key]))
    const instalmentsURL = Common.config.services.instalments + '?' + paramStrings.join("&");
    return instalmentsURL;
}