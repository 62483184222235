import React from 'react'
import Step from '../../components/Step'
import ButtonSelect from '../../elements/ButtonSelect'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import Input from '../../elements/Input';
import ButtonContainer from '../../elements/ButtonContainer';
import Button from '../../elements/Button';
import ScrollWhenAppears from '../../elements/ScrollWhenAppears';
import { Option } from '../../types'


const currentInsurer = fieldName('currentInsurer', 0);
const otherInsurer = fieldName('otherInsurer', 0);


export const rules = {
    [currentInsurer]:[
        enforce.required('Required')
    ],
    [otherInsurer]:[
        enforce.required('Required')
    ]
}


export default () => {
    const navigation = Resi.useNavigation();
    const fields = Resi.useFields();
    const options = Resi.useOptions();
    const validation = Resi.useValidation();

    /*
    const sortedOptions = [
        //options.data.product.INSURER.find(insurer => insurer.value == 'NEW_STRATA_PLAN')!,
        //options.data.product.INSURER.find(insurer => insurer.value == 'NOT_KNOWN')!,
        //options.data.product.INSURER.find(insurer => insurer.value == 'NOT_CURRENTLY_INSURED')!,
        ...options.data.product.INSURER.filter(insurer => ['OTHER', 'NOT_KNOWN', 'NOT_CURRENTLY_INSURED', 'NEW_STRATA_PLAN'].indexOf(insurer.value) == -1),
        options.data.product.INSURER.find(insurer => insurer.value == 'OTHER')!,
    ].filter(option => option != null);
    */

    function onSelected(value:string){
        fields.setValue(currentInsurer)(value)
        if(value !== 'OTHER'){
            navigation.goToNext()
        }
    }

    async function next(){
        try{
            await validation.validate(rules)
            navigation.goToNext();
        }catch(e){}
    }

    
    function doubleLineFormatting(option:Option<any>){
        return {
            ...option,
            description: option.description.replace(" (", `|(`).split("|").reduce((acc, v) => acc+`<div>${v}</div>`, ""), // Split into divs to take advantage of better formatting
        }
    }


    return (
        <Step>
            <h2>Who are you currently insured with?</h2>
            <ButtonSelect 
                options={ options.data.product.INSURER.NEW.map(doubleLineFormatting) }
                selected={ fields.value(currentInsurer) }
                colsLg={ 3 }
                colsSm={ 1 }
                callback={ onSelected }
            />
            <hr/>
            <ButtonSelect 
                options={ options.data.product.INSURER.INSURERS.map(doubleLineFormatting) }
                selected={ fields.value(currentInsurer) }
                colsLg={ 3 }
                colsSm={ 1 }
                callback={ onSelected }
            />

            <ScrollWhenAppears condition={fields.value(currentInsurer) === 'OTHER'}>
                <Input type="text"
                    label="Please enter other insurer"
                    field={ otherInsurer } />
                <ButtonContainer>
                    <Button label='Continue' type='primary' callback={ next }/>
                </ButtonContainer>
            </ScrollWhenAppears>
        </Step>
    )
};
