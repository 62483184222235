import React, { useEffect, useRef, useState } from 'react'
import Step from '../../components/Step'
import ButtonSelect from '../../elements/ButtonSelect'
import Resi from '../Resi'
import {fieldName, routeName, enforce} from '../HelpersResi'
import Input from '../../elements/Input'
import ButtonContainer from '../../elements/ButtonContainer'
import Button from '../../elements/Button'


const options = [
    {
        type: "NA",
        value: 0,
        description: "0",
    },
    {
        type: "NSA",
        value: 1,
        description: "1",
    },{
        type: "NA",
        value: 2,
        description: "2",
    },{
        type: "NA",
        value: 3,
        description: "3",
    },{
        type: "NA",
        value: 4,
        description: "4",
    },{
        type: "NA",
        value: 5,
        description: "5+",
    }
]


const numberOfClaims = fieldName('numberOfClaims');
const additionalComments = fieldName('additionalComments');
const valueOfClaims = fieldName('valueOfClaims');


export const rules = {
    [numberOfClaims]: [],
    [additionalComments]:[
        enforce.required("Required"),
    ],
    [valueOfClaims]:[
        enforce.required("Required"),
    ]
}



export default () => {
    const navigation = Resi.useNavigation();
    const fields = Resi.useFields();
    const validation = Resi.useValidation();
    const otherDetails = useRef<HTMLDivElement>(null);

    const [state, setState] = useState({
        originalNumberOfClaims: fields.value(numberOfClaims)
    });

    useEffect(() => {
        if(otherDetails.current && fields.value(numberOfClaims)!= '0' && fields.value(numberOfClaims) != state.originalNumberOfClaims){
            otherDetails.current.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [fields.value(numberOfClaims), otherDetails]);


    function onSelected(value:number){
        fields.setValue(numberOfClaims)(value);
        if(value == 0){
            navigation.goToNext();
        }
    }


    async function onContinue(){
        try{
            await validation.validate(rules);
            navigation.goToNext();
        }catch(e){}
    }



    return (
        <Step>
            <h2>How many claims have you had in the past three (3) years?</h2>
            <ButtonSelect 
                options={ options }
                selected={fields.value(numberOfClaims)}
                colsLg={ 3 }
                colsSm={ 1 }
                callback={ onSelected }
            />
            { fields.value(numberOfClaims) != undefined && fields.value(numberOfClaims) != "0" &&
            <div ref={otherDetails}>
                <div className="pt-4" >
                    <Input field={valueOfClaims} type='dollar' label="Value of claims" autoFocus/>
                </div>
                <div className="pt-4 pb-2">
                    <Input field={additionalComments} type='textarea' label="Tell us about your previous claims" />
                </div>
                <ButtonContainer>
                    <Button label="Continue" type="primary" icon="fas fa-chevron-right" callback={ onContinue } />
                </ButtonContainer>
            </div>
            }
        </Step>
    )
};