import smartAddress from './smartAddress'
import quote from './quote'
import paymentOptions from './paymentOptions'
import paymentAuthorise from './paymentAuthorise'
import paymentInitiate from './paymentInitiate'
import documents from './documents'
import postcode from './postcode'
import instalments from './instalments'
import details from './details'
import findToken from './findToken'
import calculateInstalments from './calculateInstalments'
import quoteAndInstalments from './quoteAndInstalments'
import googleAnalytics from './google-analytics'
import detailsAndInstalments from './detailsAndInstalments'

export default {
    smartAddress,
    quote,
    calculateInstalments,
    quoteAndInstalments,
    detailsAndInstalments,
    paymentOptions,
    paymentAuthorise,
    paymentInitiate,
    documents,
    postcode,
    instalments,
    details,
    findToken,
    googleAnalytics,
}
