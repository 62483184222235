import resiProductOptions from './resiProductOptions'
import resiSumsInsured from './resiSumsInsured'
import resiExcesses from './resiExcesses'
import paymentOptions from '../../common/services/paymentOptions'

// Rolls all options endpoints into one

export type ResiOptionsResult = Awaited<ReturnType<typeof resiOptions>>;

const resiOptions = async () => {
    return {
        product: await resiProductOptions(),
        sumsInsured: await resiSumsInsured(),
        excesses: await resiExcesses(),
        payment: await paymentOptions(),
    }
}

export default resiOptions;